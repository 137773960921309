import React, { useEffect } from "react";
import { StyledStatusMessage } from "../registrationform.styles";
import { useTranslation } from "react-i18next";
import { RegisterPageLayout } from "@heylog-app/frontend-lib/app";
import i18next from "i18next";
import { useParams } from "react-router-dom";
export const FinalStepV2 = () => {
    const { t } = useTranslation();
    const { ln = "" } = useParams()
    useEffect(() => {
        setTimeout(() => {
            i18next.changeLanguage(ln)
        }, 2000)
    }, [])
    return (
        <RegisterPageLayout isHeader={true}>
            <StyledStatusMessage status={'proceed'} style={{ justifyItems: 'center' }}>
                <div>{t(`registration.thankyouMsg`)}</div>
                <div>{t(`registration.closeApp`)}</div>
            </StyledStatusMessage>
        </RegisterPageLayout>
    )
};
