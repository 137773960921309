import type { FC } from 'react';

export const SvgJP: FC = () => (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2301_3157)">
            <mask id="mask0_2301_3157" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="13">
                <path d="M0.595001 0.657227H16.405V12.3429H0.595001V0.657227Z" fill="white" />
            </mask>
            <g mask="url(#mask0_2301_3157)">
                <path fill-rule="evenodd" clipRule="evenodd" d="M-0.393127 0.657227H17.3931V12.3429H-0.393127V0.657227Z" fill="white" />
                <path d="M8.50132 10.1329C10.5369 10.1329 12.1871 8.50665 12.1871 6.50055C12.1871 4.49444 10.5369 2.86816 8.50132 2.86816C6.46571 2.86816 4.81552 4.49444 4.81552 6.50055C4.81552 8.50665 6.46571 10.1329 8.50132 10.1329Z" fill="#BC002D" />
            </g>
        </g>
        <rect x="0.629371" y="0.691596" width="15.7413" height="11.6169" rx="1.34041" stroke="#BFBFC2" stroke-width="0.0687391" />
        <defs>
            <clipPath id="clip0_2301_3157">
                <rect x="0.595001" y="0.657227" width="15.81" height="11.6857" rx="1.37478" fill="white" />
            </clipPath>
        </defs>
    </svg>

);
// style="mask-type:luminance"