import { useState, useRef, ChangeEvent } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Avatar,
  DialogProps,
  styled,
  IconButton,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import { getNameInitials } from '@heylog-app/frontend-lib/app';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';
import Image from '../../../images/avatar-bg@2x.jpg';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '700px',
    maxWidth: '90vw',
    minHeight: '600px',
    overflow: 'hidden',
  },
}));

const PreviewContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '400px',
  width: '100%',
});

export default function PreviewPicture({
  modalIsOpen,
  closeModal,
  handleFileSelect,
  employee,
  showUpload,
  setCroppedImage,
  croppedImage,
  previewImage,
  setPreviewImage
}: any) {
  const { t } = useTranslation();
  const cropperRef = useRef<ReactCropperElement>(null);
  const [file, setFile] = useState<File>();

  // Fixed file handling
  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => setPreviewImage(reader.result as string);
    reader.readAsDataURL(file);
    setFile(file);
  };

  const getCroppedImage = () => {
    const cropper = cropperRef.current?.cropper;
    if (!cropper) return;

    const canvas = cropper.getCroppedCanvas({
      width: 300,
      height: 300,
    });

    if (!canvas) {
      console.error('Cropped canvas is empty');
      return;
    }

    const croppedDataUrl = canvas.toDataURL('image/png');
    const file = base64ToFile(croppedDataUrl, 'cropped-image.png');

    setCroppedImage(croppedDataUrl);
    handleFileSelect(file);
  };

  const base64ToFile = (base64: string, filename: string): File => {
    const arr = base64.split(',');
    const mime = arr?.[0]?.match(/:(.*?);/)![1];
    const bstr = atob(arr?.[1] || "");
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  return (
    <StyledDialog
      open={modalIsOpen}
      onClose={() => {
        setPreviewImage(null);
        closeModal()
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {t('navigation.settingsMenu.profile.pictureEdit')}
        <IconButton onClick={() => {
          setPreviewImage(null);
          closeModal()
        }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <PreviewContent>
          {previewImage ? (
            <Cropper
              ref={cropperRef}
              src={previewImage}
              style={{ height: 400, width: '100%' }}
              aspectRatio={1}
              viewMode={2}
              guides={false}
            />
          ) : employee?.profilePicturePath || croppedImage ? (
            <Avatar
              src={croppedImage || employee?.profilePicturePath}
              sx={{
                width: 300,
                height: 300,
                fontSize: '4rem',
              }}
            />
          ) : (
            <Avatar
              sx={{
                width: 300,
                height: 300,
                fontSize: '4rem',
                bgcolor: 'primary.main',
                backgroundImage: `url(${Image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              {getNameInitials(employee?.firstName, employee?.lastName) || (
                <Icon path={mdiAccount} size={'10rem'} />
              )}
            </Avatar>
          )}
        </PreviewContent>

        {showUpload && (
          <div style={{ display: 'flex', justifyContent: 'center', gap: 16, marginTop: 24 }}>
            {!previewImage ? (
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload Image
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={getCroppedImage}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setPreviewImage(null)}
                >
                  Cancel
                </Button>
              </>
            )}
          </div>
        )}
      </DialogContent>
    </StyledDialog>
  );
}