import type { FC } from 'react';

export const SvgIT: FC = () => (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2301_3165)">
            <path fill-rule="evenodd" clipRule="evenodd" d="M0.595001 0.657227H16.405V12.3429H0.595001V0.657227Z" fill="white" />
            <path fill-rule="evenodd" clipRule="evenodd" d="M0.595001 0.657227H5.86418V12.3429H0.595001V0.657227Z" fill="#009246" />
            <path fill-rule="evenodd" clipRule="evenodd" d="M11.1358 0.657227H16.405V12.3429H11.1358V0.657227Z" fill="#CE2B37" />
        </g>
        <defs>
            <clipPath id="clip0_2301_3165">
                <rect x="0.595001" y="0.657227" width="15.81" height="11.6857" rx="1.37478" fill="white" />
            </clipPath>
        </defs>
    </svg>


);
