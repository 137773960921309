import {
  ButtonV2,
  colors,
  DialogFooter,
  H2,
  LinkedOrder,
  SIZE_SM,
  STYLE_1,
  STYLE_2,
} from "@heylog-app/frontend-lib/app";
import { Avatar, Box, Button, Chip, Grid } from "@mui/material";
import styled, { css } from "styled-components";
import Image from "../../images/avatar-bg@2x.jpg";

const inputClasses = css`
  .MuiInputBase-root {
    padding-left: 0;
  }

  input {
    padding: 8.5px 4px 8.5px 0px;
  }

  .Mui-error {
    margin-left: 0;
  }

  fieldset {
    border: 0;
    border-bottom: 1px ${colors.grey[300]} solid;
    border-radius: 0;
  }
`;

export const StyledDetailsTitle = styled.h1`
  ${H2};
  color: ${colors.grey[900]};
  white-space: nowrap;
  margin: 0;
`;
export const ButtonsGridContainer = styled(Grid)`
  float: inline-end;
`;
export const StyledDecoration = styled.div`
  margin-top: 20px;
  height: 1px;
  width: 100%;
  background-color: ${colors.greyblue[205]};
`;
export const Container = styled(Box)`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  padding: 2rem 1rem;
  background-color: #f5f8fe;
`;
export const CustomGridContainer = styled(Grid)`
  align-items: center;
  background-color: #f5f8fe;
`;
export const StyledAvatar = styled(Avatar)`
  background-image: url(${Image});
  background-size: cover;
`;
export const StyledChooseOrder = styled(LinkedOrder)`
  ${inputClasses}
  margin-top: 10px;
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding-left: 0;
  }
  .MuiInputBase-root {
    background: var(--White-200, #f5f8fe);
  }
  .MuiOutlinedInput-root .MuiAutocomplete-input {
    color: var(--White-600, #7c839e);
  }
`;

export const StyledMessageGroup = styled.div`
  display: flex;
  max-width: fit-content;
  flex-direction: column;
  align-items: "flex-start";
`;
export const StyledMessageAuthorText = styled.div`
  ${STYLE_2};
  ${SIZE_SM};

  display: flex;
  align-items: baseline;
  gap: 0.5rem;
`;

export const StyledDialog = styled(DialogFooter)`
  background-color: var(--White-150, #fafcfe);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 160px;
  border-radius: 5px;
  border-top: none;
  position: relative;
  padding: 2rem 1rem;
`;
export const StyledDiv = styled.div`
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 10;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--Primary-Main, #122cc5);
`;
export const StyledMessageBubble = styled.div`
  position: relative;
  ${STYLE_1};
  font-size: 0.85rem;
  background-color: var(--Success-Light, #d9f9da);

  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0.25rem 0 0 0;
  border-radius: 1rem;
  white-space: pre-line;
  line-break: anywhere;

  ${({ theme }) => `background-color: ${theme.palette.green.main};`}

  p {
    margin: 0;
  }
`;
export const StyledDialogContainer = styled(Grid)`
  min-height: 480px;
`;
export const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: "4px",
  border: `0px`,
  backgroundColor: "var(--White-300, #E1E4EA)",
  color: "var(--Primary-Darker, #030B5E)",
}));
export const StyledExistingButton = styled(Button)`
  display: flex;
  max-width: fit-content;
  height: 40px;
  padding: 12px 25px 12px 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--Primary-Main, #122cc5);
  background: var(--White-200, #f5f8fe);
`;
export const ContactGrid = styled(Grid)`
  display: flex;
  width: 239px;
  height: 48px;
  padding-right: 4.5px;
  align-items: center;
  gap: 44px;
  border-radius: 5px;
  background: var(--Primary-Light-150, #ebedff);
`;


export const StyledLi = styled.li`
  background-color: var(--Primary-Light-150, #F5F8FE);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #EBEDFF !important;
  }
`;

