import {
	PusherBeamsProvider,
	PusherChannelsProvider,
	RegisterPageLayout,
} from '@heylog-app/frontend-lib/app';

import type { FC } from 'react';
import { FormWrapper } from '../components/registration-form';

export const RegistrationPage: FC = () => {
	return (
		<PusherChannelsProvider>
			<RegisterPageLayout isHeader={true}>
				<FormWrapper />
			</RegisterPageLayout>
		</PusherChannelsProvider>
	);
};
