import React from "react";
import {
  StyledBackButton,
  StyledButton,
  StyledTextWrapper,
  StyledDiv,
  StyledFormV2,
  StyledInputV2,
} from '../registrationform.styles';
import { useTranslation } from "react-i18next";
import { DetailsHeader } from "../components/DetailsHeader";
import { Checkbox } from "@mui/material";
import { Controller } from "react-hook-form";
import { useMultiStep } from "../MultiStepProvider";

export const ThirdStep: React.FC = () => {
  const { t } = useTranslation();
  const { formMethods: { watch, control }, deliveryNotes, transportDetails } = useMultiStep();

  const { back } = useMultiStep();
  const deliveryNotesFromAPI = deliveryNotes?.map((value: { delivery_note_id: string }, index: number) => {
    return {
      value: value?.delivery_note_id,
      key: value?.delivery_note_id + index,
    }
  })

  const selectedDeliveryNotes = watch("step3.deliveryNotes", {});

  const onSubmit = () => {
    console.log("Selected Delivery Notes:", selectedDeliveryNotes);
  };

  return (
    <StyledTextWrapper>
      <DetailsHeader note="registration.confirmDelivery" transportDetails={transportDetails} />
      <StyledFormV2>
        {deliveryNotesFromAPI.map(({ key, value }: any) => (
          <div key={key} style={{ display: "flex", gap: "0.5rem" }}>
            <Controller
              name={`step3.deliveryNotes.${key}`}
              control={control}
              defaultValue={false}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  sx={{ color: "#122CC5", "&.Mui-checked": { color: "#122CC5" } }}
                  checked={!!value}
                  onChange={(e) => onChange(e.target.checked)}
                />
              )}
            />
            <StyledInputV2
              value={value}
              type="text"
              InputProps={{ readOnly: true }}
            />
          </div>
        ))}
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <Controller
            name="step3.deliveryNotes.hasMore"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                sx={{ color: "#122CC5", "&.Mui-checked": { color: "#122CC5" } }}
                checked={!!value}
                onChange={(e) => onChange(e.target.checked)}
              />
            )}
          />
          <StyledInputV2
            value={t(`registration.additionalDeliveryNotesAvailable`)}
            type="text"
            InputProps={{ readOnly: true }}
          />
        </div>
      </StyledFormV2>

      <StyledDiv style={{ marginTop: '1.7rem' }}>
        <StyledBackButton type="button" onClick={back}>
          {t(`registration.back`)}
        </StyledBackButton>
        <StyledButton
          onClick={onSubmit}
          disabled={!Object.values(selectedDeliveryNotes).includes(true)}
        >
          {t(`registration.further`)}
        </StyledButton>
      </StyledDiv>
    </StyledTextWrapper>
  );
};
