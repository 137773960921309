import { useTranslation } from 'react-i18next';
import { Box, Chip, Stack, Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiBookmark, mdiBookmarkOutline, mdiInformationOutline } from '@mdi/js';
import { getFormattedEta, getNameInitials } from '../util';
import { useConversationsV2 } from './use-conversationv2.hook';
import { colors } from '../styles';
import { useOrders } from './use-orders.hook';
import { convertOrderToDashboardRow } from './use-dashboard.hook';

import type { ConversationType } from '@heylog-app/shared/types';
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { } from 'react';
import { StyledAvatar } from '../components/ui/message-author/message-author.styles';
import { InfoOutlined } from '@mui/icons-material';


export interface ConversationDetailsRow {
	contactId?: number;
	conversationV1Id?: string;
	dateAndTime: string;
	from: {
		senderEmail?: string;
		senderName?: string;
	};
	id: number;
	selected?: boolean;
	message?: string;
	priority?: string;
	internalReference?: string;
	orderDateFromTo?: string;
	subject?: string;
	profilePicturePath?: string;
	todo: boolean;
	type: ConversationType;
}
export const useMessagesData = (types?: ConversationType[], handleTodoChange?: any, paginationModel?: any, searchTerm?: string, tabName?: string, searchType?: string) => {
	const { t } = useTranslation();
	const handleTodoChanges = (params: GridRenderCellParams, value: boolean) => {
		handleTodoChange && handleTodoChange(params, value)
		// make api call to BE don't just change on FE
	};


	const columns: GridColDef[] = [

		{
			field: 'priority',
			headerName: t('messages.priority'),
			headerClassName: 'bold-header ',
			width: 30,
			renderCell: (params) => {
				return (
					<>
						<Stack
							justifyContent="center"
							sx={{
								width: '100%',
								height: '100%',
								display: 'flex',
								cursor: "pointer"
							}}

						>
							{/* <Icon path={mdiInformationOutline} size={1} color="rgb(186, 93, 17)" /> */}
							<Box
								onClick={(event: React.ChangeEvent<any>) => {
									event.stopPropagation()
								}}
								sx={{
									width: '32px',
									height: '32px',
									backgroundColor: params.value ? '#FBD4B4' : 'transparent',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: '4px',
								}}
							>
								<InfoOutlined style={{ color: params.value ? '#FA8525' : '#7C839E', transform: 'rotate(180deg)' }} />
							</Box>
						</Stack>

					</>
				)
			},
		},
		{
			field: 'todo',
			type: 'boolean',
			headerClassName: 'bold-header',
			headerName: t(`messages.todo`),
			width: 50,
			renderCell: (params) => {
				if (params.value === undefined) return null;
				return (
					<div style={{
						cursor: "pointer"
					}}>
						{
							params.value ?
								<div onClick={(e: React.ChangeEvent<any>) => {
									e.stopPropagation()
									handleTodoChanges(params, !params.value)
								}}>

									<Icon path={mdiBookmark} size={1} color={"#122CC5"} />
								</div>
								:
								<div onClick={(e: React.ChangeEvent<any>) => {
									e.stopPropagation()
									handleTodoChanges(params, !params.value)
								}}>

									<Icon path={mdiBookmarkOutline} size={1} color={"#122CC5"} />
								</div>
						}

					</div >
				);
			},
		},
		{
			field: 'type',
			headerName: t(`messages.type`),
			headerClassName: 'bold-header',
			flex: 1,
		},
		{
			field: 'dateAndTime',
			headerName: t(`messages.dateAndTime`),
			flex: 1,
			headerClassName: 'bold-header',
		},
		{
			field: 'from',
			headerName: tabName !== "Heylog" ? t(`messages.from`) : t(`messages.contact`),
			flex: 2,
			headerClassName: 'bold-header',
			renderCell: (params) => {
				if (!params.value) return null;
				return (
					<Stack direction="row" alignItems="center">
				
							<StyledAvatar
								sx={{
									height: '22px',
									width: '22px',
									fontSize: '12px',
								}}
								src={params.row.profilePicturePath}
							>
								{!params.row.profilePicturePath && getNameInitials(params.value.senderName)}
							</StyledAvatar>

						<Stack sx={{ marginLeft: '8px' }}>
							<Typography sx={{ fontWeight: '700', fontSize: '12px' }}>
								{params.value.senderName}
							</Typography>
							{/* <Typography sx={{ fontSize: '10px' }}>
								{params.value.senderEmail}
							</Typography> */}
						</Stack>
					</Stack>
				);
			},
		},
		{
			field: 'subject',
			headerName: t(`messages.subject`),
			flex: 2,
			headerClassName: 'bold-header',
			renderCell: (params) => {
				if (!params.row.subject) return null;
				const subject = params.row.subject;
				return <span>{subject}</span>;
			},
		},
		{
			field: 'internalReference',
			headerName: t('messages.heylogReference'),
			flex: 1,
			headerClassName: 'bold-header',
		},
		{
			field: 'labels',
			headerName: t('messages.labels'),
			flex: 2,
			headerClassName: 'bold-header',
			renderCell: (params) => {
				if (!params.value) return null;
				return (
					<Stack direction="row" gap={1}>
						{params.value.map((team: string, index: number) => {
							return (
								<Chip
									sx={{
										bgcolor: '#198A1F',
										fontSize: '10px',
										padding: '2px',
										color: 'white',
									}}
									key={index}
									size="small"
									label={team}
								/>
							);
						})}
					</Stack>
				);
			},
		},
	];

	const { conversations, updateConversationsV2, isLoading } = useConversationsV2(types, paginationModel?.pageNumber === 0 ? 1 : paginationModel?.pageNumber, paginationModel?.pageSize, searchTerm, tabName, searchType);

	// SWR cache should help keeping from overfetching
	const { orders } = useOrders();
	const rows: ConversationDetailsRow[] = conversations?.data?.map((conversation) => {
		// maybe i don't have details
		const details = conversation.details
			? (conversation.details as { conversationV1Id: string; contactId: number })
			: null;

		const order =
			conversation.orderId &&
			orders?.find((order) => order.id === conversation.orderId);

		const internalReference = order
			? order.labels.find((label) => label.type === 'ORDER_EXTERNAL_REFERENCE')?.value
			: undefined;

		// this will break when we have multiple orders
		const priority = order
			? order.labels.find((label) => label.type === 'ORDER_PRIORITY')?.value
			: undefined;

		const todo = conversation.labels.find(
			(label) => label.type === 'CONVERSATION_TODO' && label.value === 'Todo',
		)
			? true
			: false;

		const orderForDashboard = order ? convertOrderToDashboardRow(order, t) : undefined;
		const orderDateFrom = orderForDashboard?.collectionDate
			? getFormattedEta(orderForDashboard?.collectionDate)
			: '';
		const orderDateTo = orderForDashboard?.deliveryDate
			? getFormattedEta(orderForDashboard?.deliveryDate)
			: '';
		const orderDateFromTo = orderDateFrom + ' - ' + orderDateTo;

		const orderAttention =
			order && order?.labels?.find((label) => label.type === 'ORDER_ATTENTION')?.value;

		const attention = orderAttention ? [orderAttention] : [];

		const labels = conversation.labels
			.filter((label) => label.type !== 'CONVERSATION_TODO')
			.map((label) => label.value);

		return {
			contactId: details?.contactId,
			status: conversation?.status,
			conversationV1Id: details?.conversationV1Id,
			dateAndTime: conversation.updatedAt
				? new Date(conversation.updatedAt).toLocaleString('eu')
				: '',
			from: {
				senderEmail: conversation.lastMessage?.from,
				senderName: conversation.lastMessage?.from,
			},
			id: conversation.id,
			labels: [...attention, ...labels],
			message: conversation.lastMessage?.text,
			orderId: conversation.orderId,
			selected: false,
			priority,
			profilePicturePath: conversation.lastMessage?.profilePicturePath,
			internalReference,
			orderDateFromTo,
			subject: conversation.lastMessage?.subject,
			todo,
			type: conversation.type,
		};
	}) || [];
	return { columns, rows, updateConversationsV2, pagination: conversations?.pagination, isLoading };
};
