import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Popper, ClickAwayListener, LinearProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EmojiPicker from 'emoji-picker-react';
import SendIcon from '@mui/icons-material/Send';
import {
	MessageTypeEnum,
} from '@heylog-app/shared/types';
import { getMediaType } from '@heylog-app/shared/util';

import {
	StyledMessageFlexWrapper,
	StyledMessageInput,
	StyledMessageInputWrapper,
	StyledInputBottom,
	StyledFileWrapper,
	StyledTextareas,
	StyledTextareaWrapper,
	StyledSubmitButton,
} from './message-input.styles';
import {

	useDialog,
	useMutateHeylogMessages,
	useSnackbar,
	useTemplates,
	useUploadFiles,
	useUser,
	useWorkspace,
} from '../../../hooks';
import { addEmojiToInput } from '../../../util/add-emoji';
import { UploadedFiles } from '../uploaded-files/uploaded-files';
import { DragOverlay } from '../drag-overlay';
import { MessageTextarea } from './message-textarea';
import { FlashSnackbar } from '../../snackbars';
import { parseAxiosErrorMessage } from '../../../util';

import type { FC, SyntheticEvent, KeyboardEventHandler, Dispatch, SetStateAction } from 'react';
import type { EmojiClickData } from 'emoji-picker-react';
import { MessageInputToolbarV3 } from '../message-input-toolbar/message-input-toolbarV3';
import { ButtonV2 } from '@heylog-app/frontend-lib/app';

interface MessageInputPropsInteface {
	conversationId?: string;
	shouldFocusOnRender?: boolean;
	message?: string;
	setMessage?: Dispatch<SetStateAction<string>>;
	useSubmitButtons?: boolean;
}

export const MessageInputV3: FC<MessageInputPropsInteface> = ({
	conversationId,
	shouldFocusOnRender,
	useSubmitButtons = false,
}) => {
	const { workspaceId = '', ...params } = useParams();
	const [message, setMessage] = useState('');
	const { user } = useUser();
	const { workspace } = useWorkspace(workspaceId, user);
	const { sendMessage, sendMediaMessage } = useMutateHeylogMessages(workspaceId, conversationId || "");

	const [isSending, setIsSending] = useState(false);
	const [emojiPickerField, setEmojiPickerField] = useState<
		(EventTarget & (HTMLTextAreaElement | HTMLInputElement)) | null
	>(null);
	const messageInputToolbarRef = useRef<HTMLDivElement>(null);

	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const [focusedFieldIndex, setFocusedFieldIndex] = useState(0);
	const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
	const [templateCursorPosition, setTemplateCursorPosition] = useState<
		number | undefined
	>(undefined);

	const translationDialogControl = useDialog();

	const { templates } = useTemplates();
	const templatesDialogControl = useDialog();




	const {
		removeFile,
		files,
		getInputProps,
		getRootProps,
		openFileDialog,
		isDragAccept,
		removeAllFiles,
		fileError,
		setFileError,
	} = useUploadFiles();




	const { t } = useTranslation();

	const templateErrorMessage = t('chats.errors.noTranslatedTemplateAvailable');



	const toggleEmojiPicker = useCallback(() => {
		setIsEmojiPickerVisible(!isEmojiPickerVisible);
	}, [isEmojiPickerVisible, setIsEmojiPickerVisible]);



	const sendMediaMessages = useCallback(async () => {
		for (let i = 0; i <= files.length; i++) {
			const file = files[i];

			if (file) {
				try {
					const mediaType = getMediaType(file.type);

					//TODO handle sent reaction somewhere
					if (mediaType === MessageTypeEnum.REACTION) {
						return;
					}

					if (
						mediaType === MessageTypeEnum.IMAGE ||
						mediaType === MessageTypeEnum.VIDEO
					) {
						await sendMediaMessage({
							messagePayload: {
								caption: message,
								messageType: mediaType,
								file: file,
							},
						});
					} else if (mediaType === MessageTypeEnum.AUDIO) {
						await sendMediaMessage({
							messagePayload: { messageType: mediaType, file: file },
						});
					} else if (
						// if message is text or template dont handle this here
						mediaType !== MessageTypeEnum.TEXT
					) {
						await sendMediaMessage({
							messagePayload: {
								caption: message,
								messageType: mediaType,
								file: file,
							},
						});
					}
				} catch (error) {
					openSnackbar('error', parseAxiosErrorMessage(error));
					setFileError(true);
					setIsSending(false);
					return;
				}
			}
		}
	}, [files, sendMessage, conversationId, message, openSnackbar, setFileError]);

	const sendTextMessage = useCallback(async () => {

		if (message && message.trim().length) {
			await sendMessage({
				text: message,
				orderId: 1517,
			}).catch((error) => {
				console.log('error sending a text message: ', error);
				setIsSending(false);
			});
		}
	}, [sendMessage, message]);

	const handleSubmit = useCallback(async () => {
		if (isSending) return;
		setIsSending(true);
		// send text message
		if (files.length) {
			await sendMediaMessages();
		}
		if (message) {
			await sendTextMessage();
		}
		setMessage('');
		removeAllFiles();
		setIsSending(false);
	}, [
		files,
		message,
		isSending,
		removeAllFiles,
		sendMediaMessage,
		sendTextMessage,
	]);

	const onChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
		setMessage(event?.currentTarget?.value);
	};

	const onPressEnter: KeyboardEventHandler = (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();

			handleSubmitClick();
		}
	};

	const handleSubmitClick = () => {
		handleSubmit();
	};

	const sendEmojiToInput = async (emojiObject: EmojiClickData) => {
		if (emojiPickerField) {
			const message = emojiPickerField.value;
			const cursor = emojiPickerField?.selectionStart || 0;
			const { messageWithEmoji, processedEmojiLength } = addEmojiToInput(
				emojiObject,
				message,
				cursor,
			);

			const newCursor = cursor + processedEmojiLength + 1;
			setMessage(messageWithEmoji);
			setTemplateCursorPosition(cursor + processedEmojiLength + 1);
		}
		setIsEmojiPickerVisible(false);
	};

	useEffect(() => {
		if (templateCursorPosition && emojiPickerField) {
			emojiPickerField.setSelectionRange(templateCursorPosition, templateCursorPosition);
		}
	}, [templateCursorPosition, emojiPickerField]);

	const clickAwayEmojiPickerHandler = () => {
		setIsEmojiPickerVisible(false);
	};

	return (
		<>
			<StyledMessageInputWrapper
				data-test="message-input-component"
				ref={messageInputToolbarRef}
			>
				<StyledMessageInput {...getRootProps()}>
					{isEmojiPickerVisible && (
						<ClickAwayListener onClickAway={clickAwayEmojiPickerHandler}>
							<Popper
								data-test="emoji-picker-popper"
								open={isEmojiPickerVisible}
								anchorEl={messageInputToolbarRef?.current}
								style={{ zIndex: 100 }}
								placement={window.innerWidth <= 1600 ? 'top-start' : 'bottom-start'}
								modifiers={[
									{
										name: 'offset',
										options: {
											offset: [15, -10],
										},
									},
									{
										name: 'preventOverflow',
										options: {
											boundary: 'viewport',
										},
									},
								]}
							>
								<EmojiPicker
									onEmojiClick={sendEmojiToInput}
									autoFocusSearch={false}
									previewConfig={{ showPreview: false }}
								/>
							</Popper>
						</ClickAwayListener>
					)}

					{isDragAccept && <DragOverlay />}
					<MessageInputToolbarV3
						getInputProps={getInputProps}
						openFileDialog={openFileDialog}
						conversationId={conversationId || ""}
						openWhatsAppTemplatesDialog={templatesDialogControl.openDialog}
						toggleEmojiPicker={toggleEmojiPicker}
					/>
					<StyledMessageFlexWrapper
						$isSignatureOn={false}
						$hasMedia={files?.length > 0}
					>
						<StyledFileWrapper>
							<StyledTextareas>
								<StyledTextareaWrapper>
									<MessageTextarea
										message={message}
										isSending={isSending}
										onChange={onChange}
										onPressEnter={onPressEnter}
										setEmojiPickerField={setEmojiPickerField}
										focusedFieldIndex={focusedFieldIndex}
										setFocusedFieldIndex={setFocusedFieldIndex}
										templateCursorPosition={templateCursorPosition}
										shouldFocusOnRender={shouldFocusOnRender}
									/>
								</StyledTextareaWrapper>
							</StyledTextareas>
						</StyledFileWrapper>
						<StyledInputBottom
							$isSignatureOn={false}
							$hasMedia={files?.length > 0}
						>
							{useSubmitButtons ? (
								<>
									<Grid>
										<ButtonV2 $variant="outlined2" type="reset" onClick={() => {setMessage('')}} $height='32px'>
											{t('actionLabels.Clear')}
										</ButtonV2>
										<ButtonV2 isLoading={isSending} $variant="dark" variant="contained" onClick={handleSubmitClick} type="submit" $height='32px'>
											{t('actionLabels.send')}
										</ButtonV2>
									</Grid>
								</>
							) : (
									<StyledSubmitButton
										data-test="send-message-button"
										$active={true}
										$isSignatureOn={false}
										onClick={handleSubmitClick}
									>
										<SendIcon />
										{isSending ? <LinearProgress /> : null}
									</StyledSubmitButton>
							)}
							{files && (
								<UploadedFiles
									fileError={fileError}
									removeFile={removeFile}
									files={files}
									showMargin={false || files?.length > 0}
								/>
							)}
						</StyledInputBottom>
					</StyledMessageFlexWrapper>
				</StyledMessageInput>
			</StyledMessageInputWrapper >

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</>
	);
};
