import { useEffect, useMemo, useRef, useState } from 'react';
import {
	generatePath,
	matchPath,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';
import { Box, Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
	ErrorBoundary,
	ROUTES,
	TodoCheckbox,
	useContact,
	useContacts,
	useConversationsV2,
	useDebounce,
	usePlugins,
	Can,
	useOrderActions,
	TabNavigationToggleButton,
	colors,
	useConversationGroup,
} from '@heylog-app/frontend-lib/app';
import { Actions, ConversationTypeEnum, OrderConnectionStatusEnum } from '@heylog-app/shared/types';

import { CustomTabs, StyledContactName, StyledLinkedOrderChip, StyledTab, StyledToggleButtonGroup } from './contacts.styles';
import { useContactContext } from './contacts';

import type { FC, SyntheticEvent } from 'react';

export const ContactsDetailPageV2: FC = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const params = useParams();
	const textElementRef = useRef<HTMLDivElement>(null);
	const { contactId, workspaceId = '', conversationId = '' } = params;
	const { FrontendV2Plugin } = usePlugins(workspaceId);
	const debouncedSearchTerm = useDebounce<string | undefined>('', 250);
	const { pathname } = location;

	const { converGroupData } = useConversationGroup({ type: undefined });
	// Example of paths
	const paths = [
		ROUTES.CONTACTS.CHAT4,
	];

	const currentContact = useMemo(() => {
		if (!converGroupData) return null;
		return converGroupData?.find((contact: any) => Number(contact.id) === Number(conversationId));
	}, [converGroupData, conversationId]);
	const initialActiveTab =
		paths.find((path) => matchPath(path, pathname)) || ROUTES.CONTACTS.CHAT4;

	const [activeTab, setActiveTab] = useState(initialActiveTab);
	const [selectedTab, setSelectedTab] = useState("chat");
	const handleChange = (_: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue);
	};



	const handleTabClick = (event: SyntheticEvent, path: string) => {
		setActiveTab(path);
	};


	const navigate = useNavigate();
	const { contacts } = useContacts({ q: debouncedSearchTerm });

	useEffect(() => {
		console.log("converGroupData", converGroupData);
		if (matchPath(ROUTES.CONTACTS.DETAIL2, pathname) && converGroupData) {
			navigate(
				generatePath(ROUTES.CONTACTS.CHAT4, {
					...params,
					conversationId: converGroupData?.[0]?.id.toString() || '',
					contactId: converGroupData?.[0]?.id.toString() || '',
					activeTab: ConversationTypeEnum.HEYLOG_USER,
					status: converGroupData?.[0]?.status
				}),
			);
		}
	}, [params, converGroupData, navigate, pathname]);

	return (
		<>
			<StyledToggleButtonGroup
				color="primary"
				value={activeTab}
				exclusive
				onChange={handleTabClick}
				aria-label="Tab navigation"
				style={{ backgroundColor: '#FAFCFE' }}
			>
				<Stack
					direction="row" alignItems="center" columnGap={2}
					sx={{
						fontWeight: 'bold',
						marginTop: '14px',
						marginLeft: '10px',
					}}
				>
					{currentContact &&
						<Tooltip title={currentContact.chatName} >
							<StyledContactName ref={textElementRef}>{currentContact.chatName}</StyledContactName>
						</Tooltip>
						|| "-"}
					{currentContact?.orderReference && <StyledLinkedOrderChip
						label={currentContact?.orderReference || "-"}
					/>
					}
				</Stack>

				<Stack
					sx={{
						marginTop: '10px',
						flexDirection: 'row',
					}}
					marginLeft="auto"
				>
					<CustomTabs
						value={selectedTab}
						onChange={handleChange}
						sx={{ width: '100%' }}
					>
						<StyledTab label={'Chat'} value={"chat"} />
						<StyledTab label={'Information'} value={"info"} />
					</CustomTabs>
					{/* <TabNavigationToggleButton
						dataTest="contact-information-menu"
						label="contacts.tabs.chat"
						url={generatePath(ROUTES.CONTACTS.CHAT4, {
							...params,
							conversationId: currentContact?.id.toString() || '',
							contactId: currentContact?.id.toString() || '',
							activeTab: ConversationTypeEnum.HEYLOG_USER,
						})}
					/> */}


				</Stack>

			</StyledToggleButtonGroup>
			<ErrorBoundary>
				<Outlet context={useContactContext()} />
			</ErrorBoundary>
		</>
	);
};
