import { useCallback, useEffect, useState } from 'react';
import {
	StyledPageContent,
	StyledButton,
	StyledBackButton,
	StyledDiv,
} from './registrationform.styles';

import { useTranslation } from 'react-i18next';
import { TaskFormData } from '../Types';
import { useMultiStep } from './MultiStepProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { getFetcher, useApiClientContext, useWsChannelEvent } from '@heylog-app/frontend-lib/app';
import useSWR from 'swr';
import i18next from 'i18next';
import { WorkspaceEventsEnum } from '@heylog-app/shared/types';
export const useGetDetails = () => {
	const { apiClient } = useApiClientContext();
	const { workspaceId } = useParams()

	const getTransportDetails = useCallback(
		async ({ transportNumber, language }: { transportNumber: string; language: string }) => {
			const data = { transportNumber, language };
			return await apiClient.post(
				`/workspaces/${workspaceId}/orders/by-Transport-Number`,
				data,
			);
		},
		[apiClient]
	);
	const putTransportDetails = useCallback(
		async (data: any) => {
			return await apiClient.put(
				`/workspaces/${workspaceId}/orders/update-scb-order`,
				data,
			);
		},
		[apiClient]
	);
	const putFinalStep = useCallback(
		async (data: any) => {
			return await apiClient.put(
				`/workspaces/${workspaceId}/orders/complete-scb-order`,
				data,
			);
		},
		[apiClient]
	);
	// /workspaces/83/orders/1133/unloading-gate-locations


	return {
		getTransportDetails,
		putTransportDetails,
		putFinalStep,
	};
};

export const RegistrationForm = () => {
	const { t } = useTranslation();
	const { apiClient } = useApiClientContext();
	const { workspaceId } = useParams();
	const [loading, setLoading] = useState(false)
	const { step, isFirstStep, isLastStep, back, next, currentStepIndex, formMethods: { handleSubmit, setValue, watch, formState: { isValid } }, setTasksState, transportDetails, setTransportDetails, setDeliveryNotes, setRegisterState } = useMultiStep();
	const { getTransportDetails, putTransportDetails, putFinalStep } = useGetDetails()
	const requiredFieldsPerStep: { [key: number]: string[] } = {
		0: ["step1.preferredLanguage", "step1.transportNumber"],
		1: ["step2.tractorPlateNumber", "step2.trailerPlateNumber", "step2.driverName", "step2.phoneNumber"],
		2: [],
		3: ["step4.hasScrolled"],
		4: ["step5.communicationChanel"],
		5: [],
		6: [],
	};
	useEffect(() => {
		setTimeout(() => {
			setValue("step1.preferredLanguage", i18next.language);
		}, 2000)
	}, []);
	useEffect(() => {
		setValue("step1.preferredLanguage", i18next.language || "en");
	}, [i18next.language]);
	const currentFields = requiredFieldsPerStep[currentStepIndex] || [];

	const isCurrentStepValid = currentFields.every((field) => {
		const value = watch(field as string) ?? "";
		return isValid && (typeof value === "boolean" ? value : value.trim() !== "");
	});
	const [callForthApi, setCallForthApi] = useState<string | null>(null)
	const { data } =
		useSWR(
			callForthApi,
			getFetcher(apiClient),
			{
				revalidateOnFocus: false, // Disable revalidation on focus
			}
		);
	useEffect(() => {
		if (data) {
			setTasksState(data)
		}
	}, [data])
	useWsChannelEvent(WorkspaceEventsEnum.SCB_SMS_SENT, () => {
		console.log("event trigger", WorkspaceEventsEnum.SCB_SMS_SENT);
		setRegisterState("proceed");
	});
	const onSubmit = (data: TaskFormData) => {
		console.log(`Step ${currentStepIndex + 1} Data:`, data[`step${currentStepIndex + 1}` as keyof TaskFormData]);


		if (currentStepIndex === 4 && data?.step5.communicationChanel === 'heylog') {
			const deliveryNotes = data?.step3?.deliveryNotes ?? {};
			const hasMore = deliveryNotes["hasMore"] ?? false;
			setCallForthApi(`/workspaces/${workspaceId}/orders/${transportDetails?.orderId}/unloading-gate-locations`)
			const notAllSelected = Object.entries(deliveryNotes)
				.filter(([key]) => key !== "hasMore")
				.some(([, value]) => !value);

			if (hasMore || notAllSelected) {
				setRegisterState("error");
				next();
				return
			} else {
				setRegisterState("waiting");
				next();



				return;
			}
		}

		console.log('Submitted data:', data);
		if (currentStepIndex === 0) {
			setLoading(true)
			getTransportDetails({ transportNumber: data?.step1?.transportNumber || "", language: data?.step1?.preferredLanguage || "" })
				.then((res) => {
					setLoading(false)
					setTransportDetails({ ...res?.data, language: data?.step1?.preferredLanguage })
					setValue("step2.tractorPlateNumber", res?.data?.TractorPlateNumber)
					setValue("step2.trailerPlateNumber", res?.data?.TrailerPlateNumber)
					setValue("step2.driverName", res?.data?.FullName)
					setValue("step2.phoneNumber", res?.data?.PhoneNumber)
					next()
				}).catch((err) => {
					setLoading(false)
					console.log('err', err)
				})
		}
		if (currentStepIndex === 1) {
			setLoading(true)

			putTransportDetails({
				orderId: transportDetails?.orderId,
				"transportNumber": data?.step1?.transportNumber,
				"timeSlot": transportDetails?.timeSlot,
				"status": transportDetails?.status,
				"TractorPlateNumber": data?.step2?.tractorPlateNumber,
				"TrailerPlateNumber": data?.step2?.trailerPlateNumber,
				"FullName": data?.step2?.driverName,
				"PhoneNumber": data?.step2?.phoneNumber,
				language: data?.step1?.preferredLanguage,
			}).then((res) => {
				setLoading(false)
				next();
				setDeliveryNotes(res?.data)
			}).catch((err) => {
				setLoading(false)

				console.log('error', err)
			})
		}
		if (currentStepIndex === 5) {
			setLoading(true)

			putFinalStep({
				orderId: transportDetails?.orderId,
			}).then(() => {
				setLoading(false)
				next();
			}).catch(() => {
				setLoading(false)
			})
		}
		if (!isLastStep && currentStepIndex !== 0 && currentStepIndex !== 1 && currentStepIndex !== 5) {
			next();
			return;
		}
	}

	return (
		<StyledPageContent>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div>
					{step}
					{(currentStepIndex !== 2 && currentStepIndex !== 5) &&
						<StyledDiv>
							{(!isFirstStep && currentStepIndex !== 3 && !isLastStep) && (
								<StyledBackButton onClick={back}
								>
									{t('actionLabels.back')}
								</StyledBackButton>
							)}
							{!isLastStep && <StyledButton disabled={!isCurrentStepValid || loading} type='submit'
							>
								{t('actionLabels.confirm')}
							</StyledButton>}
						</StyledDiv>
					}
				</div>
			</form>
		</StyledPageContent >
	);
};
