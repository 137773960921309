import React from "react";
import { RegistrationForm } from "./registration-form";
import { MultiStepProvider } from "./MultiStepProvider";
import { useForm } from "react-hook-form";
import { useApiClientContext, useWorkspaceByJoinKey } from "@heylog-app/frontend-lib/app";
import { useParams, useSearchParams } from "react-router-dom";

export const FormWrapper = () => {
    const { joinKey = '' } = useParams();
    const { apiClient } = useApiClientContext();
    const [searchParams] = useSearchParams();
    const workspace = useWorkspaceByJoinKey(apiClient, joinKey).workspace;
    const workspaceName = workspace && (workspace.name || '');
    const formMethods = useForm<{
        step1: { preferredLanguage: string | null; transportNumber: string };
        step2: { tractorPlateNumber: string; trailerPlateNumber: string; driverName: string; phoneNumber: string };
        step3: { deliveryNotes: Record<string, boolean> };
        step4: { hasScrolled: boolean };
        step5: { communicationChanel: string };
        step6: object;
        step7: object;
    }>({
        mode: "onChange",
        defaultValues: {
            step1: { preferredLanguage: null, transportNumber: "" },
            step2: { tractorPlateNumber: "", trailerPlateNumber: "", driverName: "", phoneNumber: "" },
            step3: { deliveryNotes: {} },
            step4: { hasScrolled: false },
            step5: { communicationChanel: "" },
            step6: {},
            step7: {},

        },
    });
    const { control,
        formState: { errors, isValid },
        handleSubmit,
        register,
        reset,
        setValue,
        watch, } = formMethods;

    return (
        <MultiStepProvider formMethods={formMethods}>
            <RegistrationForm />
        </MultiStepProvider>);
};
