import Icon from "@mdi/react";
import { mdiAccountBoxMultipleOutline, mdiLogout } from "@mdi/js";
import { useTranslation } from "react-i18next";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import React, { useState, useCallback, useEffect } from "react";
import { Tabs } from "@mui/material";

import { WorkspaceinvitationUserRoleEnum } from "@heylog-app/shared/types";
import {
  ButtonV2,
  ButtonV2Dropdown,
  createPrivacyPolicy,
  createSafetyInstruction,
  createSiteMap,
  FlashSnackbar,
  ROUTES,
  SaveIcon,
  SidebarLayout,
  SidebarLayoutContent,
  useApiClientContext,
  usePusherBeamsContext,
  useSnackbar,
  useUser,
  useWorkspace,
} from "@heylog-app/frontend-lib/app";

import {
  StyledButtonsBlock,
  StyledHeaderTitle,
  StyledHeaderWrapper,
  StyledSettingsHeader,
  StyledSettingsTitle,
  StyledTab,
} from "./settings.styles";
import { ProfileInfo } from "./components/profile-info";
import { ProfileSettingsPage } from "./profile-settings";
import { OrderStatusSettingsPage } from "./order-status-settings";
import { WorkspaceSettingsPage } from "./workspace-settings";
import { MembersSettingsPage } from "./members-settings";

import type { FC } from "react";
import { DocumentSettingsPage } from "./document-settings";

export const SettingsPage: FC = () => {
  const { beamsClient } = usePusherBeamsContext();
  const [menuValue, setMenuValue] = useState(0);
  const [innerMenuValue, setInnerMenuValue] = useState(0);
  const { t } = useTranslation();
  const [safetyLang, setSafetyLang] = useState("en");
  const [sideLang, setSideLang] = useState("en");
  const { user } = useUser();
  const { workspaceId = "" } = useParams();
  const { workspace } = useWorkspace(workspaceId ? workspaceId : "-1", user);
  const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [fileDPA, setFileDPA] = useState<File>();
  const [fileeSafety, setFileSafety] = useState<File>();
  const [fileeSite, setFileSite] = useState<File>();

  const { apiClient } = useApiClientContext();
  const [menuOptions, setMenuOptions] = useState<
    { label: string; value: number }[]
  >([]);
  const navigate = useNavigate();
  const handleLogout = useCallback(() => {
    beamsClient?.stop().catch(console.error);
    localStorage.removeItem("pusherTransportTLS");
    localStorage.removeItem("pusherTransportNonTLS");
    navigate(ROUTES.LOGOUT);
  }, [navigate, beamsClient]);
  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const handleDPAChange = async () => {
    if (selectedValue === "leftValue") {
      if (fileDPA) {
        // const base64Content = fileDPA && (await fileToBase64(fileDPA));
        // let res = {
        //   name: fileDPA?.name,
        //   type: fileDPA?.type,
        //   base64Content: base64Content?.split(",")[1] || "",
        //   contentId: "", // You'll need to provide a content ID
        // };
        const formData = new FormData();
        formData.append("file", fileDPA);
        formData.append("preference", "false");
        createPrivacyPolicy(apiClient, formData, workspaceId).then((res) => {
          openSnackbar("success", t("settings.settingsSavedSuccessfully"));
          setMenuValue(2);
        });
      } else {
        openSnackbar("warning", "Please Upload File first");
      }
    } else {
      const formData = new FormData();
      formData.append("preference", "true");
      createPrivacyPolicy(apiClient, formData, workspaceId).then((res) => {
        setMenuValue(2);
        openSnackbar("success", t("settings.settingsSavedSuccessfully"));
      });
    }
  };
  const handleSafetyChange = async () => {
    if (fileeSafety) {
      //   const base64Content = fileeSafety && (await fileToBase64(fileeSafety));
      //   let res = {
      //     name: fileeSafety?.name,
      //     type: fileeSafety?.type,
      //     base64Content: base64Content?.split(",")[1] || "",
      //     contentId: "", // You'll need to provide a content ID
      //   };
      const formData = new FormData();
      formData.append("file", fileeSafety);
      formData.append("language", safetyLang);
      createSafetyInstruction(apiClient, formData, workspaceId).then((res) => {
        openSnackbar("success", t("settings.settingsSavedSuccessfully"));
        setMenuValue(2);
      });
    } else {
      openSnackbar("warning", "Please Upload File first");
    }
  };
  const handleSiteChange = async () => {
    if (fileeSite) {
      //   const base64Content = fileeSite && (await fileToBase64(fileeSite));
      //   let res = {
      //     name: fileeSite?.name,
      //     type: fileeSite?.type,
      //     base64Content: base64Content?.split(",")[1] || "",
      //     contentId: "", // You'll need to provide a content ID
      //   };
      const formData = new FormData();
      formData.append("file", fileeSite);
      formData.append("language", sideLang);
      createSiteMap(apiClient, formData, workspaceId).then((res) => {
        openSnackbar("success", t("settings.settingsSavedSuccessfully"));
        setMenuValue(2);
      });
    } else {
      openSnackbar("warning", "Please Upload File first");
    }
  };
  const handleWorkspaceClick = (id: number) => {
    navigate(
      generatePath(ROUTES.HOME, {
        workspaceId: id.toString(),
      })
    );
  };

  const handleChange = (event: unknown, newValue: number) => {
    setMenuValue(newValue);
    navigate(`#tab=${newValue}`);
  };

  useEffect(() => {
    const options: { label: string; value: number }[] = [];
    user?.workspaces &&
      user.workspaces.map((workspace) => {
        return options.push({ label: workspace.name, value: workspace.id });
      });
    setMenuOptions(options);
  }, [user]);

  return (
    <SidebarLayout>
      <SidebarLayoutContent>
        <StyledHeaderWrapper>
          <StyledHeaderTitle>
            <StyledSettingsHeader>
              <StyledSettingsTitle>
                <ProfileInfo
                  workspaceName={workspace?.name}
                  height={80}
                  width={80}
                />
              </StyledSettingsTitle>

              <StyledButtonsBlock>
                <ButtonV2Dropdown
                  size="medium"
                  $variant="light"
                  type="button"
                  handleFuncClick={handleWorkspaceClick}
                  startIcon={
                    <Icon path={mdiAccountBoxMultipleOutline} size={1} />
                  }
                  options={menuOptions}
                >
                  {t("settings.labels.changeTeam")}
                </ButtonV2Dropdown>

                <ButtonV2
                  size="medium"
                  $variant="light2"
                  type="button"
                  onClick={handleLogout}
                  data-test="settings-user-save-button"
                  startIcon={<Icon path={mdiLogout} size={1} />}
                >
                  {t("settings.labels.logOut")}
                </ButtonV2>
                {menuValue === 1 && innerMenuValue === 0 && (
                  <ButtonV2
                    size="medium"
                    $variant="dark"
                    type="button"
                    onClick={handleDPAChange}
                    data-test="settings-user-save-button"
                    startIcon={<SaveIcon />}
                  >
                    {t("settings.labels.saveChanges")}
                  </ButtonV2>
                )}
                {menuValue === 1 && innerMenuValue === 1 && (
                  <ButtonV2
                    size="medium"
                    $variant="dark"
                    type="button"
                    onClick={handleSafetyChange}
                    data-test="settings-user-save-button"
                    startIcon={<SaveIcon />}
                  >
                    {t("settings.labels.saveChanges")}
                  </ButtonV2>
                )}
                {menuValue === 1 && innerMenuValue === 2 && (
                  <ButtonV2
                    size="medium"
                    $variant="dark"
                    type="button"
                    onClick={handleSiteChange}
                    data-test="settings-user-save-button"
                    startIcon={<SaveIcon />}
                  >
                    {t("settings.labels.saveChanges")}
                  </ButtonV2>
                )}
              </StyledButtonsBlock>
            </StyledSettingsHeader>
            <Tabs value={menuValue} onChange={handleChange}>
              <StyledTab label={t("settings.labels.myProfile")} />
              <StyledTab label={t("settings.labels.document")} />
              <StyledTab label={t("settings.labels.myTeam")} />
              <StyledTab label={t("settings.labels.customers")} />
              <StyledTab label={t("settings.labels.orderStatus")} />
            </Tabs>
          </StyledHeaderTitle>
        </StyledHeaderWrapper>
        <FlashSnackbar
          controls={[stateSnackbar, openSnackbar, closeSnackbar]}
        />

        {menuValue === 0 && <ProfileSettingsPage />}
        {menuValue === 2 && <WorkspaceSettingsPage />}
        {menuValue === 1 && (
          <DocumentSettingsPage
            fileDPA={fileDPA}
            setFileDPA={setFileDPA}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            setMenuValuee={setInnerMenuValue}
            setFileSafety={setFileSafety}
            fileSafety={fileeSafety}
            setFileSite={setFileSite}
            fileSite={fileeSite}
            sideLang={sideLang}
            safetyLang={safetyLang}
            setSafetyLang={setSafetyLang}
            setSideLang={setSideLang}
          />
        )}
        {menuValue === 3 && (
          <MembersSettingsPage
            role={WorkspaceinvitationUserRoleEnum.CUSTOMER}
          />
        )}
        {menuValue === 4 && <OrderStatusSettingsPage />}
      </SidebarLayoutContent>
      {/*<SidebarLayoutContent>*/}
      {/*	<Outlet />*/}
      {/*</SidebarLayoutContent>*/}
    </SidebarLayout>
  );
};
