import { Badge } from '@mui/material';
import { mdiAccount } from '@mdi/js';
import Icon from '@mdi/react';

import { StyledAvatar, StyledTransportTypeIcon } from './conversation-avatar.styles';
import { getNameInitials } from '../../../util/get-name-initials';
import { getCommunicationChannelIcon } from '../../../util';

import type { AvatarProps } from '@mui/material';
import type { FC } from 'react';
import type {
	FullContactResInterface,
	MessagingTransportType,
} from '@heylog-app/shared/types';

type ConversationAvatarProps = AvatarProps & {
	transportType: MessagingTransportType;
	contact: FullContactResInterface;
};

export const ConversationAvatarV2: FC<ConversationAvatarProps> = ({
	transportType,
	contact,
	...props
}) => {
	const CommunicationChannelIcon = getCommunicationChannelIcon(transportType);
	console.log("contact ====", contact?.lastMessage )
	return (
		<Badge
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			badgeContent={
				<StyledTransportTypeIcon>
					<CommunicationChannelIcon />
				</StyledTransportTypeIcon>
			}
			overlap="circular"
		>
			<StyledAvatar src={contact?.lastMessage?.profilePicturePath || ""} >
				{contact?.lastMessage?.profilePicturePath && (getNameInitials(contact.firstName, contact.lastName) || (
					<Icon path={mdiAccount} size={'1rem'} />
				))}
			</StyledAvatar>
		</Badge>
	);
};
