import { useEffect, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { useAbility } from "@casl/react";

import { Actions, WorkspaceEventsEnum } from "@heylog-app/shared/types";

import {
  getContactsKey,
  getQueryString,
  deleteContactAPI,
  updateContactAPI,
  getFetcher,
  ROUTES,
  getContactsKeyHeylog,
} from "../util";
import { useWsChannelEvent } from "./use-ws-channel-event";
import { useMatchMutate } from "./use-match-mutate";
import { useApiClientContext } from "./use-api-client-context.hook";
import { AbilityContext } from "../providers/ability";

import type { ArchiveEntryParams, UpdateEntryParams } from "../types";
import type { Key } from "swr";
import type {
  FullContactResInterface,
  UpdateContactReqInterface,
  UnreadMessagesResInterface,
  Nullable,
  ContactStatusType,
  FullNewContactResInterface,
  ContactStatusTypeV2,
} from '@heylog-app/shared/types';

const useContactKey = (params?: {
  orderId?: string;
  q?: string;
  id?: Nullable<string>;
  status?: ContactStatusTypeV2;
  page?: number;
  rowsPerPage?: number;
}): Key => {
  const { workspaceId } = useParams();

  const baseContactsKey = workspaceId ? getContactsKey(workspaceId) : undefined;

  if (!params) return baseContactsKey;

  const {
    orderId,
    q = undefined,
    id = undefined,
    status = undefined,
    page,
    rowsPerPage,
  } = params;

  if (id) return `${baseContactsKey}/${id}`;

  const queryParams = {
    ...(status !== "ALL" && { status: status }),
    ...(q && { q }),
    ...(orderId && { orderId }),
    ...(page && { page }),
    ...(rowsPerPage && { rowsPerPage }),
  };

  return `${baseContactsKey}${getQueryString(queryParams)}`;
};

export const useContacts = ({
  orderId,
  q,
  status,
  page,
  rowsPerPage,
}: {
  orderId?: string;
  q?: string;
  status?: ContactStatusTypeV2;
  page?: number;
  rowsPerPage?: number;
}) => {
  const ability = useAbility(AbilityContext);
  const { apiClient } = useApiClientContext();

  const contactsKey = useContactKey({ orderId, q, status, page, rowsPerPage: rowsPerPage });

  const { data, error, mutate, isValidating, isLoading } = useSWR<
    FullNewContactResInterface
  >(
    ability.can(Actions.READ, "Conversation") ? contactsKey : null,
    getFetcher(apiClient)
  );

  const updateContacts = useCallback(() => mutate(), [mutate]);

  useWsChannelEvent(WorkspaceEventsEnum.CONTACT_UPDATED, () => {
    mutate().catch((e) => console.error("error fetching contacts", e));
  });
  useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_STATUS_UPDATED, () => {
    console.log('[Realtime]: handling "message:status:updated" event');
    mutate().catch((e) => console.error("error updating unread", e));
  });

  useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_RECEIVED, () => {
    console.log('[Realtime]: handling "message:status:updated" event');
    mutate().catch((e) => console.error("error updating unread", e));
  });
  // useEffect(() => {
  // mutate();
  // }, [q, orderId, mutate contactsKey]); //causes api call multiple times

  const filteredContacts = data?.data?.filter(
    (contact) => contact?.conversations?.length > 0
  );

  return {
    contacts: filteredContacts,
    isLoading,
    isError: error,
    updateContacts,
    isValidating,
    pagination: data?.pagination,
  };
};

const useUnreadKey = (): Key => {
  const { workspaceId } = useParams();
  return workspaceId ? getContactsKey(workspaceId) + "/unread" : undefined;
};

export const useUnread = () => {
  const ability = useAbility(AbilityContext);
  const { apiClient } = useApiClientContext();
  const contactsKey = useUnreadKey();

  const { data, error, mutate, isLoading } = useSWR<UnreadMessagesResInterface>(
    ability.can(Actions.MANAGE, "Conversation") ? contactsKey : null,
    getFetcher(apiClient)
  );

  useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_STATUS_UPDATED, () => {
    console.log('[Realtime]: handling "message:status:updated" event');
    mutate().catch((e) => console.error("error updating unread", e));
  });

  useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_RECEIVED, () => {
    console.log('[Realtime]: handling "message:status:updated" event');
    mutate().catch((e) => console.error("error updating unread", e));
  });

  return {
    unreadMap: data,
    isLoading,
    isError: error,
    updateUnread: mutate,
  };
};

export const useUnreadHeylog = () => {
  const ability = useAbility(AbilityContext);
  const { workspaceId } = useParams();

  const { apiClient } = useApiClientContext();
  const contactsKey = workspaceId ? getContactsKeyHeylog(workspaceId) : "";

  const { data, error, mutate, isLoading } = useSWR<UnreadMessagesResInterface>(
    ability.can(Actions.MANAGE, "Conversation") ? contactsKey : null,
    getFetcher(apiClient)
  );
  useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_USER_TO_USER, () => {
    console.log('[Realtime]: handling "message:status:updated" event');
    mutate().catch((e) => console.error("error updating unread", e));
  });
  useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_USER_TO_USER_READ, () => {
    console.log('[Realtime]: handling "message:status:updated" event');
    mutate().catch((e) => console.error("error updating unread", e));
  });

  return {
    unreadMapHeylog: data,
    isLoading,
    isError: error,
    updateUnread: mutate,
  };
};

export const useContact = (id?: Nullable<string>) => {
  const ability = useAbility(AbilityContext);
  const { apiClient } = useApiClientContext();
  const navigate = useNavigate();

  const contactsKey = useContactKey({ id });

  const { data, error, mutate, isLoading } = useSWR<FullContactResInterface>(
    id && ability.can(Actions.READ, "Conversation") ? () => contactsKey : null,
    getFetcher(apiClient)
  );



  return {
    contact: data,
    contactError: error,
    updateContact: mutate,
    revalidateContact: mutate,
    isLoading,
  };
};
export const useConversationGroup = ({ type }: { type?: ContactStatusType }) => {
  const ability = useAbility(AbilityContext);
  const { apiClient } = useApiClientContext();
  const navigate = useNavigate();
  const { workspaceId } = useParams();

  const contactsKey = `workspaces/${workspaceId}/conversations-v2/user-to-user/conversations${type ? `?conversationStatus=${type}` : ''}`

  const { data, error, mutate, isLoading } = useSWR<any>(
    ability.can(Actions.READ, "Conversation") ? () => contactsKey : null,
    getFetcher(apiClient)
  );
  useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_USER_TO_USER, () => {
    mutate()
  });


  return {
    converGroupData: data,
    converGroupError: error,
    updateConverGroup: mutate,
    revalidateConverGroup: mutate,
    isLoadingConverGroup: isLoading,
  };
};
export const useContactActions = (id?: Nullable<string>) => {
  const { apiClient } = useApiClientContext();
  const [loading, setLoading] = useState(false);
  const { workspaceId = "" } = useParams();
  const baseContactsKey = getContactsKey(workspaceId);

  const matchMutate = useMatchMutate();

  const { contact, revalidateContact, contactError, isLoading } =
    useContact(id);

  const updateContact = useCallback(
    async (params: any) => {
      let response;
      setLoading(true);
      try {
        response = await updateContactAPI(apiClient, params);
      } catch (err) {
        setLoading(false);
        throw err;
      }

      //wildcard mutate for everything that matches starting baseContactsKey
      await matchMutate(new RegExp(`^${baseContactsKey}.*$`));
      setLoading(false);

      return response;
    },
    [apiClient, matchMutate, baseContactsKey]
  );

  useWsChannelEvent(WorkspaceEventsEnum.CONTACT_UPDATED, () => {
    console.log('[Realtime]: handling "contact:updated" event');
    revalidateContact().catch((e) =>
      console.error("error fetching contacts", e)
    );
  });

  const deleteContact = useCallback(
    (params: ArchiveEntryParams) => {
      return deleteContactAPI(apiClient, params).then(
        async () => await matchMutate(new RegExp(`^${baseContactsKey}.*$`))
      );
    },
    [apiClient, baseContactsKey, matchMutate]
  );

  return {
    contact,
    isLoading,
    updateContactLoading: loading,
    isError: contactError,
    updateContact,
    refetchContact: revalidateContact,
    deleteContact,
  };
};
