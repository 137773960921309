import type { FC } from 'react';

export const SvgKR: FC = () => (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2301_3137)">
            <mask id="mask0_2301_3137"  maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="13">
                <path d="M0.594696 0.657715H16.4055V12.3434H0.594696V0.657715Z" fill="white" />
            </mask>
            <g mask="url(#mask0_2301_3137)">
                <path fill-rule="evenodd" clipRule="evenodd" d="M0.594696 0.657715H16.4078V12.3434H0.594696V0.657715Z" fill="white" />
                <path fill-rule="evenodd" clipRule="evenodd" d="M2.32715 4.21143L3.97675 1.77655L4.38658 2.04561L2.73698 4.48048L2.32715 4.21143ZM2.94189 4.61501L4.59149 2.18014L5.00132 2.4492L3.35172 4.88407L2.94189 4.61501ZM3.55664 5.0186L5.20624 2.58372L5.61607 2.85278L3.96647 5.28765L3.55664 5.0186Z" fill="black" />
                <path fill-rule="evenodd" clipRule="evenodd" d="M11.37 10.1553L13.0196 7.7204L13.4294 7.98946L11.7798 10.4243L11.37 10.1553ZM11.9847 10.5589L13.6343 8.12399L14.0441 8.39304L12.3945 10.8279L11.9847 10.5589ZM12.5995 10.9624L14.2491 8.52757L14.6589 8.79663L13.0093 11.2315L12.5995 10.9624Z" fill="black" />
                <path fill-rule="evenodd" clipRule="evenodd" d="M11.9868 8.80029L14.042 10.1511L11.9868 8.80029Z" fill="black" />
                <path d="M11.9868 8.80029L14.042 10.1511" stroke="white" stroke-width="0.247031" />
                <path fill-rule="evenodd" clipRule="evenodd" d="M6.02676 4.89111C6.46482 4.24444 7.14441 3.79462 7.91602 3.64061C8.68763 3.48659 9.48806 3.641 10.1412 4.06987C10.7944 4.49873 11.2468 5.16692 11.3989 5.92744C11.551 6.68796 11.3903 7.47851 10.9523 8.12518L6.02676 4.89111Z" fill="#CD2E3A" />
                <path fill-rule="evenodd" clipRule="evenodd" d="M6.03294 4.88634C5.59509 5.53265 5.43452 6.32275 5.58655 7.08284C5.73858 7.84293 6.19077 8.51074 6.84363 8.93936C7.49649 9.36798 8.29654 9.5223 9.06779 9.36838C9.83904 9.21445 10.5183 8.76488 10.9562 8.11858C11.1751 7.79543 11.2554 7.40037 11.1794 7.02033C11.1033 6.64029 10.8772 6.30638 10.5508 6.09207C10.2244 5.87776 9.82436 5.8006 9.43874 5.87756C9.05311 5.95452 8.71348 6.17931 8.49455 6.50246L6.03294 4.88634Z" fill="#0047A0" />
                <path d="M8.49022 6.50151C8.94594 5.82885 8.76453 4.92191 8.08503 4.47581C7.40553 4.02971 6.48525 4.21337 6.02953 4.88604C5.57381 5.5587 5.75522 6.46564 6.43472 6.91174C7.11422 7.35785 8.0345 7.17418 8.49022 6.50151Z" fill="#CD2E3A" />
                <path fill-rule="evenodd" clipRule="evenodd" d="M3.98364 11.2319L2.33404 8.79706L2.74387 8.528L4.39347 10.9629L3.98364 11.2319ZM4.59839 10.8283L2.94879 8.39347L3.35862 8.12442L5.00822 10.5593L4.59839 10.8283ZM5.21313 10.4248L3.56353 7.98989L3.97336 7.72083L5.62296 10.1557L5.21313 10.4248Z" fill="black" />
                <path fill-rule="evenodd" clipRule="evenodd" d="M13.0263 5.28857L11.3767 2.8537L11.7866 2.58464L13.4362 5.01952L13.0263 5.28857ZM13.6411 4.88499L11.9915 2.45011L12.4013 2.18106L14.0509 4.61593L13.6411 4.88499ZM14.2558 4.4814L12.6062 2.04653L13.0161 1.77747L14.6657 4.21235L14.2558 4.4814Z" fill="black" />
                <path fill-rule="evenodd" clipRule="evenodd" d="M3.6701 9.6792L4.28666 9.27397L3.6701 9.6792ZM11.9936 4.20856L12.7129 3.73579L11.9936 4.20856ZM13.3295 3.33055L13.946 2.92532L13.3295 3.33055Z" fill="black" />
                <path d="M3.6701 9.6792L4.28666 9.27397M11.9936 4.20856L12.7129 3.73579M13.3295 3.33055L13.946 2.92532" stroke="white" stroke-width="0.247031" />
            </g>
        </g>
        <rect x="0.629371" y="0.691596" width="15.7413" height="11.6169" rx="1.34041" stroke="#BFBFC2" stroke-width="0.0687391" />
        <defs>
            <clipPath id="clip0_2301_3137">
                <rect x="0.595001" y="0.657227" width="15.81" height="11.6857" rx="1.37478" fill="white" />
            </clipPath>
        </defs>
    </svg>


);
// style="mask-type:luminance"