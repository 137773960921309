import styled from "styled-components";
import { ListItemText } from "@mui/material";
import Select from "@mui/material/Select";
import { TextField, Typography } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import {
  colors,
  SIZE_MD,
  STYLE_1,
  T3,
  T1_medium,
  T2,
  T2_medium,
  T3_medium,
  medium_T1,
} from "@heylog-app/frontend-lib/app";

export const StyledTextWrapper = styled.div`
  ${STYLE_1};
  ${SIZE_MD};
  align-self: center;
  width: clamp(18.75rem, 90vw, 24.6rem);
  line-height: 1.4rem;
  justify-items: center;
  justify-self: center;
`;

export const StyledPageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledDiv1 = styled.div`
  color: var(--White-Black, #000);
  text-align: center;
  width: 20rem;
  justify-self: center;

  font-style: normal;
  ${T2_medium};
`;

export const StyledDiv2 = styled.div`
  color: var(--White-Black, #000);
  text-align: center;
  ${T3};
  font-style: normal;

  padding: 0px 3.375rem;
`;
export const StyledDiv3 = styled.div`
  color: var(--White-Black, #000);
  text-align: center;
  ${T3_medium};
  font-style: normal;
  justify-self: center;
  width: clamp(18rem, 90%, 23rem);
`;

export const StyledDiv4 = styled.div`
  color: var(--White-Black, #000);
  text-align: center;
  ${medium_T1};
  font-style: normal;
  @media (max-width: 340px) {
    font-size: 14px;
  }
`;

export const StyledSpan3 = styled.p`
  color: var(--White-Black, #000);
  text-align: center;

  ${T1_medium};
  font-style: normal;
`;

export const StyledForm = styled.div`
  width: clamp(18rem, 90%, 23rem);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  margin-top: 0.9375rem;
  transition: width 0.3s ease-in-out;
`;

export const StyledFormV2 = styled.div`
  width: clamp(16rem, 90vw, 23rem);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  margin-top: 1rem;
`;

export const StyledPdfContainer = styled.div`
  width: clamp(16rem, 90vw, 20rem);
  justify-items: center;
  border-top: 1px solid #d9d9d9;
`;

export const StyledSelect = styled(Select)<{
  hasValue?: boolean;
  error?: boolean;
}>`
  height: 2.625rem;
  font-size: 0.875rem;
  color: #666;
  padding: 0.5rem 0.625rem 0.5rem 1rem;

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid
      ${({ error, hasValue }) =>
        error
          ? "rgb(223, 38, 38)"
          : hasValue
          ? "var(--Primary-Main, #122CC5)"
          : "var(--White-300, #e1e4ea)"};
  }

  .MuiInputBase-input {
    padding: 0;
    color: var(--Primary-Main, #122cc5);
  }
  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--Primary-Main, #122cc5) !important; /* Blue border */
    }
  }

  border-radius: 4px;
  background: var(--White-100, #fff);

  @media (max-width: 365px) {
    height: 2.4rem;
    font-size: 0.75rem;
    padding: 0.4rem 0.5rem;
  }
`;

export const StyledInput = styled(TextField)<{
  error?: boolean;
  hasValue?: boolean;
}>`
  width: 100%;
  font-size: 0.875rem;
  height: 2.625rem;
  color: var(--Primary-Main, #122cc5);

  & .MuiInputBase-root {
    font-family: Inter;
    padding: 0.5rem 0.625rem 0.5rem 1rem;
    height: 2.625rem;
  }

  .MuiOutlinedInput-root {
    border-radius: 4px;
    background: var(--White-100, #fff);

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--Primary-Main, #122cc5);
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: var(--Primary-Main, #122cc5);
    }

    &.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: rgb(223, 38, 38);
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid
      ${({ error, hasValue }) =>
        error
          ? "rgb(223, 38, 38)"
          : hasValue
          ? "var(--Primary-Main, #122CC5)"
          : "var(--White-300, #e1e4ea)"};
  }

  .MuiInputBase-input {
    padding: 0;
    color: var(--Primary-Main, #122cc5);
  }

  .MuiInputBase-input::placeholder {
    color: var(--White-600, #7c839e);
    opacity: 1;
    font-style: italic;
    ${T3};
  }

  @media (max-width: 365px) {
    font-size: 0.75rem;
    height: 2.4rem;

    & .MuiInputBase-root {
      padding: 0.4rem 0.5rem;
      height: 2.4rem;
    }
  }
`;

export const StyledInputV2 = styled(TextField)<{
  error?: boolean;
  hasValue?: boolean;
}>`
  width: 100%;
  font-size: 14px;
  height: 42px;
  color: var(--Primary-Main, #122cc5);
  & .MuiInputBase-root {
    font-family: Inter;
    padding: 8px 10px 8px 16px;
    height: 42px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--Primary-Main, #122cc5);
  }

  .MuiInputBase-input {
    padding: 0px;
    color: var(--Primary-Main, #122cc5);
  }

  .MuiOutlinedInput-root {
    border-radius: 4px;
    background: var(--White-100, #fff);

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: var(--Primary-Main, #122cc5);
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: var(--Primary-Main, #122cc5);
    }
  }
`;

export const StyledButton = styled.button`
  height: 32px;
  min-width: 120px;
  max-width: auto;
  color: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
  padding: 5px 20px 5px 20px;

  border-radius: 4px;
  background: var(--Primary-Main, #122cc5);

  font-style: normal;
  ${T2_medium};

  background-color: ${({ disabled }) =>
    disabled ? "var(--White-300, #E1E4EA)" : "#1546c2"};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export const StyledBackButton = styled.button`
  color: var(--White-600, #7c839e);

  height: 2rem;
  font-style: normal;
  background: none;
  border: none;
  text-align: center;
  align-self: center;
  ${T2};
`;

export const StyledTextWrapperPage2 = styled.div`
  text-align: center;
  width: 23rem;
  @media (max-width: 340px) {
    width: 20rem;
  }
`;

export const StyledDetails = styled(Typography)`
  margin: 0.56rem 0;

  .detail-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    color: var(--Primary-Main, #122cc5);

    font-style: normal;
    ${T3_medium};
  }
  p {
    color: var(--Primary-Main, #122cc5);
    margin: 0px;

    font-style: normal;
    ${T3};
  }
  .header {
    ${T2_medium}
  }
`;

export const StyledListItemText = styled(ListItemText)`
  color: ${colors.grey[900]};
  &:hover {
    background-color: rgb(209, 212, 242);
  }
`;
interface BorderLinearProgressProps {
  status: "error" | "proceed" | "waiting";
}

export const BorderLinearProgress = styled(
  LinearProgress
)<BorderLinearProgressProps>`
  height: 10px;
  width: 90%;
  justify-self: center;
  border-radius: 5px;

  &.${linearProgressClasses.colorPrimary} {
    background-color: ${({ status }) =>
      status === "error"
        ? "#FFCFCF"
        : status === "proceed"
        ? "#CFFFD6"
        : "#D3EAF7"};
  }

  & .${linearProgressClasses.bar} {
    border-radius: 5px;
    background-color: ${({ status }) =>
      status === "error"
        ? "#F53232"
        : status === "proceed"
        ? "#30914C"
        : "#1588C7"};
  }
  @media (max-width: 340px) {
    width: 74%;
  }
`;

interface StatusMessageProps {
  status: "error" | "proceed" | "waiting";
}

export const StyledStatusMessage = styled.div<StatusMessageProps>`
  max-width: 100%;
  justify-self: center;
  margin-top: 20px;
  min-height: 40px;
  border: 1px solid
    ${({ status }) =>
      status === "error"
        ? "var(--Error-Dark, #B20F0F)"
        : status === "waiting"
        ? "#1588C7"
        : "var(--Success-Dark, #198A1F)"};
  border-radius: 10px;
  padding: 8px 15px;
  display: block;
  color: ${({ status }) =>
    status === "error"
      ? "var(--Error-Dark, #B20F0F)"
      : status === "waiting"
      ? "#1588C7"
      : "var(--Success-Dark, #198A1F)"};
  background: ${({ status }) =>
    status === "error"
      ? "var(--Error-Light, #FFCFCF)"
      : status === "waiting"
      ? "#CCE8F8"
      : "var(--Success-Light, #D9F9DA)"};
  ${T2};
  @media (max-width: 340px) {
    font-size: 13px;
  }
`;
export const StyledDiv = styled.div`
  height: fit-content;
  display: flex;
  gap: 2.75rem;
  justify-content: center;
`;
export const StyledPlaceholder = styled(Typography)`
  color: var(--White-600, #7c839e);

  font-style: italic;
  ${T3};
`;
