import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const UserOrderStatusEnum = mkenum({
	CREATED: 'CREATED',
	IN_TRANSIT: 'IN_TRANSIT',
	DELIVERED: 'DELIVERED',
	ARCHIVED: 'ARCHIVED',
});

export type UserOrderStatus = TypeFromPEnum<typeof UserOrderStatusEnum>;

// when switching to one of these statuses, send a OSU reminder
export const OSUReminderStartUserOrderStatuses = [
	// CREATED will work for TM but break the flow for regular OSUs
	UserOrderStatusEnum.CREATED,
	UserOrderStatusEnum.IN_TRANSIT,
];

// when switching to one of these statuses, stop sending a OSU reminder
export const OSUReminderStopUserOrderStatuses = [
	UserOrderStatusEnum.DELIVERED,
	UserOrderStatusEnum.ARCHIVED,
];

// when switching to one of these statuses, stop sending all Task reminders for that order (including warnings)
export const TaskReminderStopUserOrderStatuses = [
	UserOrderStatusEnum.DELIVERED,
	UserOrderStatusEnum.ARCHIVED,
];

export const ContactOrderStatusEnum = mkenum({
	IN_TRANSIT: 'IN_TRANSIT',
	CREATED: 'CREATED',
	DELIVERED: 'DELIVERED',
	ARCHIVED: 'ARCHIVED',
	APPROACHING: 'APPROACHING',
});

export type ContactOrderStatus = TypeFromPEnum<typeof ContactOrderStatusEnum>;

export const TimeMattersOrderStatusEnum = mkenum({
	APPROACHING: 'APPROACHING',
	ARRIVED_AT_LOADING: 'ARRIVED_AT_LOADING',
	LOADED: 'LOADED',
	ARRIVED_AT_UNLOADING: 'ARRIVED_AT_UNLOADING',
	DELIVERED: 'DELIVERED',
});

export type TimeMattersOrderStatus = TypeFromPEnum<typeof TimeMattersOrderStatusEnum>;


export const ScbOrderStatusEnum = mkenum({
	CREATED: 'CREATED',
	IN_TRANSIT: 'IN_TRANSIT',
	DELIVERED: 'DELIVERED',
	ARCHIVED: 'ARCHIVED',
	IN_REGISTRATION: 'IN_REGISTRATION',
	GO_TO_GATEHOUSE: 'GO_TO_GATEHOUSE',
	SUCCESSFULLY_REGISTERED: 'SUCCESSFULLY_REGISTERED',
	ACCESSING_YARD: 'ACCESSING_YARD'
});

export type ScbOrderStatus = TypeFromPEnum<typeof ScbOrderStatusEnum>;
