import { useCallback, useEffect, useRef, useState } from 'react';
import {
	Chip,
	Dialog,
	DialogTitle,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Tooltip,
} from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mdiAccount, mdiAccountRemove, mdiPencil, mdiTruck } from '@mdi/js';
import Icon from '@mdi/react';

import { ContactRoleEnum, MessagingTransportTypeEnum } from '@heylog-app/shared/types';
import { getRoleIcon, truncateText } from '@heylog-app/shared/util';

import { colors } from '../../../styles/theme/colors';
import {
	StyledContactDetail,
	StyledContactLabel,
	StyledContactName,
	StyledContactDetailIcon,
	StyledLinkedOrderChip,
	StyledP,
	StyledDiv,
	StyledText,
} from './contact-list-item.styles';
import { SidebarListItem, SidebarListItemV2 } from '../../sidebar';
import { ButtonMenu, ContactName, ConversationAvatar, ConversationAvatarV2, ModalClose } from '../../ui';
import { useApiClientContext, useDialog, useMenu } from '../../../hooks';
import { ROUTES, disconnectContactFromOrderAPI, formatDateCustom } from '../../../util';
import { EditContactForm } from '../../forms/edit-contact-form/edit-contact-form';

import type { FullContactResInterface } from '@heylog-app/shared/types';
import type { ListItemProps } from '@mui/material';
import type { FC } from 'react';

type ContactListItemProps = Omit<ListItemProps, 'onClick'> & {
	contact: FullContactResInterface;
	unreadCount: number;
	onSelectContact: (contact: FullContactResInterface) => void;
};

const StyledChip = styled(Chip)`
	margin-left: 5px;
	border-radius: 12px;
	background-color: #35C23D;
	color: #FFFFFF;
`;

export const ContactListItemV2: FC<ContactListItemProps> = ({
	contact,
	unreadCount,
	onSelectContact,
	...props
}) => {
	const { apiClient } = useApiClientContext();
	const { contactId = '', orderId = '', workspaceId = '' } = useParams();
	const { t } = useTranslation();


	const { id, company } = contact;
	const isActive = contactId === id?.toString();


	const handleItemClick = useCallback(() => {
		onSelectContact(contact);
	}, [contact, onSelectContact]);

	const [isOverflowed, setIsOverflow] = useState(false);
	const textElementRef = useRef<HTMLDivElement>(null);
	const truncatedName = truncateText(contact?.chatName || 'New Contact', 25);
	const truncatedText = truncateText(contact?.lastMessage?.text || '', 35);

	useEffect(() => {
		if (!textElementRef.current) return;
		setIsOverflow(
			textElementRef.current.scrollWidth > textElementRef.current.clientWidth,
		);
	}, [contact?.chatName]);
	return (
		<SidebarListItemV2
			{...props}
			onClick={handleItemClick}
			active={isActive}

		>
			<ListItemAvatar>
				<ConversationAvatarV2
					contact={contact}
					src=""
					transportType={MessagingTransportTypeEnum.HEYLOG}
				/>
			</ListItemAvatar>
			<ListItemText style={{ alignItems: 'center' }}>
				<StyledDiv>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<Tooltip title={contact?.chatName || "New Contact"}>
							<StyledContactName ref={textElementRef}>{truncatedName}</StyledContactName>
						</Tooltip>
						<StyledText>
							{`${contact?.lastMessage?.direction === "TO_CONTACT" ? "You" : contact?.lastMessage?.transportMessage?.sender?.firstName}: ${truncatedText}`}
						</StyledText>
					</div>
					<div>
						{!!unreadCount && <StyledChip label={unreadCount} size={'small'} />}
					</div>
				</StyledDiv>

				{/* <StyledContactDetail>
					<StyledContactLabel>{t(`messages.routeOptimizationPlan`)}</StyledContactLabel>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							{contact?.orderReference && <StyledLinkedOrderChip label={contact?.orderReference} />}
						</div>
						<StyledP>
							{formatDateCustom(contact?.updatedAt)}
						</StyledP>
					</div>
				</StyledContactDetail> */}
			</ListItemText>
		</SidebarListItemV2>
	);
};
