import { Avatar, Box, Card, InputAdornment, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useRef, type FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
	IconNoConver,
	IconSend,
	InputV2,
	TabsGroupV2,
	colors,
	getNameInitials,
	useConversationsV2,
	useUserToUserMessageActions,
	useUserToUserMessages,
	useWsChannelEvent,
} from '@heylog-app/frontend-lib/app';
import SendIcon from '@mui/icons-material/Send';
import { ConversationTypeEnum, WorkspaceEventsEnum } from '@heylog-app/shared/types';

import { StyledDivNoConver, StyledTitle, TypoNoConversation } from './comments.styles';

import type {
	FullMessageResInterface,
	MessageUserToUserPartialResInterface,
	ConversationType,
} from '@heylog-app/shared/types';
import Icon from '@mdi/react';
import { mdiFileSend } from '@mdi/js';
import { StyledAvatar } from 'libs/frontend-lib/modules/contacts/src/pages/settings/settings.styles';

type FormData = {
	comment: string;
};

const internalTab = {
	title: 'shipment.internal',
	value: ConversationTypeEnum.HEYLOG_INTERNAL as ConversationType,
};
const externalTab = {
	title: 'shipment.customer',
	value: ConversationTypeEnum.HEYLOG_EXTERNAL as ConversationType,
};


export const Comments: FC = () => {
	const { t } = useTranslation();
	const [type] = useState<ConversationType>(internalTab.value);

	const { shipmentId } = useParams();
	const [internalMessageState, setInternalMessageState] = useState()
	const [externalMessageState, setExternalMessageState] = useState()
	const { handleSubmit, control, register, reset } = useForm<FormData>({
		defaultValues: {
			comment: '',
		},
	});

	const { conversations, updateConversationsV2 } = useConversationsV2([
		ConversationTypeEnum.HEYLOG_INTERNAL,
		ConversationTypeEnum.HEYLOG_EXTERNAL,
	]);
	const internalConversation = conversations?.data?.find(
		(conversation) => conversation.type === ConversationTypeEnum.HEYLOG_INTERNAL,
	);
	const externalConversation = conversations?.data?.find(
		(conversation) => conversation.type === ConversationTypeEnum.HEYLOG_EXTERNAL,
	);

	const { createUserToUserMessage: createUserToUserMessageInternal } =
		useUserToUserMessageActions(internalConversation?.id.toString() || '');
	const { createUserToUserMessage: createUserToUserMessageExternal } =
		useUserToUserMessageActions(externalConversation?.id.toString() || '');

	const onSubmitCommentForm = useCallback(
		async (data: FormData) => {
			if (data.comment.trim() === '') {
				return;
			}
			const createUserToUserMessage =
				type === ConversationTypeEnum.HEYLOG_INTERNAL
					? createUserToUserMessageInternal
					: createUserToUserMessageExternal;

			await createUserToUserMessage(
				{
					orderId: shipmentId ? parseInt(shipmentId) : 0,
					text: data.comment,
				},
				type,
			);

			reset({ comment: '' });
		},
		[
			createUserToUserMessageInternal,
			createUserToUserMessageExternal,
			reset,
			shipmentId,
			type,
		],
	);

	const internalMessages = useUserToUserMessages(
		internalConversation?.id.toString(),
	);

	const externalMessages = useUserToUserMessages(
		externalConversation?.id.toString(),
	);

	const messageToComment = (message: FullMessageResInterface) => {
		const transportMessage =
			message.transportMessage as MessageUserToUserPartialResInterface;
		return {
			user: transportMessage.sender,
			comment: transportMessage.text,
			message: message
		};
	};

	const comments = useMemo(() => {
		if (type === ConversationTypeEnum.HEYLOG_INTERNAL) {
			return internalMessages?.messages ? internalMessages?.messages?.map(messageToComment) : [];
		} else {
			return externalMessages?.messages ? externalMessages?.messages?.map(messageToComment) : [];
		}
	}, [externalMessages, internalMessages, type]);

	// scroll to bottom of comments if its overflowing
	const messagesEndRef = useRef<HTMLDivElement>(null);
	useWsChannelEvent(WorkspaceEventsEnum.USER_TO_USER_COMMENT, (payload: any) => {
		updateConversationsV2()
		internalMessages.updateUserToUserMessage()
		externalMessages.updateUserToUserMessage()
	})

	// const scrollToBottom = () => {
	// 	messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	// };

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		scrollToBottom();
	// 	}, 200);
	// }, [comments]);
	// scroll to bottom of comments if its overflowing

	return (
		<Card
			sx={{
				height: '100%',
				boxShadow: 'none',
			}}
		>
			<Stack gap={1} justifyContent="space-between" sx={{ height: '100%' }}>
				<Stack
					gap={2}
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					padding={1}
					sx={{ borderBottom: `1px solid ${colors.grey[300]}` }}
				>
					<StyledTitle>{t('shipment.comments')}</StyledTitle>
					{/* <Box>
						// <TabsGroupV2 tabs={tabs} onChange={setType} value={type} />
					</Box> */}
				</Stack>
				<Stack marginTop="auto" padding={1} gap={1} sx={{
					height: '150px',
					overflowY: 'scroll',
					scrollbarWidth: 'thin',
					'&::-webkit-scrollbar': {
						width: '6px',
					},
					'&::-webkit-scrollbar-thumb': {
						backgroundColor: colors.grey[400],
						borderRadius: '4px',
					},
				}}>
					{comments?.length > 0 ? comments.map((comment, index) => {
						console.log('comment', comment)
						return (
							<Stack key={index}
								direction="row"
								alignItems="center" gap={1} sx={{ wordBreak: 'break-all', fontSize: '14px' }} >
								<StyledAvatar sx={{ width: 32, height: 32 }} src={comment?.message?.profilePicturePath || ""}>
									{!comment?.message?.profilePicturePath && getNameInitials(comment?.user?.firstName, comment?.user?.lastName)}

								</StyledAvatar>
								<Stack>
									<Stack direction={"column"}>
										<Stack direction="row" gap={2}>
											<span style={{ fontWeight: 'bold' }}>
												{comment.user.firstName} {comment.user.lastName}
											</span>
											<span style={{ color: 'gray', fontSize: '12px' }}>
												{new Date().toLocaleDateString()}
											</span>
										</Stack>
										<Stack>
											<span>{comment.comment}</span>
										</Stack>
									</Stack>
								</Stack>
							</Stack>

						);
					}) :
						<StyledDivNoConver>
							<IconNoConver />
							<TypoNoConversation>
								No comments yet
							</TypoNoConversation>
						</StyledDivNoConver>}
					<div ref={messagesEndRef} />
				</Stack>
				<Box borderTop={`1px solid ${colors.grey[300]}`}>
					<form onSubmit={handleSubmit(onSubmitCommentForm)} noValidate>
						<Controller
							control={control}
							name="comment"
							render={({ field }) => (
								<InputV2
									id="standard-basic"
									variant="standard"
									placeholder={t('shipment.yourCommentHere2')}
									fullWidth
									endAdornment={
										<InputAdornment position="end" >
											<IconSend
												color={field.value && field.value.trim() !== "" ? "#122CC5" : "#E1E4EA"}
												style={{ cursor: "pointer" }}
												onClick={() => handleSubmit(onSubmitCommentForm)()}
											/>
										</InputAdornment>
									}
									{...register('comment')}
									{...field}

								/>
							)}
						/>
					</form>
				</Box>
			</Stack>
		</Card >
	);
};
