import type { FC } from 'react';

export const SvgNO: FC = () => (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2301_3171)">
            <path d="M0.595001 0.657227H16.405V12.3429H0.595001V0.657227Z" fill="#ED2939" />
            <path d="M5.04156 0.657227H8.00594V12.3429H5.04156V0.657227Z" fill="white" />
            <path d="M0.595001 5.03906H16.405V7.96048H0.595001V5.03906Z" fill="white" />
            <path d="M5.78265 0.657227H7.26484V12.3429H5.78265V0.657227Z" fill="#002664" />
            <path d="M0.595001 5.76953H16.405V7.23024H0.595001V5.76953Z" fill="#002664" />
        </g>
        <defs>
            <clipPath id="clip0_2301_3171">
                <rect x="0.595001" y="0.657227" width="15.81" height="11.6857" rx="1.37478" fill="white" />
            </clipPath>
        </defs>
    </svg>


);
