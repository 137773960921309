import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { deleteSiteMap, useApiClientContext, useSiteDocumentsData, useTableColumnsSettings } from "@heylog-app/frontend-lib/app";
import { SiteMapDataGrid } from "./site-map-data-grid";
import { useParams } from "react-router-dom";

// Styled components for the document listing section
const DocumentListContainer = styled("div")(({ theme }) => ({
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "8px",
    backgroundColor: "#fff",
}));

// Mock data for demonstration

export const SiteMapListing = () => {
    const { t } = useTranslation();
    const navParams = useParams();
    const { workspaceId } = navParams;
    const apiRef = useGridApiRef();
    const [paginationModel, setPaginationModel] = useState<any>({
        pageNumber: 1,
        pageSize: 10,
    })
    const { apiClient } = useApiClientContext();

    const handleDeleterow = (siteDetails: any) => {
        deleteSiteMap(apiClient, workspaceId, siteDetails.language)
            .then(() => {
                refetchSiteDocument();
            })
            .catch((error) => {
                console.error("Failed to delete site map:", error);
            });
    }

    const { columns, rows, isLoading, refetchSiteDocument, totalRowsContact } = useSiteDocumentsData(handleDeleterow, paginationModel);
    const onChangePage = (props: any) => {
        setPaginationModel({ pageNumber: props.page + 1, pageSize: props.pageSize })
    }
    useEffect(() => {
        refetchSiteDocument()
    }, [paginationModel.pageNumber, paginationModel.pageSize])
    const {
        adjustedColumns,
        handleColumnResize,
        handleColumnOrderChange,
        handleColumnVisibilityModelChange,
    } = useTableColumnsSettings({
        columns,
        apiRef,
        localStorageName: 'safety-data-table',
    });

    const handleRowSelectionChange = (records: string[]) => {
        // setSelectedContacts(records);
    }
    console.log('rows', rows, adjustedColumns)

    return (


        <DocumentListContainer>
            <Typography
                variant="h6"
                sx={{ padding: "16px", fontWeight: 600, fontSize: "18px" }}
            >
                {t("Documents.tableTitle")}
            </Typography>

            <SiteMapDataGrid
                onPageChange={onChangePage}
                isLoading={isLoading}
                rows={rows}
                pagination={true}
                rowCount={totalRowsContact}
                columns={adjustedColumns}
                handleRowSelectionChange={handleRowSelectionChange}
                checkboxSelection={true}
                apiRef={apiRef}
                handleColumnOrderChange={handleColumnOrderChange}
                handleColumnResize={handleColumnResize}
                handleColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                withPaginationSettings={true}
            />
            {/* 
            <PaginationContainer>
                <Stack direction="row" spacing={2} alignItems="center">
                    <PaginationText>{t("Per page:")}</PaginationText>
                    <Typography variant="body2" fontWeight={600}>5</Typography>
                </Stack>

                <PaginationControls>
                    <PaginationText>1 - 05 of 11</PaginationText>
                    <IconButton size="small" disabled>
                        <ChevronLeftIcon fontSize="small" />
                    </IconButton>
                    <IconButton size="small">
                        <ChevronRightIcon fontSize="small" />
                    </IconButton>
                </PaginationControls>
            </PaginationContainer> */}
        </DocumentListContainer>
    );
};
