import React, { useCallback, useEffect, useState } from 'react';
import {
  StyledTextWrapperPage2,
  BorderLinearProgress,
  StyledStatusMessage,
  StyledTextWrapper,
  StyledButton,
} from '../registrationform.styles';
import {
  mdiInformation,
  mdiCloseCircle,
  mdiCheckCircle,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { getFetcher, RegisterPageLayout, useApiClientContext } from '@heylog-app/frontend-lib/app';
import { DownloadText, LinkWrapper, ProgressBox, SiteMapLink } from './status.styles';
import { GoogleMapWithMarkers, MapStops, RegisterHeader } from '../components';
import { useTranslation } from 'react-i18next';
import { useMultiStep } from '../MultiStepProvider';
import useSWR from 'swr';
import { FileDownloadResInterface } from '@heylog-app/shared/types';

const statusDetails: Record<
  'waiting' | 'proceed' | 'error',
  { message: string; style: string; icon: string; value: number }
> = {
  waiting: {
    message: 'registration.waitInParkingLot',
    style: '#1588C7',
    icon: mdiInformation,
    value: 76,
  },
  proceed: {
    message: 'registration.proceedToGate',
    style: '#198A1F',
    icon: mdiCheckCircle,
    value: 100,
  },
  error: {
    message: 'registration.goToGatehouse',
    style: '#DF2626',
    icon: mdiCloseCircle,
    value: 67,
  },
};
const useSiteById = (language: string) => {
  const { apiClient } = useApiClientContext();
  const { workspaceId } = useParams();
  const { data, error, isValidating, isLoading, mutate } =
    useSWR<FileDownloadResInterface>(
      `/workspaces/${workspaceId}/single-site-map${language ? `?language=${language}` : ""}`,
      getFetcher(apiClient),
      {
        revalidateOnFocus: false, // Disable revalidation on focus
      }
    );

  const refetchSafetyDocument = useCallback(() => mutate(), [mutate]);
  return {
    dataCheck: data,
    isLoading,
    refetchSafetyDocument,
    isError: error,
    isValidating,
  };
};
export const RegistrationStatus = () => {
  // const location = useLocation();
  // const { state } = location;
  const [status, setStatus] = useState<"waiting" | "proceed" | "error">('waiting');
  const { registerState, transportDetails, tasksState } = useMultiStep();
  const { dataCheck } = useSiteById(transportDetails?.language)
  useEffect(() => {
    if (registerState) {
      setStatus(registerState as keyof typeof statusDetails);

    }
  }, [registerState]);

  const { t } = useTranslation();

  const currentStatus = statusDetails[status];

  return (
    <div style={{ justifyItems: 'center' }}>
      <StyledTextWrapper>
        <StyledTextWrapperPage2>
          <RegisterHeader transportDetails={transportDetails} />
        </StyledTextWrapperPage2>
        {currentStatus && (
          <ProgressBox>
            <StyledStatusMessage status={status}>
              <span>{t(currentStatus.message)}</span>
            </StyledStatusMessage>
            <Box display="flex" alignItems="center" gap={1} justifyContent="center" marginTop='12px'>
              <BorderLinearProgress variant="determinate" value={currentStatus.value} status={status} />
              <Icon path={currentStatus.icon} size={0.95} color={currentStatus.style} />
            </Box>
          </ProgressBox>
        )}
        <LinkWrapper>
          <SiteMapLink onClick={() => {
            window.open(dataCheck?.siteMapUrl)
          }}>Site Map </SiteMapLink> <DownloadText>{t(`registration.download`)}</DownloadText>
        </LinkWrapper>
      </StyledTextWrapper>
      {status === 'proceed' ? <MapStops tasksState={tasksState} /> : <GoogleMapWithMarkers />}
      {status === 'proceed' &&
        <StyledButton
        >
          {t(`registration.deliveryDone`)}
        </StyledButton>}
    </div>
  );
};
