import { StyledListItem, StyledListItemButton, StyledListItemButtonV2, StyledListItemV2 } from './sidebar-list-item.styles';

import type { FC } from 'react';
import type { ListItemButtonProps, ListItemProps } from '@mui/material';

type SidebarListItem = Omit<ListItemProps, 'onClick'> &
	Pick<ListItemButtonProps, 'onClick'> & { active?: boolean };

export const SidebarListItem: FC<SidebarListItem> = ({
	children,
	active,
	onClick,
	...props
}) => {
	return (
		<StyledListItem {...props} $active={active}>
			<StyledListItemButton onClick={onClick} $active={active}>
				{children}
			</StyledListItemButton>
		</StyledListItem>
	);
};

export const SidebarListItemV2: FC<SidebarListItem> = ({
	children,
	active,
	onClick,
	...props
}) => {
	return (
		<StyledListItemV2 {...props} $active={active}>
			<StyledListItemButtonV2 onClick={onClick} $active={active}>
				{children}
			</StyledListItemButtonV2>
		</StyledListItemV2>
	);
};
