/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  StyledBoxDiv,
  StyledBoxDivInside,
  StyledButtonBoxInside,
  StyledHeadLabel,
  StyledHeadTitle,
  StyledInnerHead,
  StyledLanguageBox,
  StyledLanguageBoxText,
  StyledLeftBox,
  StyledMain,
  StyledMainInner,
  StyledMainInnerHead,
  StyledRightBox,
  StyledTextBox,
  StyledTextFooter,
} from "./safety-page.styles";
import { MenuItem, Select, Stack } from "@mui/material";

import {
  FlashSnackbar,
  IconSendFile,
  useMessageTranslation,
  useSafetyById,
  useSnackbar,
  useUser,
  useWorkspace,
} from "@heylog-app/frontend-lib/app";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TranslationServiceType } from "@heylog-app/shared/types";
import { convertToISO639LikeCountryCodes } from "@heylog-app/shared/util";
import { SafetyListing } from "./Components/safety-listing";
type DPAPageProps = {
  filee?: File | undefined;
  setFile?: React.Dispatch<React.SetStateAction<File | undefined>>;
  safetyLang?: string;
  setSafetyLang?: React.Dispatch<React.SetStateAction<string>>;
};
export const SafetyPage = ({
  filee,
  setFile,
  safetyLang,
  setSafetyLang,
}: DPAPageProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { workspaceId } = useParams();
  const { user } = useUser();
  const { t } = useTranslation();
  const [isFile, setIsFile] = useState<string | null>(null);
  // const [value, setValue] = useState("en")
  const { workspace } = useWorkspace(workspaceId || "", user);

  // useEffect(() => {
  //   if (dataCheck) {
  //     setIsFile(dataCheck?.safetyInstructionUrl || null);
  //     setSafetyLang && setSafetyLang(dataCheck.language);
  //   }
  // }, [dataCheck]);
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent default browser behavior
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault(); // Prevent default browser behavior
    const files = event.dataTransfer.files as any;
    if (files && files.length > 0) {
      setFile && setFile(files?.[0]); // Use the first file for simplicity
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files as any;
    if (files && files.length > 0) {
      setFile && setFile(files?.[0]); // Use the first file for simplicity
    }
  };
  const { supportedLang } = useMessageTranslation({
    translationService: workspace?.translationService as TranslationServiceType,
  });

  const sortedLanguages = useMemo(() => {
    const supportedLanguagesMap = supportedLang?.sourceLanguages?.map(
      (languageCode: any) => {
        return { code: languageCode, name: t(`languages.${languageCode}`) };
      }
    );
    supportedLanguagesMap?.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    return supportedLanguagesMap;
  }, [supportedLang, t]);
  const onChange = (event: any) => {
    setSafetyLang && setSafetyLang(event.target.value);
  };
  return (
    <StyledMain>
      <StyledMainInnerHead>

        <StyledInnerHead>
          <Stack marginBottom={"10px"}>
            <Stack gap={"10px"}>
              <StyledHeadTitle>{t("settings.safety-page.title")}</StyledHeadTitle>
              <StyledLanguageBox>
                <StyledLanguageBoxText>
                  {t("settings.safety-page.langDocu")}
                </StyledLanguageBoxText>
                <Select
                  onChange={onChange}
                  value={safetyLang}
                  variant={"outlined"}
                  style={{ width: "150px", height: "20px" }}
                >
                  {sortedLanguages?.map((lang: any) => (
                    <MenuItem
                      data-test={"language-list-item"}
                      data-value={convertToISO639LikeCountryCodes(lang.code)}
                      key={lang.name}
                      value={lang.code}
                    >
                      {t(`languages.${lang.code}`)}
                    </MenuItem>
                  ))}
                </Select>
              </StyledLanguageBox>
              <StyledHeadLabel>
                {t("settings.safety-page.subHeading")}
              </StyledHeadLabel>
            </Stack>
          </Stack>
          <StyledMainInner>
            <StyledLeftBox>
              <Stack gap={"15px"}>
                <StyledBoxDiv onDragOver={handleDragOver} onDrop={handleDrop}>
                  <StyledBoxDivInside>
                    {/* <Icon path={mdiFileDocumentPlusOutline} size={2} /> */}
                    <IconSendFile />
                    <StyledTextBox>
                      {t("settings.safety-page.dropFile")}
                    </StyledTextBox>
                    <input
                      type="file"
                      placeholder="input"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (!file) return;

                        const allowedExtensions = ["pdf", "doc", "docx"];
                        const fileExtension = file.name
                          .split(".")
                          .pop()
                          ?.toLowerCase();
                        const maxFileSize = 50 * 1024 * 1024; // 50MB in bytes

                        // Validate extension
                        if (
                          !fileExtension ||
                          !allowedExtensions.includes(fileExtension)
                        ) {
                          openSnackbar(
                            "warning",
                            t("settings.safety-page.errorType")
                          );
                          e.target.value = ""; // reset input
                          return;
                        }

                        // Validate size
                        if (file.size > maxFileSize) {
                          openSnackbar(
                            "warning",
                            t("settings.safety-page.errorSize")
                          );
                          e.target.value = ""; // reset input
                          return;
                        }

                        handleFileChange(e);
                      }}
                    />
                    <StyledButtonBoxInside onClick={handleButtonClick}>
                      {t("settings.safety-page.uploadFile")}
                    </StyledButtonBoxInside>
                    {filee && (
                      <StyledTextFooter>
                        {t("settings.safety-page.selectedFile")}{" "}
                        <strong>{filee?.name}</strong>
                      </StyledTextFooter>
                    )}

                    <StyledTextFooter>
                      {t("settings.safety-page.supportType")}
                    </StyledTextFooter>
                  </StyledBoxDivInside>
                </StyledBoxDiv>
              </Stack>
            </StyledLeftBox>

            <FlashSnackbar
              controls={[stateSnackbar, openSnackbar, closeSnackbar]}
            />
          </StyledMainInner>
        </StyledInnerHead>
        <StyledRightBox>
          <SafetyListing />
        </StyledRightBox>
      </StyledMainInnerHead>

    </StyledMain>
  );
};
