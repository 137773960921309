import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Popper, ClickAwayListener, Button, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EmojiPicker from 'emoji-picker-react';

import {
	MessageTypeEnum,
} from '@heylog-app/shared/types';
import { getMediaType } from '@heylog-app/shared/util';

import {
	StyledMessageFlexWrapper,
	StyledMessageInput,
	StyledMessageInputWrapper,
	StyledInputBottom,
	StyledFileWrapper,
	StyledTextareas,
	StyledTextareaWrapper,
	StyledSubmitBlock,
	StyledSubmitButton,
	StyledSubmitButtonV2,
} from './message-input.styles';
import { MessageTranslationDialog } from '../message-translation-modal/message-translation-dialog';
import {
	useConversation,
	useConversationLanguagev2,
	useDebounce,
	useDialog,
	useMessageTranslation,
	useMutateMessages,
	useSnackbar,
	useTemplates,
	useUploadFiles,
	useUser,
	useWorkspace,
} from '../../../hooks';
import { addEmojiToInput } from '../../../util/add-emoji';
import { UploadedFiles } from '../uploaded-files/uploaded-files';
import { DragOverlay } from '../drag-overlay';
import { MessageTextarea } from './message-textarea';
import { TranslatedMessageTextarea } from './translated-message-textarea';
import { FlashSnackbar } from '../../snackbars';
import { parseAxiosErrorMessage, ROUTES } from '../../../util';
import SendIcon from '@mui/icons-material/Send';

import type { SelectChangeEvent } from '@mui/material';
import type {
	TranslationServiceType,
	SupportedLanguagesType,
	WATplParamTextInterface,
} from '@heylog-app/shared/types';
import type { FC, SyntheticEvent, KeyboardEventHandler, Dispatch, SetStateAction } from 'react';
import type { EmojiClickData } from 'emoji-picker-react';
import { MessageInputToolbarV2 } from '../message-input-toolbar';
import { ButtonV2 } from '../buttonV2/buttonV2';
import { SignatureShield } from './signature-shield';

interface MessageInputPropsInteface {
	contactId?: string;
	conversationId?: string;
	shouldFocusOnRender?: boolean;
	message: string;
	setMessage: Dispatch<SetStateAction<string>>;
	showAttachment?: boolean;
	showTranslate?: boolean;
	onSubmit?: () => void;
}

export const MessageInputV2: FC<MessageInputPropsInteface> = ({
	message,
	setMessage,
	contactId: contactIdProp,
	conversationId: conversationIdProp,
	shouldFocusOnRender,
	showTranslate = true,
	showAttachment = true,
	onSubmit,
}) => {
	const { workspaceId = '', ...params } = useParams();
	const conversationId = conversationIdProp || params['conversationId'] || '';
	const contactId = contactIdProp || params['contactId'] || '';
	const { user } = useUser();
	const { workspace } = useWorkspace(workspaceId, user);
	const { conversation } = useConversation(workspaceId, conversationId);//here
	const { sendMessage } = useMutateMessages(workspaceId, conversationId);

	const [translatedMessage, setTranslatedMessage] = useState('');
	const [isTranslationActive, setIsTranslationActive] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [emojiPickerField, setEmojiPickerField] = useState<
		(EventTarget & (HTMLTextAreaElement | HTMLInputElement)) | null
	>(null);
	const messageInputToolbarRef = useRef<HTMLDivElement>(null);

	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const [translatedTemplateParameters, setTranslatedTemplateParameters] =
		useState<WATplParamTextInterface[]>();

	const [focusedFieldIndex, setFocusedFieldIndex] = useState(0);
	const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
	const [templateCursorPosition, setTemplateCursorPosition] = useState<
		number | undefined
	>(undefined);

	const translationDialogControl = useDialog();

	const { templates } = useTemplates();
	const templatesDialogControl = useDialog();

	const debouncedMessage = useDebounce<string>(message, 500);

	useEffect(() => {
		setIsTranslationActive(conversation?.isTranslationActive || false);
		setMessage('');
	}, [conversation]);

	const {
		getMessageTranslation,
		loading: isTranslationLoading,
		supportedLang,
		latestTranslatedText,
	} = useMessageTranslation({
		translationService: workspace?.translationService as TranslationServiceType,
	});

	const {
		contactLanguage,
		conversationLanguage,
		handleContactLanguageChange,
		handleConversationLanguageChange,
	} = useConversationLanguagev2({
		isTranslationActive,
		setIsTranslationActive,
		conversationId,
	});

	useEffect(() => {
		if (
			supportedLang &&
			contactLanguage &&
			!supportedLang.targetLanguages.includes(contactLanguage as SupportedLanguagesType)
		) {
			handleContactLanguageChange({
				target: {
					value: 'en',
					name: 'languageSelector',
				},
			} as SelectChangeEvent<string>);
		}
	}, [contactLanguage, handleContactLanguageChange, supportedLang]);

	const {
		removeFile,
		files,
		getInputProps,
		getRootProps,
		openFileDialog,
		isDragAccept,
		removeAllFiles,
		fileError,
		setFileError,
	} = useUploadFiles();

	const isSubmitEnabled =
		!isSending &&
		(message.length > 0 || files.length > 0) &&
		(!isTranslationActive ||
			(!isTranslationLoading &&
				((!!message && message === latestTranslatedText) ||
					(message.length === 0 && translatedMessage.length === 0))));

	const isSignatureOn = conversation?.showUserSignature || false;

	const { t } = useTranslation();

	const templateErrorMessage = t('chats.errors.noTranslatedTemplateAvailable');

	const translationTextBoxPlaceholderLabel = useMemo(
		() =>
			isTranslationLoading
				? t('chats.loadingTranslation')
				: t('chats.translationPlaceholder'),
		[isTranslationLoading, t],
	);

	const toggleEmojiPicker = useCallback(() => {
		setIsEmojiPickerVisible(!isEmojiPickerVisible);
	}, [isEmojiPickerVisible, setIsEmojiPickerVisible]);

	useEffect(() => {
		if (isTranslationLoading) setTranslatedMessage('');
		if (message.length === 0) setTranslatedMessage('');
	}, [isTranslationLoading, message]);

	useEffect(() => {
		if (isTranslationActive) {
			if (debouncedMessage) {
				getMessageTranslation(
					debouncedMessage,
					conversationLanguage,
					contactLanguage,
					workspace?.translationService,
				).then((result) => {
					setTranslatedMessage(result);
				});
			}
		} else {
			// setTranslatedTemplate(undefined);
			setTranslatedMessage('');
		}
	}, [
		debouncedMessage,
		isTranslationActive,
		conversationLanguage,
		contactLanguage,
		getMessageTranslation,
		templates,
		openSnackbar,
		templateErrorMessage,
		workspace?.translationService,
	]);


	const sendMediaMessage = useCallback(async () => {
		if (!conversation?.transportType) return;

		//handle sending media for now
		for (let i = 0; i <= files.length; i++) {
			const file = files[i];

			if (file) {
				try {
					const mediaType = getMediaType(file.type);

					//TODO handle sent reaction somewhere
					if (mediaType === MessageTypeEnum.REACTION) {
						return;
					}

					if (
						mediaType === MessageTypeEnum.IMAGE ||
						mediaType === MessageTypeEnum.VIDEO
					) {
						await sendMessage({
							messagePayload: {
								caption: message,
								messageType: mediaType,
								file: file,
							},
							transportType: conversation.transportType,
						});
					} else if (mediaType === MessageTypeEnum.AUDIO) {
						await sendMessage({
							messagePayload: { messageType: mediaType, file: file },
							transportType: conversation.transportType,
						});
					} else if (
						// if message is text or template dont handle this here
						mediaType !== MessageTypeEnum.TEXT &&
						mediaType !== MessageTypeEnum.WHATSAPP_TEMPLATE &&
						mediaType !== MessageTypeEnum.LOCATION &&
						conversation.transportType
					) {
						await sendMessage({
							messagePayload: {
								caption: message,
								messageType: mediaType,
								file: file,
							},
							transportType: conversation.transportType,
						});
					}
				} catch (error) {
					openSnackbar('error', parseAxiosErrorMessage(error));
					setFileError(true);
					setIsSending(false);
					return;
				}
			}
		}
	}, [files, sendMessage, conversation, message, openSnackbar, setFileError]);
	const navigate = useNavigate()

	const sendTextMessage = useCallback(async () => {
		if (message && message.trim().length && conversation?.transportType) {
			await sendMessage({
				messagePayload: {
					messageType: MessageTypeEnum.TEXT,
					text: message,
					translatedText: translatedMessage,
				},
				transportType: conversation?.transportType,
			}).catch((error) => {
				console.log('error sending a text message: ', error);
				setIsSending(false);
			});
		}
	}, [conversation, sendMessage, message, translatedMessage]);

	const handleSubmit = useCallback(async () => {
		if (isSending) return;

		if (conversation?.transportType) {
			setIsSending(true);

			// text message won't be sent if it can be sent as caption of media message
			const messageContainsImageOrVideo = files.some((file) => {
				const mimeType = getMediaType(file.type);
				return mimeType === MessageTypeEnum.IMAGE || mimeType === MessageTypeEnum.VIDEO;
			});


			// send media message
			if (files.length) {
				await sendMediaMessage();
			}

			// send text message
			if (message && !messageContainsImageOrVideo) {
				await sendTextMessage();
			}

			setMessage('');
			setTranslatedMessage('');
			removeAllFiles();
			setIsSending(false);
		}
	}, [
		conversation?.transportType,
		files,
		message,
		isSending,
		removeAllFiles,
		sendMediaMessage,
		sendTextMessage,
	]);

	const onChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
		setMessage(event?.currentTarget?.value);
	};

	const onPressEnter: KeyboardEventHandler = (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();

			if (isSubmitEnabled) {
				handleSubmitClick();
			}
		}
	};

	const handleSubmitClick = () => {
		if (isSubmitEnabled) {
			handleSubmit();
		}
	};

	const sendEmojiToInput = async (emojiObject: EmojiClickData) => {
		if (emojiPickerField) {
			const message = emojiPickerField.value;
			const cursor = emojiPickerField?.selectionStart || 0;
			const { messageWithEmoji, processedEmojiLength } = addEmojiToInput(
				emojiObject,
				message,
				cursor,
			);

			const newCursor = cursor + processedEmojiLength + 1;
			setMessage(messageWithEmoji);
			setTemplateCursorPosition(cursor + processedEmojiLength + 1);
		}
		setIsEmojiPickerVisible(false);
	};

	useEffect(() => {
		if (templateCursorPosition && emojiPickerField) {
			emojiPickerField.setSelectionRange(templateCursorPosition, templateCursorPosition);
		}
	}, [templateCursorPosition, emojiPickerField]);

	const clickAwayEmojiPickerHandler = () => {
		setIsEmojiPickerVisible(false);
	};

	return (
		<>
			<StyledMessageInputWrapper
				data-test="message-input-component"
				ref={messageInputToolbarRef}
			>
				<StyledMessageInput {...getRootProps()}>
					{isEmojiPickerVisible && (
						<ClickAwayListener onClickAway={clickAwayEmojiPickerHandler}>
							<Popper
								data-test="emoji-picker-popper"
								open={isEmojiPickerVisible}
								anchorEl={messageInputToolbarRef?.current}
								style={{ zIndex: 100 }}
								placement={window.innerWidth <= 1600 ? 'top-start' : 'bottom-start'}
								modifiers={[
									{
										name: 'offset',
										options: {
											offset: [15, -10],
										},
									},
									{
										name: 'preventOverflow',
										options: {
											boundary: 'viewport',
										},
									},
								]}
							>
								<EmojiPicker
									onEmojiClick={sendEmojiToInput}
									autoFocusSearch={false}
									previewConfig={{ showPreview: false }}
								/>
							</Popper>
						</ClickAwayListener>
					)}

					{isDragAccept && <DragOverlay />}
					<MessageInputToolbarV2
						getInputProps={getInputProps}
						openFileDialog={openFileDialog}
						setIsTranslationActive={setIsTranslationActive}
						openTranslationDialog={translationDialogControl.openDialog}
						isTranslationActive={isTranslationActive}
						conversationId={conversationId}
						openWhatsAppTemplatesDialog={templatesDialogControl.openDialog}
						toggleEmojiPicker={toggleEmojiPicker}
						showAttachment={showAttachment}
						showTranslate={showTranslate}
					/>
					<StyledMessageFlexWrapper
						$isSignatureOn={isSignatureOn}
						$hasMedia={files?.length > 0}
					>
						<StyledFileWrapper>
							<StyledTextareas>
								{/* isTranslationActive ? (
									<TranslatedMessageTextarea
										message={message}
										translatedMessage={translatedMessage}
										conversationLanguage={conversationLanguage}
										contactLanguage={contactLanguage}
										isSending={isSending}
										translatedTemplateParameters={translatedTemplateParameters}
										translationTextBoxPlaceholderLabel={
											translationTextBoxPlaceholderLabel
										}
										onChange={onChange}
										onPressEnter={onPressEnter}
										handleConversationLanguageChange={handleConversationLanguageChange}
										handleContactLanguageChange={handleContactLanguageChange}
										supportedLang={supportedLang}
										setEmojiPickerField={setEmojiPickerField}
										focusedFieldIndex={focusedFieldIndex}
										setFocusedFieldIndex={setFocusedFieldIndex}
										templateCursorPosition={templateCursorPosition}
									/>
								) : (
									)}*/}
								<StyledTextareaWrapper>
									<MessageTextarea
										message={message}
										isSending={isSending}
										onChange={onChange}
										onPressEnter={onPressEnter}
										setEmojiPickerField={setEmojiPickerField}
										focusedFieldIndex={focusedFieldIndex}
										setFocusedFieldIndex={setFocusedFieldIndex}
										templateCursorPosition={templateCursorPosition}
										shouldFocusOnRender={shouldFocusOnRender}
									/>
								</StyledTextareaWrapper>
							</StyledTextareas>
						</StyledFileWrapper>
						<StyledInputBottom
							$isSignatureOn={isSignatureOn}
							$hasMedia={files?.length > 0}
						>
							<Button onClick={() => { setMessage(''); }} >
								{t('actionLabels.clearMsg')}
							</Button>
							{files && (
								<UploadedFiles
									fileError={fileError}
									removeFile={removeFile}
									files={files}
									showMargin={isSignatureOn || files?.length > 0}
								/>
							)}
						</StyledInputBottom>


					</StyledMessageFlexWrapper>
				</StyledMessageInput>
			</StyledMessageInputWrapper>
			
			<MessageTranslationDialog
				contactLanguage={contactLanguage}
				setIsTranslationActive={setIsTranslationActive}
				conversationLanguage={conversationLanguage}
				handleContactLanguage={handleContactLanguageChange}
				handleConversationLanguage={handleConversationLanguageChange}
				conversationId={conversationId}
				control={translationDialogControl}
				translationService={workspace?.translationService || 'DEEPL'}
			/>

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</>
	);
};
