import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { Stack } from '@mui/material';

import { getRoleIcon } from '@heylog-app/shared/util';

import { StyledRoleIcon } from './contact-information.styles';
import { useContact } from '../../hooks';
import { ContactInformationHeader } from '../contact-information-header';
import { formatPhoneNumberHide } from '../../util';
import { ContactDetailItem } from '../contact-detail-item/contact-detail-item';
import { colors } from '../../styles';

import type { FC } from 'react';

interface ContactInformationProps {
	contactId?: number;
	onCloseDialog?: () => void;
}

export const ContactInformation: FC<ContactInformationProps> = ({
	contactId: contactIdProp,
	onCloseDialog,
}) => {
	const params = useParams();
	const contactId = contactIdProp || params['contactId'] || '';

	const { contact } = useContact(contactId.toString());
	const { t } = useTranslation();
	const roleIcon = getRoleIcon(contact ? contact.role : 'DRIVER');

	return (
		<Box data-test="contact-information" padding={5} sx={{ overflowY: 'auto' }}>
			<ContactInformationHeader
				onCloseDialog={onCloseDialog}
				contactId={contactId.toString()}
			/>

			<div>
				<Stack
					sx={{
						fontSize: '16px',
						marginBottom: '20px',
					}}
				>
					{t('contacts.blocks.company')}
				</Stack>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					{/*<ContactDetailItem label="contacts.form.labels.firstName">*/}
					{/*	<span data-test="contact-detail-first-name">{contact?.firstName}</span>*/}
					{/*</ContactDetailItem>*/}

					{/*<ContactDetailItem label="contacts.form.labels.lastName">*/}
					{/*	<span data-test="contact-detail-last-name">{contact?.lastName}</span>*/}
					{/*</ContactDetailItem>*/}
					<ContactDetailItem label="contacts.form.labels.company">
						<span data-test="contact-detail-company">
							{contact?.company ? contact?.company : 'N/A'}
						</span>
					</ContactDetailItem>

					<ContactDetailItem label="contacts.form.labels.licensePlate">
						<span data-test="contact-detail-licensePlate">
							{contact?.licensePlate ? contact?.licensePlate : 'N/A'}
						</span>
					</ContactDetailItem>

					<ContactDetailItem label="contacts.form.labels.role">
						{roleIcon && (
							<StyledRoleIcon path={roleIcon} size={'1em'} color={colors.common.black} />
						)}
						<span data-test="contact-detail-role">{t(`roles.${contact?.role}`)}</span>
					</ContactDetailItem>
				</Box>

				<Stack
					sx={{
						fontSize: '16px',
						marginBottom: '20px',
					}}
				>
					{t('contacts.blocks.contactInfo')}
				</Stack>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<ContactDetailItem label="contacts.form.labels.phone">
						{contact?.phone
							? (formatPhoneNumberHide(contact.phone))
							: 'N/A'}
					</ContactDetailItem>

					<ContactDetailItem label="contacts.form.labels.email">
						<span data-test="contact-detail-email">
							{contact?.email ? contact?.email : 'N/A'}
						</span>
					</ContactDetailItem>

					<Box
						sx={{
							visibility: 'hidden',
							width: '30%',
						}}
					></Box>
				</Box>

				<Stack
					sx={{
						fontSize: '16px',
						marginBottom: '20px',
					}}
				>
					{t('contacts.blocks.other')}
				</Stack>
				<ContactDetailItem label="contacts.form.labels.preferredLanguage">
					{contact?.preferredLanguage && (
						<span data-test="contact-detail-preferred-language">
							{t(`languages.${contact?.preferredLanguage}`)}
						</span>
					)}
				</ContactDetailItem>
			</div>
		</Box>
	);
};
