import React from "react";
import { UseFormReturn } from 'react-hook-form';
import {
	StyledTextWrapper,
	StyledForm,
	StyledSelect,
	StyledInput,
	StyledDiv1,
	StyledDiv2,
	StyledPlaceholder,
} from '../registrationform.styles';
import MenuItem from '@mui/material/MenuItem';
import { Controller } from 'react-hook-form';
import { languages } from '@heylog-app/shared/util';
import { useTranslation } from "react-i18next";
import { TaskFormData } from "../../Types";
import { BlueDropdown } from "@heylog-app/frontend-lib/app";

interface FirstStepProps {
	formMethods: UseFormReturn<TaskFormData>;
}

export const FirstStep: React.FC<FirstStepProps> = ({ formMethods }) => {
	const { t } = useTranslation();
	const { control, register, formState: { errors } } = formMethods;

	const sortedLanguages = languages.sort((a, b) =>
		a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }),
	);

	return (
		<StyledTextWrapper style={{ marginBottom: '4.2rem' }}>
			<StyledDiv1 style={{ marginBottom: '0.4rem' }}>
				{t('registration.welcomeText')}
			</StyledDiv1>
			<StyledDiv2>{t('registration.enterDetails')}</StyledDiv2>
			<StyledForm>
				<Controller
					name="step1.preferredLanguage"
					control={control}
					rules={{ required: "Please select a language" }}
					render={({ field }) => (
						<StyledSelect
							{...field}
							{...register("step1.preferredLanguage", { required: true })}
							displayEmpty
							disabled
							IconComponent={() => <BlueDropdown />}
							style={{ marginTop: '1rem' }}
							renderValue={(value: unknown) => {
								if (!value) {
									return <StyledPlaceholder>{t(`registration.selectYourPreferredLanguage`)}</StyledPlaceholder>;
								}
								const selectedLanguage = sortedLanguages.find(lang => lang.value === value);

								return selectedLanguage ? <>{selectedLanguage.label}</> : <>{value}</>;
							}}
							inputProps={{ 'aria-label': 'Select your preferred language' }}
							hasValue={!!field.value}
						>
							{sortedLanguages.map(({ label, value }) => (
								<MenuItem key={value} value={value}>
									{label}
								</MenuItem>
							))}
						</StyledSelect>
					)}
				/>
				<Controller
					name="step1.transportNumber"
					control={control}
					rules={{ required: "Transport number is required" }}
					render={({ field }) => (
						<StyledInput
							{...field}
							{...register("step1.transportNumber", {
								required: true,
								minLength: { value: 9, message: "Must be exactly 9 digits" },
								maxLength: { value: 9, message: "Must be exactly 9 digits" },
								validate: (value) =>
									/^\d{9}$/.test(value) || "Must be exactly 9 digits",
							})}
							placeholder={t(`registration.transportNumber`)}
							hasValue={!!field.value}
							error={!!errors?.step1?.transportNumber}
							autoComplete="off"
							inputProps={{
								maxLength: 9,
								inputMode: "numeric",
								pattern: "\\d{9}", // Ensures exactly 9 digits
							}}
						/>
					)}
				/>
			</StyledForm>
		</StyledTextWrapper>
	);
};
