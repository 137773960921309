import useSWR from "swr";

import { useApiClientContext } from "./use-api-client-context.hook";
import { getFetcher, putFetcher } from "../util";

import { getMessagesCount, getMessagesHeylogCount } from "../util/get-messages-count";
import { useWsChannelEvent } from "./use-ws-channel-event";
import { WorkspaceEventsEnum } from "@heylog-app/shared/types";
import { getReadMessagesKeyHeylog } from "../util/get-conversationv2-key";

export const useMessagesCount = (workspaceId: string) => {
  const { apiClient } = useApiClientContext();

  const { data, error, isLoading, mutate } = useSWR(
    getMessagesCount(workspaceId),
    getFetcher(apiClient)
  );
  useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_STATUS_UPDATED, () => {
    mutate().catch((e) => console.error("error updating unread", e));
  });

  useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_RECEIVED, () => {
    mutate().catch((e) => console.error("error updating unread", e));
  });

  return {
    messagesCount: data,
    isLoading,
    isError: error,
  };
};

export const useMessagesCountHeylog = (workspaceId: string) => {
  const { apiClient } = useApiClientContext();

  const { data, error, isLoading, mutate } = useSWR<any>(
    getMessagesHeylogCount(workspaceId),
    getFetcher(apiClient)
  );
  useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_USER_TO_USER, () => {
    mutate().catch((e) => console.error("error updating unread", e));
  });

  useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_USER_TO_USER_READ, () => {
    mutate().catch((e) => console.error("error updating unread", e));
  });
  return {
    messagesCount: data,
    isLoading,
    isError: error,
  };
};


export const useMessagesReadHeylog = (workspaceId?: string, conversationId?: string) => {
  const { apiClient } = useApiClientContext();

  const { data, error, isLoading, mutate } = useSWR<any>(
    getReadMessagesKeyHeylog(workspaceId, conversationId),
    putFetcher(apiClient)
  );

  useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_USER_TO_USER, () => {
    mutate().catch((e) => console.error("error updating unread", e));
  });


  return {
    refetch: mutate,
    isLoading,
  };
};