import {
	SvgCs,
	SvgDe,
	SvgHu,
	SvgLt,
	SvgLv,
	SvgPl,
	SvgRo,
	SvgRu,
	SvgSk,
	SvgSl,
	SvgUk,
	SvgBg,
	SvgTr,
	SvgEs,
	SvgPt,
	SvgFi,
	SvgIt,
	SvgUkr,
	SvgEt,
	SvgFr,
	SvgBs,
	SvgSr,
	SvgHr,
	SvgMk,
	SvgUK,
	SvgDE,
	SvgIT,
	SvgJP,
	SvgKR,
	SvgNO,
} from '../language-icons';

import type { FC } from 'react';

const MAP: Record<string, FC> = {
	de: SvgDe,
	en: SvgUk,
	pl: SvgPl,
	cs: SvgCs,
	ru: SvgRu,
	ro: SvgRo,
	hu: SvgHu,
	sk: SvgSk,
	sl: SvgSl,
	lv: SvgLv,
	lt: SvgLt,
	tr: SvgTr,
	bg: SvgBg,
	es: SvgEs,
	'pt-pt': SvgPt,
	fi: SvgFi,
	it: SvgIt,
	uk: SvgUkr,
	et: SvgEt,
	fr: SvgFr,
	bs: SvgBs,
	sr: SvgSr,
	hr: SvgHr,
	mk: SvgMk,
};

const MAPV2: Record<string, FC> = {
	de: SvgDE,
	en: SvgUK,
	pl: SvgPl,
	cs: SvgCs,
	ru: SvgRu,
	ro: SvgRo,
	hu: SvgHu,
	sk: SvgSk,
	sl: SvgSl,
	lv: SvgLv,
	lt: SvgLt,
	tr: SvgTr,
	bg: SvgBg,
	es: SvgEs,
	'pt-pt': SvgPt,
	fi: SvgFi,
	it: SvgIT,
	uk: SvgUkr,
	et: SvgEt,
	fr: SvgFr,
	bs: SvgBs,
	sr: SvgSr,
	hr: SvgHr,
	mk: SvgMk,
	jp: SvgJP,
	kr: SvgKR,
	no: SvgNO,
};

type LanguageIconProps = {
	language: string;
};

export const LanguageIcon: FC<LanguageIconProps> = ({ language }) => {
	const Icon = MAP[language];
	if (Icon) return <Icon />;
	return null;
};

export const LanguageIconV2: FC<LanguageIconProps> = ({ language }) => {
	const Icon = MAPV2[language];
	if (Icon) return <Icon />;
	return null;
};