import {
	IconSMS,
	IconWhatsApp,
	IconFacebookMessenger,
	IconViber,
	IconTelegram,
} from '../components';

import type { MessagingTransportType } from '@heylog-app/shared/types';

const icons = {
	FACEBOOK_WHATSAPP: IconWhatsApp,
	FACEBOOK_MESSENGER: IconFacebookMessenger,
	VIBER: IconViber,
	TELEGRAM: IconTelegram,
	TWILIO_SMS: IconSMS,
	HEYLOG_USER_TO_USER: IconFacebookMessenger,
};

export const getCommunicationChannelIcon = (type: MessagingTransportType) => {
	return icons[type];
};
