/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
    StyledDiv1,
    StyledDetails,
    StyledDiv3,
    StyledTextWrapperPage2,
} from "../registrationform.styles";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";

interface DetailsHeaderProps {
    note?: string;
    transportDetails?: any;
}

export const DetailsHeader: React.FC<DetailsHeaderProps> = ({
    note,
    transportDetails,
}) => {
    const { t } = useTranslation();
    const date = new Date(transportDetails?.timeSlot * 1000);
    const year = date.getFullYear().toString().slice(-2); 
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hour = date.getHours().toString().padStart(2, "0"); 
    const minute = date.getMinutes().toString().padStart(2, "0"); 

    return (
        <StyledTextWrapperPage2>
            <StyledDiv1>{t("registration.thankyou")}</StyledDiv1>
            <StyledDetails>
                <Typography className="header">
                    {t("registration.yourDetails")}
                </Typography>

                <Stack sx={{ margin: "0.7rem 0rem" }}>
                    <div className="detail-row">
                        <Typography component="span">
                            {t("registration.transportNumber")}
                        </Typography>
                        <Typography component="p">
                            : &nbsp;{transportDetails?.transportNumber}
                        </Typography>
                    </div>

                    <div className="detail-row">
                        <Typography component="span">
                            {t("registration.timeSlot")}
                        </Typography>
                        <Typography component="p">&nbsp;{`${year}-${month}-${day} ${hour}:${minute}`}</Typography>
                    </div>
                </Stack>

                <div className="detail-row">
                    <Typography component="span">{t("registration.Status")}</Typography>
                    <Typography component="p" color="error">
                        &nbsp;{transportDetails?.status}
                    </Typography>
                </div>
            </StyledDetails>
            <StyledDiv3>{note && t(note)}</StyledDiv3>
        </StyledTextWrapperPage2>
    );
};
