import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

import type { GridColDef } from '@mui/x-data-grid-pro';
import { DeleteOutlined } from '@mui/icons-material';
import { useSafetyById } from './use-workspace.hook';


export interface SafetyDetailsRow {
    id?: number;
    name?: string;
    language?: string;
    pathURL?: string;
    fileName?: string;
}
type PaginationModel = {
    pageNumber?: number;
    pageSize?: number;
};

export const useSafetyDocumentsData = (handleDeleterow?: (contact: SafetyDetailsRow) => void, paginationModel?: PaginationModel) => {
    const { t } = useTranslation();
    const columns: GridColDef[] = [
        {
            field: 'name',
            minWidth: 200,
            headerName: t('Documents.header.name'),
            headerClassName: 'bold-header',
            sortable: true,


        },
        {
            field: 'language',
            minWidth: 230,
            headerClassName: 'bold-header',
            sortable: true,

            headerName: t(`Documents.header.langauge`),
        },


        {
            field: 'actions',
            headerName: t(`actionLabels.actions`),
            headerClassName: 'bold-header',
            minWidth: 100,
            renderCell: (params) => (
                <IconButton
                    onClick={() => handleDeleterow?.(params.row)}
                    aria-label="delete"
                >
                    <DeleteOutlined style={{ color: '#5068C2' }} />
                </IconButton>
            ),
        },
    ];

    // const {
    //     safetyDcouments,
    //     isLoading,
    //     updateSafetyDocument,
    //     pagination,
    // } = useSafetyDocuments({ page: paginationModel?.pageNumber, rowsPerPage: paginationModel?.pageSize });
    const {
        dataCheck: safetyDcouments,
        refetchSafetyDocument,
        isLoading,
        pagination,
    } = useSafetyById({ page: paginationModel?.pageNumber, rowsPerPage: paginationModel?.pageSize })

    const rows: SafetyDetailsRow[] = safetyDcouments?.map((contact, index) => ({
        id: index,
        name: contact?.fileName,
        language: contact?.language || '',
        pathURL: contact?.safetyInstructionUrl || '',

    })) || [];

    return {
        columns, rows, isLoading, refetchSafetyDocument
        , totalRowsContact: pagination?.totalRows
    };
};


