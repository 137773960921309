import './profile.css';

const Upload = ({ width, height }: { width: number, height: number }) => {
    return (
        <div>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="camera" className="svg-inline--fa fa-camera fa-2x upload-btn "
                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" color="white" width={width} height={height}>
                <path fill="currentColor" d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 384c-53 0-96-43-96-96s43-96 96-96s96 43 96 96s-43 96-96 96z">
                </path>
            </svg>
        </div>
    );
}

export default Upload;
