/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import { useEnvContext } from "@heylog-app/frontend-lib/app";
import {
  Container,
  MapContainer,
  Stop,
  StopContainer,
  Title,
} from "./map.styles";
import { StyledButton } from "../registrationform.styles";
import { useTranslation } from "react-i18next";


export const MapStopsV2 = ({ tasksState, getFinalDeliveryDone, loading }: any) => {
  const env = useEnvContext();
  const [stops, setStops] = useState([])
  const { t } = useTranslation();
  useEffect(() => {
    if (tasksState) {
      setStops(tasksState?.tasks?.map((item: any, index: number) => {
        return {
          id: item?.task_id,
          title: `Stopp ${index + 1}`,
          gate: item?.gate_name,
          hall: "Halle 05",
          service: "Lackiererei",
          lat: item?.latitude,
          lng: item?.longitude,
        }
      }).filter((item2: any) => item2.lat !== null && item2.lng !== null))
    }
  }, [tasksState])
  return (
    <APIProvider apiKey={env.GPLACES_KEY}>
      <Container>
        {stops && stops.map((stop: any, index: number) => (
          <StopContainer key={index}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Title>{stop.title}:&nbsp;</Title>
              <Stop>
                {" "}
                {stop.gate} - {stop.hall}
              </Stop>
            </div>
            <Stop>{stop.service}</Stop>
            <MapContainer>
              <Map
                controlSize={20}
                center={{ lat: stop.lat, lng: stop.lng }}
                zoom={18}
                mapId={"739af084373f96fe"}
                gestureHandling="greedy"
                zoomControl={true}
                streetViewControl={true}
                fullscreenControl={false}
                mapTypeControl={true}
                disableDefaultUI={true}
              >
                <Marker position={{ lat: stop.lat, lng: stop.lng }} />
              </Map>
            </MapContainer>
          </StopContainer>
        ))}
        <StyledButton
          onClick={() => {
            getFinalDeliveryDone()
          }}
          disabled={loading}
        >
          {t(`registration.deliveryDone`)}
        </StyledButton>
      </Container>
    </APIProvider >
  );
};
