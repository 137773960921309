import styled from "styled-components";
import { Button, Typography } from "@mui/material";

import { T2, T3, T5 } from "@heylog-app/frontend-lib/app";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 1rem 1rem 1rem;
  font-family: Arial, sans-serif;
  text-align: center;
  margin-bottom: 2rem;
  width: clamp(18.75rem, 90vw, 24.6rem);
`;
// min-height: 419px;

export const ContainerV2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 36px 169px 36px;
  font-family: Arial, sans-serif;
  text-align: center;
  width: clamp(18.75rem, 90vw, 24.6rem);
`;
// min-height: 419px;

export const AcceptButton = styled(Button)`
  && {
    background-color: #4169e1;
    color: white;
    font-weight: bold;
    margin-top: 16px;
    width: 80%;
  }
`;
export const PdfContainer = styled.div`
  width: 100%;
  height: 500px;
  overflow: auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  position: relative;
`;

export const PdfViewer = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;
export const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: blue transparent;

  /* For Webkit-based browsers (Chrome, Edge, Safari) */
  &::-webkit-scrollbar {
    width: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: blue;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darkblue;
  }
`;

export const StyledScrollDiv = styled.div`
  width: 100%;
  overflow: auto;

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: blue lightgray;

  /* Webkit (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: lightgray;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: blue;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darkblue;
  }
`;

export const CommunicationButton = styled(Button)`
  ${T3};
  width: clamp(18rem, 90vw, 23rem);
  height: 42px;
  padding: 8px 10px 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 4px;
  border: 1px solid #122cc5;
  background: #fff;
`;

export const StyledTypoV1 = styled(Typography)`
  color: #9f9f9f;
  ${T5}
`;
export const StyledTypoV2 = styled(Typography)`
  color: var(--Primary-Main, #122cc5);
  ${T2}
`;
export const StyledContainer = styled.div`
  margin-top: 0.25rem;
  padding: 0.625rem 0;
  align-self: flex-start;
  justify-items: flex-start;
  padding-left: clamp(0.75rem, 5vw, 1.25rem);
  width: 100%;
  max-width: 23rem;
`;
