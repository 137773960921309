import styled from "styled-components";
import { T4 } from "@heylog-app/frontend-lib/app";
import { Box } from "@mui/material";

export const LinkWrapper = styled.div`
  display: flex;
  gap: 2px;
  justify-content: center;
  margin-top: 14px;
  margin-bottom: 10px;
`;

export const SiteMapLink = styled.a`
  ${T4};
  color: var(--Primary-Main, #122cc5);
  font-style: normal;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const DownloadText = styled.span`
  color: var(--White-500, #9ba0b3);
  font-style: normal;
  ${T4};
`;
export const ProgressBox = styled(Box)`
  width: clamp(17rem, 90vw, 23rem);
  margin-top: 3.3rem;
`;
