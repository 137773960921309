import styled from 'styled-components';

import { T2_medium, colors } from '@heylog-app/frontend-lib/app';
import { FormControlLabel } from '@mui/material';

export const StyledMain = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	height: 100%;
	padding: 0px 20px 258px 0px;
	background:  #FFF
`;
export const StyledLanguageBox = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 20px;
	gap: 10px;
`
export const StyledLanguageBoxText = styled.div`
	font-family: Inter;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: -0.1px;
	vertical-align: middle;
	color: #7C839E;
`
export const StyledBoxDiv = styled.div`
	height: 182px;
	align-self: stretch;
	border-radius: 4px;
	border: 2px dashed  #122CC5;
	background:  #EBEDFF;
	align-content: center;
`
export const StyledBoxDivInside = styled.div`
	display: flex;
	width: 555px;
	flex-direction: column;
	align-items: center;
	gap: 10px;
`
export const StyledTextBox = styled.div`
	color: #030B5E;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
	letter-spacing: -0.3px;
	align-self: stretch;
`
export const StyledButtonBoxInside = styled.div`
	display: flex;
	width: 133px;
	flex-direction: column;
	gap: 10px;
	border-radius: 4px;
	padding: 10px;
	background:  #F5F8FE;
	color:  #122CC5;
	text-align: center;
	cursor: pointer;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
`
export const StyledTextFooter = styled.div`
	color:  #7C839E;
	text-align: center;
	font-family: Inter;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 160% */
	letter-spacing: -0.1px;
`
export const StyledMainInner = styled.div`
	height: 47px;
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	gap: 100px;
`;
export const StyledRightText = styled.div`
color: #7C839E;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */
letter-spacing: -0.1px;
`
export const StyledRightTextBlue = styled.div`
color:  #122CC5;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: -0.1px;
`
export const StyledInner2 = styled.div`
	display: inline-flex;
	align-items: flex-start;
	gap: 106px;
`;
export const StyledHeadTitle = styled.div`
	color: ${colors.primary.darker};
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;
export const StyledHeadLabel = styled.div`
	color: ${colors.grey[600]};
	/* Regular/T4 */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 133.333% */
	letter-spacing: -0.1px;
`;
export const StyledFormControlLabel2 = styled(FormControlLabel)`
	margin-right: 0;
	padding-left: 3px;
	color: #030B5E;

	span {
	}

	.MuiTypography-root {
		font-size: 14px;
	}
`;
export const StyledLeftBox = styled.div`
	display: flex;
	width: 597px;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
`
export const StyledRightBox = styled.div`
	display: flex;
	width: 597px;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
`
export const StyledMainInnerHead = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	gap: 10px;
	align-items: flex-start;
	justify-content: center;
	`
export const StyledInnerHead = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	gap: 10px;
	align-items: flex-start;
	justify-content: center;
`