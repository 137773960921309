import React from "react";
import {
  APIProvider,
  Map,
  Marker,
} from '@vis.gl/react-google-maps';
import { useEnvContext } from "@heylog-app/frontend-lib/app";

const defaultCenter = {
  lat: 34.1540,
  lng: -118.6474,
};

const locations = [
  { lat: 34.1540, lng: -118.6474 },
  { lat: 40.7128, lng: -74.006 },
];

const styles = [
  {
    featureType: 'administrative.locality',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

export const GoogleMapWithMarkers = () => {
  const env = useEnvContext();

  return (
    <APIProvider apiKey={env.GPLACES_KEY}>
      <div style={{ height: '300px', width: '100%' }}>
        <Map
          center={defaultCenter}
          mapId={'739af084373f96fe'}
          zoom={18}
          controlSize={20}
          styles={styles}
          gestureHandling={'greedy'}
          zoomControl={true}
          streetViewControl={true}
          fullscreenControl={false}
          mapTypeControl={true}
          disableDefaultUI={true}
        >
          {locations.map((location, index) => (
            <Marker key={index} position={location} />
          ))}
        </Map>
      </div>
    </APIProvider >
  );
};
