import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

import type { GridColDef } from '@mui/x-data-grid-pro';
import { DeleteOutlined } from '@mui/icons-material';
import { useSiteById } from './use-workspace.hook';


export interface SafetyDetailsRow2 {
    id?: number;
    name?: string;
    language?: string;
    pathURL?: string;
}
type PaginationModel2 = {
    pageNumber?: number;
    pageSize?: number;
};

export const useSiteDocumentsData = (handleDeleteClick?: (contact: SafetyDetailsRow2) => void, paginationModel?: PaginationModel2) => {
    const { t } = useTranslation();
    const columns: GridColDef[] = [
        {
            field: 'name',
            minWidth: 200,
            headerName: t('Documents.header.name'),
            headerClassName: 'bold-header',
            sortable: true,


        },
        {
            field: 'language',
            minWidth: 230,
            headerClassName: 'bold-header',
            sortable: true,

            headerName: t(`Documents.header.langauge`),
        },


        {
            field: 'actions',
            headerName: t(`actionLabels.actions`),
            headerClassName: 'bold-header',
            minWidth: 100,
            renderCell: (params) => (
                <IconButton
                    onClick={() => handleDeleteClick?.(params.row)}
                    aria-label="edit"
                >
                    <DeleteOutlined />
                </IconButton>
            ),
        },
    ];
    const {
        dataCheck: safetyDcouments,
        refetchSiteDocument,
        isLoading,
        pagination,
    } = useSiteById({ page: paginationModel?.pageNumber, rowsPerPage: paginationModel?.pageSize })


    const rows: SafetyDetailsRow2[] = safetyDcouments?.map((contact, index) => ({
        id: index,
        name: contact?.fileName,
        language: contact?.language || '',
        pathURL: contact?.siteMapUrl || '',

    })) || [];

    return {
        columns, rows, isLoading, refetchSiteDocument
        , totalRowsContact: pagination?.totalRows
    };
};


