import { StyledPageHeader } from './page-header.styles';
import { LanguageSwitch, LanguageSwitchV2 } from '../language-switch';
import { HeylogIconBlue } from '../../icons';
import { HeylogHeaderIcon } from '../../icons';

import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface PageHeaderProps {
	isHeaderIcon?: boolean;
}

export const PageHeader: FC<PageHeaderProps> = ({ isHeaderIcon = false }) => {
	const { t } = useTranslation();
	return (
		<StyledPageHeader  style={{ padding: isHeaderIcon ? '0 1rem' : '0' }}>
			<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
				{isHeaderIcon ? <HeylogHeaderIcon /> : <HeylogIconBlue />}
			</div>
			{isHeaderIcon ? <LanguageSwitchV2 /> : <LanguageSwitch />}
		</StyledPageHeader>
	);
};
