/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, ReactNode, useState } from "react";
import { useMultiStepHook } from "./useMultiStepHook";
import { UseFormReturn } from "react-hook-form";
import {
  CommuteChanel,
  FinalStep,
  FirstStep,
  SafetyInstructions,
  SecondStep,
  ThirdStep,
} from "./steps";
import { RegisterPageLayout } from "@heylog-app/frontend-lib/app";
import { RegistrationStatus } from "./registered/registrationStatus";

interface MultiStepContextType {
  currentStepIndex: number;
  step?: React.ReactElement;
  steps: React.ReactElement[];
  isFirstStep: boolean;
  isLastStep: boolean;
  goTo: (index: number) => void;
  next: () => void;
  back: () => void;
  formMethods: UseFormReturn<any>;
  setTransportDetails?: any;
  transportDetails?: any;
  setDeliveryNotes?: any;
  deliveryNotes?: any;
  setRegisterState?: any;
  registerState?: any;
  setTasksState?: any;
  tasksState?: any;
}

export const MultiStepContext = createContext<MultiStepContextType | null>(
  null
);

export const MultiStepProvider: React.FC<{
  children: ReactNode;
  formMethods: UseFormReturn<any>;
}> = ({ children, formMethods }) => {
  const multiStep = useMultiStepHook([
    <FirstStep formMethods={formMethods} />,
    <SecondStep formMethods={formMethods} />,
    <ThirdStep />,
    <SafetyInstructions />,
    <CommuteChanel />,
    <RegistrationStatus />,
    <FinalStep />
  ]);
  const [registerState, setRegisterState] = useState();
  const [deliveryNotes, setDeliveryNotes] = useState();
  const [transportDetails, setTransportDetails] = useState();
  const [tasksState, setTasksState] = useState()
  return (
    <MultiStepContext.Provider
      value={{
        ...multiStep,
        formMethods,
        setTransportDetails,
        transportDetails,
        setDeliveryNotes,
        deliveryNotes,
        setRegisterState,
        registerState,
        setTasksState,
        tasksState,
      }}
    >
      {children}
    </MultiStepContext.Provider>
  );
};

export const useMultiStep = () => {
  const context = useContext(MultiStepContext);
  if (!context)
    throw new Error("useMultiStep must be used within MultiStepProvider");
  return context;
};
