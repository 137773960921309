export const formatPhoneNumberForDisplay = (value?: string) => {
	return value ? '+' + value : '';
};


export const formatPhoneNumberHide = (phone?: string) => {
	if (!phone || phone.length < 6) return 'N/A'; // Handle invalid cases

	const countryCode = phone.slice(0, 4); // Extract the first 4 digits
	const lastTwoDigits = phone.slice(-2); // Extract last 2 digits
	const maskedPart = '*'.repeat(phone.length - 6); // Mask everything except first 4 and last 2 digits

	return formatPhoneNumberForDisplay(`${countryCode}${maskedPart}${lastTwoDigits}`);
};