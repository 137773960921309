import React from "react";
import { Stack } from "@mui/material";
import { CommunicationButton, ContainerV2 } from "./form.styles";
import { DetailsHeader } from "../components/DetailsHeader";
import { IconHeylog2, IconWhatsapp3 } from "@heylog-app/frontend-lib/app";
import { useMultiStep } from "../MultiStepProvider";

export const CommuteChanel = () => {
  const { formMethods: { setValue }, transportDetails } = useMultiStep();

  const handleSelect = (channel: "whatsapp" | "heylog") => {
    setValue("step5.communicationChanel", channel, { shouldValidate: true });
  };

  return (
    <ContainerV2>
      <DetailsHeader note="registration.selectCommunicationChannel" transportDetails={transportDetails} />
      <Stack gap={2} style={{ marginTop: "4rem" }}>
        <CommunicationButton
          startIcon={<IconWhatsapp3 />}
          onClick={() => handleSelect("whatsapp")}
          disabled
        >
          WhatsApp
        </CommunicationButton>
        <CommunicationButton
          startIcon={<IconHeylog2 />}
          onClick={() => handleSelect("heylog")}
        >
        </CommunicationButton>
      </Stack>
    </ContainerV2>
  );
};
