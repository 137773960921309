/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-useless-fragment */
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useState } from "react";

import {
  useSnackbar,
  FlashSnackbar,
  useApiClientContext,
  createPrivacyPolicy,
  usePlugins,
} from "@heylog-app/frontend-lib/app";

import {
  StyledDocumentTab,
  StyledDocumentTabName,
  StyledMain,
  StyledSettingsContentWrapper,
  StyledSettingsPageLayout,
} from "./settings.styles";

import "./settings.css";
import type { Dispatch, FC, SetStateAction } from "react";
import { DpaPage } from "./components/dpa-page";

import SafetyIcon from "../../images/safety";
import SiteIcon from "../../images/site";
import DpaIcon from "../../images/dpa";
import { SafetyPage } from "./components/safety-instruction";
import { SitePage } from "./components/site-map";
interface DocumentSettingsPageProps {
  fileDPA: any;
  setFileDPA: Dispatch<SetStateAction<File | undefined>>;
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<string>>;
  setMenuValuee: Dispatch<SetStateAction<number>>;
  setFileSafety: Dispatch<SetStateAction<File | undefined>>;
  fileSafety?: File;
  setFileSite: Dispatch<SetStateAction<File | undefined>>;
  fileSite?: File;
  sideLang: string;
  safetyLang: string;
  setSafetyLang: React.Dispatch<React.SetStateAction<string>>;
  setSideLang: React.Dispatch<React.SetStateAction<string>>;
}
export const DocumentSettingsPage: FC<DocumentSettingsPageProps> = ({
  fileDPA,
  setFileDPA,
  setSelectedValue,
  selectedValue,
  setMenuValuee,
  setFileSafety,
  fileSafety,
  setFileSite,
  fileSite,
  sideLang,
  safetyLang,
  setSafetyLang,
  setSideLang,
}: any) => {
  const [menuValue, setMenuValue] = useState(0);
  const handleChange = (newValue: number) => {
    setMenuValue(newValue);
    setMenuValuee(newValue);
  };
  const { workspaceId = "" } = useParams();
  const { SCBPlugin } = usePlugins(workspaceId);
  return (
    <StyledMain>
      <StyledSettingsPageLayout>
        <StyledSettingsContentWrapper>
          <>
            <StyledDocumentTabName>
              <StyledDocumentTab
                isActive={menuValue === 0}
                onClick={(e) => handleChange(0)}
              >
                <DpaIcon
                  style={{ color: menuValue === 0 ? "#122CC5" : "#030B5E" }}
                />
                <span>DPA</span>
              </StyledDocumentTab>
              {SCBPlugin && (
                <>
                  <StyledDocumentTab
                    isActive={menuValue === 1}
                    onClick={(e) => handleChange(1)}
                  >
                    <SafetyIcon
                      style={{ color: menuValue === 1 ? "#122CC5" : "#030B5E" }}
                    />
                    <span>Safety Instructions</span>
                  </StyledDocumentTab>
                  <StyledDocumentTab
                    isActive={menuValue === 2}
                    onClick={(e) => handleChange(2)}
                  >
                    <SiteIcon
                      style={{ color: menuValue === 2 ? "#122CC5" : "#030B5E" }}
                    />
                    <span>Site Map</span>
                  </StyledDocumentTab>
                </>
              )}
            </StyledDocumentTabName>
            {menuValue === 0 && (
              <DpaPage
                filee={fileDPA}
                setFile={setFileDPA}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
              />
            )}
            {menuValue === 1 && (
              <SafetyPage
                filee={fileSafety}
                setFile={setFileSafety}
                safetyLang={safetyLang}
                setSafetyLang={setSafetyLang}
              />
            )}
            {menuValue === 2 && (
              <SitePage
                filee={fileSite}
                setFile={setFileSite}
                sideLang={sideLang}
                setSideLang={setSideLang}
              />
            )}
          </>
        </StyledSettingsContentWrapper>
      </StyledSettingsPageLayout>
    </StyledMain>
  );
};
