import React, { useRef, useState, useEffect } from "react";
import { Page } from "react-pdf";
import { PdfContainer } from "./pdf.styles";
import type { UseFormSetValue } from "react-hook-form";

interface PdfViewerProps {
  width?: string;
  height?: string;
  itemCount: number;
  scale?: number;
  setValue: UseFormSetValue<any>;
}

export const PdfViewer: React.FC<PdfViewerProps> = ({
  width = "252px",
  // height = "340px",
  height = "250px",
  itemCount,
  scale = 1.0,
  setValue,
}) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      // Enable button when user is close to the bottom (within 20px)
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20;

      if (isAtBottom && !isButtonEnabled) {
        setIsButtonEnabled(true);
      }
    }
  };

  // Add scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [isButtonEnabled]);

  useEffect(() => {
    setValue('step4.hasScrolled', isButtonEnabled);
  }, [isButtonEnabled]);
  


  return (
    <PdfContainer width={width} height={height} ref={containerRef}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {Array.from({ length: itemCount }, (_, index) => (
          <Page key={index} pageNumber={index + 1} scale={scale} width={parseInt(width)} renderTextLayer={false} renderAnnotationLayer={false} />
        ))}
      </div>
    </PdfContainer>
  );
};
