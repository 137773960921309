import styled from "styled-components";

import { RESET_LIST, SIZE_SM, STYLE_1, T4 } from "../../../styles";

export const StyledPageFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${STYLE_1};
  ${SIZE_SM};
`;

export const StyledFooterLinks = styled.ul`
  ${RESET_LIST};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  gap: 1rem;
  width: 100%;
  padding: 1rem 0.25rem;
  margin-bottom: 1rem;

  border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};

  li {
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.palette.common.black};

      svg {
        max-height: 0.6rem;
        max-width: 0.6rem;
        margin-left: 0.25rem;
        margin-bottom: 0.25rem;
      }
    }
  }
`;

export const StyledFooterLinksV2 = styled.ul`
  ${RESET_LIST};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  gap: 1rem;
  width: 100%;
  padding: 1.25rem 0.25rem;
  ${T4};

  li {
    a {
      text-decoration: none;
      color: var(--White-500, #9ba0b3);

      svg {
        max-height: 0.6rem;
        max-width: 0.6rem;
        margin-left: 0.25rem;
        margin-bottom: 0.25rem;
      }
    }
  }
`;

export const StyledCopyright = styled.span`
  color: ${({ theme }) => theme.palette.common.blackTransparent};
`;

export const StyledCopyrightV2 = styled.span`
  color: var(--White-400, #cdcfd9);
  width: 128px;
  height: 16px;
  ${T4};
  font-style: normal;
`;
