import { useCallback, useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import { ChevronRight } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';

import { languages } from '@heylog-app/shared/util';

import { theme } from '../../../styles/theme/theme';
import { LanguageIcon, LanguageIconV2 } from '../language-icon';
import { StyledButton, StyledButtonV2, StyledLabel, StyledLabelV2 } from './language-switch.styles';

import type { FC } from 'react';
import { MenuDropdown } from '../../icons';
import { useParams } from 'react-router-dom';

export const LanguageSwitch: FC = () => {
	//TODO when merging new languages branch, add those changes here, be carafeul
	const showLanguageMenuButton = useRef(null);
	const [showLanguageMenu, setShowLanguageMenu] = useState(false);
	const id = 'language-switch';

	const [selectedLanguage, setSelectedLanguage] = useState('en');

	useEffect(() => {
		i18next.changeLanguage(selectedLanguage);
	}, [selectedLanguage]);

	const sortedLanguages = languages.sort((a, b) =>
		a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }),
	);

	const languageObj = sortedLanguages.find((lang) => lang.value === selectedLanguage);

	const handleLanguageChange = useCallback((value: string) => {
		setSelectedLanguage(value);
		setShowLanguageMenu(false);
	}, []);

	const toggleLanguageMenu = useCallback(() => {
		setShowLanguageMenu(!showLanguageMenu);
	}, [showLanguageMenu]);

	const closeLanguageMenu = useCallback(() => {
		setShowLanguageMenu(false);
	}, []);

	if (!languageObj) return null;

	return (
		<>
			<StyledButton
				ref={showLanguageMenuButton}
				id={`control-${id}`}
				aria-controls={id}
				aria-haspopup="true"
				aria-expanded={showLanguageMenu}
				onClick={toggleLanguageMenu}
				startIcon={<LanguageIcon language={languageObj.value} />}
				endIcon={
					<ChevronRight
						sx={{
							transform: 'rotate(90deg)',
							path: { fill: theme.palette.common.black },
						}}
					/>
				}
			>
				<StyledLabel>{languageObj.label}</StyledLabel>
			</StyledButton>

			<Menu
				anchorEl={showLanguageMenuButton?.current}
				id={id}
				open={showLanguageMenu}
				onClose={closeLanguageMenu}
				MenuListProps={{
					'aria-labelledby': `control-${id}`,
				}}
			>
				{sortedLanguages.map(({ label, value }) => (
					<MenuItem onClick={() => handleLanguageChange(value)} id={value} key={value}>
						<StyledLabel>
							{<LanguageIcon language={value} />}
							{label}
						</StyledLabel>
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export const LanguageSwitchV2: FC = () => {
	//TODO when merging new languages branch, add those changes here, be carafeul
	const showLanguageMenuButton = useRef(null);
	const { ln = "" } = useParams()
	const [showLanguageMenu, setShowLanguageMenu] = useState(false);
	const id = 'language-switch';

	const [selectedLanguage, setSelectedLanguage] = useState(ln || 'en');

	useEffect(() => {
		i18next.changeLanguage(selectedLanguage);
	}, [selectedLanguage]);

	const sortedLanguages = languages.sort((a, b) =>
		a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }),
	);

	const languageObj = sortedLanguages.find((lang) => lang.value === selectedLanguage);

	const handleLanguageChange = useCallback((value: string) => {
		setSelectedLanguage(value);
		setShowLanguageMenu(false);
	}, []);

	const toggleLanguageMenu = useCallback(() => {
		setShowLanguageMenu(!showLanguageMenu);
	}, [showLanguageMenu]);

	const closeLanguageMenu = useCallback(() => {
		setShowLanguageMenu(false);
	}, []);

	if (!languageObj) return null;

	return (
		<div style={{ padding: '3px 0px' }}>
			<StyledButtonV2
				ref={showLanguageMenuButton}
				id={`control-${id}`}
				aria-controls={id}
				aria-haspopup="true"
				aria-expanded={showLanguageMenu}
				onClick={toggleLanguageMenu}
				startIcon={<LanguageIconV2 language={languageObj.value} />}
				endIcon={
					<MenuDropdown
					/>
				}
			>
				<StyledLabelV2>{languageObj.label}</StyledLabelV2>
			</StyledButtonV2>

			<Menu
				anchorEl={showLanguageMenuButton?.current}
				id={id}
				open={showLanguageMenu}
				onClose={closeLanguageMenu}
				MenuListProps={{
					'aria-labelledby': `control-${id}`,
				}}
			>
				{sortedLanguages.map(({ label, value }) => (
					<MenuItem onClick={() => handleLanguageChange(value)} id={value} key={value}>
						<StyledLabelV2>
							{<LanguageIconV2 language={value} />}
							{label}
						</StyledLabelV2>
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};