
const DpaIcon = (props: any) => {
    return (
        <div>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"
                {...props}>
                <path d="M4.5 5.05102C4.5 4.09864 5.2619 3.30952 6.2415 3.30952H10.7313C11.1939 3.30952 11.6293 3.5 11.9558 3.82653L14.432 6.30272C14.7585 6.62925 14.949 7.06463 14.949 7.52721V15.5C14.949 16.4796 14.1599 17.2415 13.2075 17.2415H6.2415C5.2619 17.2415 4.5 16.4796 4.5 15.5V5.05102ZM13.6429 15.5V7.66327H11.466C10.9762 7.66327 10.5952 7.28231 10.5952 6.79252V4.61565H6.2415C5.9966 4.61565 5.80612 4.83333 5.80612 5.05102V15.5C5.80612 15.7449 5.9966 15.9354 6.2415 15.9354H13.2075C13.4252 15.9354 13.6429 15.7449 13.6429 15.5Z" fill={"currentColor"} />
            </svg>

        </div>
    );
}

export default DpaIcon;
