import { Button } from "@mui/material";
import styled from "styled-components";

import { STYLE_1, STYLE_2, T4 } from "../../../styles";

export const StyledButton = styled(Button)`
  ${STYLE_1};

  svg {
    border-radius: 50%;
  }
`;

export const StyledButtonV2 = styled(Button)`
  ${T4};
`;

export const StyledLabel = styled.span`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.black};
  ${STYLE_2};

  svg {
    border-radius: 50%;
  }
`;
export const StyledLabelV2 = styled.span`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.black};
  ${STYLE_2};
  font-family: Inter;
  color: var(--White-600, #7c839e);
`;
