import { StyledSidebarList, StyledSidebarListV2 } from './sidebar-list.styles';

import type { FC, ReactNode } from 'react';

type SidebarListProps = {
	children?: ReactNode;
};

export const SidebarList: FC<SidebarListProps> = ({ children }) => {
	return <StyledSidebarList>{children}</StyledSidebarList>;
};

export const SidebarListV2: FC<SidebarListProps> = ({ children }) => {
	return <StyledSidebarListV2>{children}</StyledSidebarListV2>;
};
