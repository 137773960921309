import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Paper,
	Stack,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material';
import { mdiMenuDown, mdiTruckDeliveryOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { OrderTaskStatusEnum, OrderTaskTypeEnum } from '@heylog-app/shared/types';

import { getFormattedEta, updateOrderTaskStatus } from '../../util';
import {
	CustomBlock,
	CustomCompanyInfoBlock,
	CustomInfoBlock,
	CustomLabelsBlock,
	CustomReferenceBlock,
	CustomReferenceText,
	CustomStepCircle,
	StatusDropdown,
	StatusLabel,
	StyledCompanyName,
	StyledContainer,
	StyledHeader,
	StyledLabel,
	StyledLocation,
	StyledStatusButton,
	StyledStatusMenu,
	StyledStatusOption,
	StyledStepWrapper,
	StyledTypeLabel,
	StyledWithoutTasks,
} from './vertical-stepper.styles';
import { IconUntrack } from '../icons/icon-untrack';
import { TaskDetailsDialog } from '../task-details-dialog/task-details-dialog';
import { useApiClientContext, useDialog, useSnackbar } from '../../hooks';

import type {
	OrderTaskResInterface,
	ContactResInterface,
	ContactOrderTaskStatus,
	OrderTaskStatusUpdateReqInterface,
} from '@heylog-app/shared/types';
import type { FC, Dispatch, SetStateAction } from 'react';
import { UpdateOrderTaskParams } from '../../types';
import { FlashSnackbar } from '../snackbars';

export interface VerticalStepperProps {
	completedSteps?: number[];
	contacts?: ContactResInterface[];
	currentStep?: number;
	currentSteps?: number[];
	customer?: string;
	deleteTask: (id?: number) => void;
	haveSwitcher: boolean;
	isLinear?: boolean;
	setStatusChanged: any;
	steps: OrderTaskResInterface[];
	warningSteps?: number[];
	problemSteps?: number[];
	shipmentId?: string;
	workspaceId?: string;
}

export const VerticalStepper: FC<VerticalStepperProps> = ({
	completedSteps = [],
	contacts,
	currentStep,
	currentSteps = [],
	customer,
	deleteTask,
	haveSwitcher = true,
	isLinear = false,
	setStatusChanged,
	shipmentId,
	steps,
	problemSteps = [],
	workspaceId,
	warningSteps = [],
}) => {
	const { t } = useTranslation();
	const [activeStep, setActiveStep] = useState(currentStep);
	const [activeSteps, setActiveSteps] = useState(currentSteps);
	const [doneSteps, setDoneSteps] = useState(completedSteps);
	const [warnSteps, setWarnSteps] = useState(warningSteps);
	const [number, setNumber] = useState(0);
	const [dialogData, setDialogData] = useState<OrderTaskResInterface | null>(null);
	const { apiClient } = useApiClientContext();
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const handleNext = () => {
		activeStep && setActiveStep((prevActiveStep) => prevActiveStep && prevActiveStep + 1);
	};

	const handleBack = () => {
		activeStep && setActiveStep((prevActiveStep) => prevActiveStep && prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
		setActiveSteps([]);
		setDoneSteps([]);
		setWarnSteps([]);
	};

	const openDialog = (data: OrderTaskResInterface, number: number) => {
		setDialogData(data);
		setNumber(number);
		openTaskDetailsDialog();
	};

	useEffect(() => {
		setActiveSteps(currentSteps);
		setDoneSteps(completedSteps);
		setWarnSteps(warningSteps);
	}, [currentSteps, completedSteps, warningSteps]);

	const {
		showDialog: showTaskDetailsDialog,
		openDialog: openTaskDetailsDialog,
		closeDialog: closeTaskDetailsDialog,
	} = useDialog();
	const updateStatus = (id: number, status: string) => {
	}
	const handleOptionClick = (orderTask: any, newStatus: ContactOrderTaskStatus) => {
		if (newStatus !== orderTask.status) {
			const params: UpdateOrderTaskParams<OrderTaskStatusUpdateReqInterface> = {
				data: { status: newStatus },
				orderId: shipmentId,
				taskId: orderTask.id.toString(),
				workspaceId: workspaceId || "",
			};

			updateOrderTaskStatus(apiClient, params)
				.then(() => {
					openSnackbar(
						'success',
						t('orders.tasks.tsuSuccess', {
							orderTaskRef: orderTask.customerReference,
						}),
					);
					if (setStatusChanged) {
						setStatusChanged?.({ status: newStatus, taskId: orderTask.id.toString() });
					}
				})
				.catch((err) => {
					openSnackbar(
						'error',
						t('orders.tasks.tsuError', {
							orderTaskRef: orderTask.customerReference,
						}),
					);
					console.error(err);
				});
		}
	};
	return (
		<>
			<StyledContainer>
				{steps[0] ? (
					<Stepper
						{...(!isLinear ? { activeStep } : { nonLinear: true })}
						orientation="vertical"
					>
						{steps.map((step, index) => (
							<Step key={index}>
								<StepLabel
									sx={{
										'& .MuiStepLabel-label': {
											color: 'text.primary',
											fontWeight: '500',
										},
									}}
									StepIconComponent={({ active, completed, warning, problem }) => (
										<CustomStepCircle
											completed={isLinear ? doneSteps.includes(index) : completed}
											active={isLinear ? activeSteps.includes(index) : active}
											warning={isLinear ? warnSteps.includes(index) : warning}
											problem={isLinear ? problemSteps.includes(index) : problem}
										><>
												{doneSteps.includes(index) ? (
													<>
														<Check style={{ color: '#fff', fontSize: '14px', fontWeight: "bold" }} />
													</>
												) : (
													<Stack sx={{ color: '#fff', fontSize: '14px' }}>{index + 1}</Stack>
												)}
											</>
										</CustomStepCircle>
									)}
								>
									<StyledStepWrapper onClick={() => openDialog(step, index + 1)}>
										<CustomLabelsBlock>
										<StyledHeader>
												{step.type === OrderTaskTypeEnum.LOADING ? (
													<Icon path={mdiTruckDeliveryOutline} size={1} />
												) : step.type === OrderTaskTypeEnum.REGISTER ? (
													<Icon path={mdiTruckDeliveryOutline} size={1} /> //icon required here
												) : (
													<IconUntrack />
												)}
												<StyledTypeLabel>
													{step.type === OrderTaskTypeEnum.LOADING
														? t('orders.tasks.type.loading')
														: step.type === OrderTaskTypeEnum.REGISTER
															? t('orders.tasks.type.register')
															: t('orders.tasks.type.unloading')}
												</StyledTypeLabel>
											</StyledHeader>

											{/* {step.status === OrderTaskStatusEnum.COMPLETED ? (
											<StatusLabel completed={true} active={false}>
												{t('orders.tasks.status.done')}
											</StatusLabel>
										) : step.status === OrderTaskStatusEnum.IN_PROGRESS ? (
											<StatusLabel completed={false} active={true}>
												{t('orders.tasks.status.in_progress')}
											</StatusLabel>
										) : step.status === OrderTaskStatusEnum.CANCELLED ? (
											<StatusLabel completed={true} active={false} cancelled={true}>
												{t('orders.tasks.status.cancelled')}
											</StatusLabel>
										) : (
											<StatusLabel completed={false} active={false}>
												{t('orders.tasks.status.pending')}
											</StatusLabel>
										)} */}
											<StatusDropdown>
												<StyledStatusButton status={step.status} height='32px'>
													<Stack direction={'row'} alignItems={"center"}>
														{t(`orders.tasks.status.${step.status.toLowerCase()}`)} <Icon path={mdiMenuDown} size={1} />
													</Stack>
												</StyledStatusButton>
												<StyledStatusMenu>
													{[
														OrderTaskStatusEnum.COMPLETED,
														OrderTaskStatusEnum.PENDING,
														OrderTaskStatusEnum.PROBLEM,
														OrderTaskStatusEnum.CANCELLED,
														OrderTaskStatusEnum.IN_PROGRESS,
													].filter(item => item.toLowerCase() !== step.status.toLowerCase()).map((status) => (
														<StyledStatusOption
															key={status}
															onClick={(e) => {
																e.stopPropagation();
																handleOptionClick(step, status)
															}}
															status={status}
														>
															{t(`orders.tasks.status.${status.toLowerCase()}`)}
														</StyledStatusOption>
													))}
												</StyledStatusMenu>
											</StatusDropdown>
										</CustomLabelsBlock>
										<CustomBlock>
											<CustomCompanyInfoBlock>
												<CustomBlock>
													<CustomReferenceBlock>
														<StyledLabel>{t('orders.tasks.ref')}:</StyledLabel>
														<CustomReferenceText>{step.customerReference}</CustomReferenceText>
													</CustomReferenceBlock>
												</CustomBlock>
												{/* <CustomInfoBlock>
												<StyledLabel>{t('orders.tasks.company')}:</StyledLabel>
												<StyledCompanyName>{step.company}</StyledCompanyName>
											</CustomInfoBlock> */}

											</CustomCompanyInfoBlock>
										</CustomBlock>
										<CustomBlock>
											<StyledLabel>
												{step.etaFrom
													? getFormattedEta(new Date(step.etaFrom as Date))
													: ''}
												{step.etaTo
													? ' - ' + getFormattedEta(new Date(step.etaTo as Date))
													: ''}
											</StyledLabel>
										</CustomBlock>
										<CustomInfoBlock>
											{/* <StyledLabel>{t('orders.tasks.address')}:</StyledLabel> */}
											<StyledLocation>{step.location}</StyledLocation>
										</CustomInfoBlock>
									</StyledStepWrapper>
								</StepLabel>
								<StepContent>
									{/*<Typography>{step.street}</Typography>*/}
									{haveSwitcher && (
										<Box sx={{ mb: 2 }}>
											<div>
												<Button
													variant="contained"
													onClick={handleNext}
													sx={{ mt: 1, mr: 1 }}
												>
													{index === steps.length - 1 ? 'Finish' : 'Continue'}
												</Button>
												<Button
													disabled={index === 0}
													onClick={handleBack}
													sx={{ mt: 1, mr: 1 }}
												>
													{t('orders.tasks.back')}
												</Button>
											</div>
										</Box>
									)}
								</StepContent>
							</Step>
						))}
					</Stepper>
				) : (
					<StyledWithoutTasks>{t('exceptions.order.noTasks')}</StyledWithoutTasks>
				)}
				{haveSwitcher && activeStep === steps.length && (
					<Paper square elevation={0} sx={{ p: 3 }}>
						<Typography>All steps completed - you&apos;re finished</Typography>
						<Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
							Reset
						</Button>
					</Paper>
				)}
				{dialogData && (
					<TaskDetailsDialog
						contacts={contacts}
						customer={customer ? customer : ''}
						dataTest="task-details-dialog"
						deleteTask={deleteTask}
						dialogTitle={t('orders.taskDetailsDialog.title', { number })}
						isOpen={showTaskDetailsDialog}
						onClose={closeTaskDetailsDialog}
						orderTask={dialogData}
						setStatusChanged={setStatusChanged}
					/>
				)}{' '}
			</StyledContainer>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</>
	);
};
