import { List } from '@mui/material';
import styled from 'styled-components';

export const StyledSidebarList = styled(List)`
	padding: 0;

	overflow-y: auto;
	height: 100%;
`;

export const StyledSidebarListV2 = styled(List)`
	padding: 14px 9px 0px 9px;

	overflow-y: auto;
	height: 80%;
`;
