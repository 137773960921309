import styled from "styled-components";

interface PdfViewerProps {
  width?: string;
  height?: string;
}

export const PdfContainer = styled.div<PdfViewerProps>`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "500px"};
  overflow-y: auto;
  overflow-x: hidden;
  background: #f2f2f2;
  border-radius: 10px;

  ::-webkit-scrollbar {
    width: 8px;
    background: #f2f2f2 !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #122cc5;
    border: 0px !important;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-track {
    padding-block: 4px;
    background-color: #f2f2f2;
  }
`;
