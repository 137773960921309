import React from "react";
import { StyledStatusMessage } from "../registrationform.styles";
import { useTranslation } from "react-i18next";

export const FinalStep = () => {
    const { t } = useTranslation();
    return <div>
        <StyledStatusMessage status={'proceed'} style={{ justifyItems: 'center' }}>
            <div>{t(`registration.thankyouMsg`)}</div>
            <div>{t(`registration.closeApp`)}</div>
        </StyledStatusMessage>
    </div>;
};
