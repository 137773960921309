import React, { useCallback, useEffect, useState } from 'react';
import {
  StyledTextWrapperPage2,
  BorderLinearProgress,
  StyledStatusMessage,
  StyledTextWrapper,
  StyledButton,
} from '../registrationform.styles';
import {
  mdiInformation,
  mdiCloseCircle,
  mdiCheckCircle,
} from '@mdi/js';
import Icon from '@mdi/react';
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getFetcher, RegisterPageLayout, useApiClientContext } from '@heylog-app/frontend-lib/app';
import { DownloadText, LinkWrapper, ProgressBox, SiteMapLink } from './status.styles';
import { MapStopsV2, RegisterHeader } from '../components';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { FileDownloadResInterface } from '@heylog-app/shared/types';
import i18next from 'i18next';
const statusDetails: Record<
  'waiting' | 'proceed' | 'error',
  { message: string; style: string; icon: string; value: number }
> = {
  waiting: {
    message: 'registration.waitInParkingLot',
    style: '#1588C7',
    icon: mdiInformation,
    value: 76,
  },
  proceed: {
    message: 'registration.proceedToGate',
    style: '#198A1F',
    icon: mdiCheckCircle,
    value: 100,
  },
  error: {
    message: 'registration.goToGatehouse',
    style: '#DF2626',
    icon: mdiCloseCircle,
    value: 67,
  },
};
export const useGetDetails = () => {
  const { apiClient } = useApiClientContext();
  const { workspaceId } = useParams()


  const putFinalStep = useCallback(
    async (data: any) => {
      return await apiClient.put(
        `/workspaces/${workspaceId}/orders/complete-scb-order`,
        data,
      );
    },
    [apiClient]
  );
  // /workspaces/83/orders/1133/unloading-gate-locations


  return {

    putFinalStep,
  };
};
const useSiteById = (language: string) => {
  const { apiClient } = useApiClientContext();
  const { workspaceId } = useParams();
  const { data, error, isValidating, isLoading, mutate } =
    useSWR<FileDownloadResInterface>(
      `/workspaces/${workspaceId}/single-site-map${language ? `?language=${language}` : ""}`,
      getFetcher(apiClient),
      {
        revalidateOnFocus: false, // Disable revalidation on focus
      }
    );

  const refetchSafetyDocument = useCallback(() => mutate(), [mutate]);
  return {
    dataCheck: data,
    isLoading,
    refetchSafetyDocument,
    isError: error,
    isValidating,
  };
};
export const RegistrationStatusV2 = () => {
  // const location = useLocation();
  // const { state } = location;
  const navigate = useNavigate()
  const { apiClient } = useApiClientContext();
  const { joinKey, workspaceId, orderId = "", ln = "" } = useParams();
  const [transportDetails, setTransportDetails] = useState<any>()
  const [loading, setLoading] = useState(false)
  const [status] = useState<"waiting" | "proceed" | "error">('proceed');
  const { dataCheck } = useSiteById(ln)
  const [tasksState, setTasksState] = useState<any>([])
  const { putFinalStep } = useGetDetails()

  const getFinalDeliveryDone = () => {
    setLoading(true)

    putFinalStep({
      orderId: parseInt(orderId),
    }).then(() => {
      setLoading(false)
      navigate(`/REGISTRATIONSUCCESS/join/${joinKey}/id/${workspaceId}/order/${orderId}/language/${ln}/final`)
    }).catch(() => {
      setLoading(false)
    })
  }

  const { data } =
    useSWR(
      `/workspaces/${workspaceId}/orders/${orderId}/unloading-gate-locations`,
      getFetcher(apiClient),
      {
        revalidateOnFocus: false, // Disable revalidation on focus
      }
    );
  useEffect(() => {
    if (data) {
      i18next.changeLanguage(ln)
      setTasksState(data)
      setTransportDetails(data)
    }
  }, [data])
  const { t } = useTranslation();

  const currentStatus = statusDetails[status];

  return (
    <RegisterPageLayout isHeader={true}>

      <div style={{ justifyItems: 'center' }}>
        <StyledTextWrapper>
          <StyledTextWrapperPage2>
            <RegisterHeader transportDetails={transportDetails} />
          </StyledTextWrapperPage2>
          {currentStatus && (
            <ProgressBox>
              <StyledStatusMessage status={status}>
                <span>{t(currentStatus.message)}</span>
              </StyledStatusMessage>
              <Box display="flex" alignItems="center" gap={1} justifyContent="center" marginTop='12px'>
                <BorderLinearProgress variant="determinate" value={currentStatus.value} status={status} />
                <Icon path={currentStatus.icon} size={0.95} color={currentStatus.style} />
              </Box>
            </ProgressBox>
          )}
          <LinkWrapper>
            <SiteMapLink onClick={() => {
              window.open(dataCheck?.siteMapUrl)
            }}>Site Map </SiteMapLink> <DownloadText>{t(`registration.download`)}</DownloadText>
          </LinkWrapper>
        </StyledTextWrapper>
        <MapStopsV2 tasksState={tasksState} getFinalDeliveryDone={getFinalDeliveryDone} loading={loading} />
      </div>
    </RegisterPageLayout>
  );
};
