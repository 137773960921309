import { Button, Checkbox, CircularProgress, colors, DialogContent, FormControlLabel, Grid, MenuItem, TextField } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
	ContactRoleEnum,
	deeplSupportedLanguages,
	googleSupportedLanguages,
	TranslationServiceEnum,
} from '@heylog-app/shared/types';
import { getRoleIcon, phoneRegex, roles } from '@heylog-app/shared/util';

import { StyledDialogActions, StyledRoleIcon } from './edit-contact-form.styles';
import { useContactActions, useWorkspace } from '../../../hooks';
import { formatPhoneNumberForDisplay, formatPhoneNumberHide, parseAxiosErrorMessage, preventSubmitOnEnter } from '../../../util';
import { ButtonV2, ControlledSelect } from '../../ui';

import type {
	UpdateContactReqInterfaceV2,
} from '@heylog-app/shared/types';
import type { Control, FieldValues } from 'react-hook-form';
import type { FC } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

interface DisplayFormData extends UpdateContactReqInterfaceV2 {
	phone?: string;
}

type FormData = DisplayFormData;

type EditContactFormProps = {
	contact?: UpdateContactReqInterfaceV2 | undefined; // provide ID if editing a contact
	onSuccess: () => void;
	onCancel: () => void;
	openSnackbar: any;
};
const schema = yup.object().shape({
	firstName: yup
		.string()
		.trim()
		.nullable()
		.min(2, 'First name must be at least 2 characters')
		.max(50, 'First name must be at most 50 characters')
		.matches(/^[A-Za-z\s'-]+$/, 'First name can only contain letters, spaces, hyphens, and apostrophes'),
	lastName: yup
		.string()
		.trim()
		.nullable()
		.min(2, 'Last name must be at least 2 characters')
		.max(50, 'Last name must be at most 50 characters')
		.matches(/^[A-Za-z\s'-]+$/, 'Last name can only contain letters, spaces, hyphens, and apostrophes'),

	company: yup
		.string()
		.trim()
		.nullable()
		.max(100, 'Company name must be at most 100 characters'),

	email: yup
		.string()
		.trim()
		.nullable()
		.matches(
			/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/,
			'Invalid email format'
		)
		.max(100, 'Email must be at most 100 characters'),

});
export const EditContactFormV2: FC<EditContactFormProps> = ({
	contact,
	onSuccess,
	onCancel,
	openSnackbar,
}) => {
	const { workspaceId = '' } = useParams();
	const contactId = contact?.contactId?.toString() ?? '';
	const { updateContact, updateContactLoading } = useContactActions(contactId);
	const { workspace } = useWorkspace(workspaceId);

	const { t } = useTranslation();

	const supportedLanguages =
		workspace?.translationService === TranslationServiceEnum.DEEPL
			? deeplSupportedLanguages
			: googleSupportedLanguages;

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
		watch,
	} = useForm<FormData>({
		defaultValues: {
			fullName: contact?.fullName || '',
			firstName: contact?.firstName || '',
			lastName: contact?.lastName || '',
			company: contact?.company || '',
			phone: contact?.contactNumber || '',
			email: contact?.email || '',
			preferredLanguage: contact?.preferredLanguage || '',
			isPhoneHide: true,
		},
		resolver: yupResolver(schema),

	});

	useEffect(() => {
		if (contact) {
			reset({
				role: contact.role || ContactRoleEnum.NONE,
				preferredLanguage: contact.preferredLanguage,
			});
		}
	}, [contact, reset]);

	const onSubmit = async (data: FormData) => {
		if (contact) {
			try {
				const res = await updateContact({
					id: contactId,
					data: {
						...data,
						email: data.email?.toLowerCase(),
						isPhoneHide: true,

					},
					workspaceId,
				});
				if (res) {
					onSuccess();
				}
			} catch (err) {
				openSnackbar('error', parseAxiosErrorMessage(err));
			}
		}
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)} onKeyDown={preventSubmitOnEnter} noValidate>
				<DialogContent sx={{ padding: '16px 25px 53px 25px' }}>
					<Grid container columnSpacing={'30px'}>
						<Grid item xs={6}>
							<Controller
								control={control}
								name="firstName"
								render={({ field }) => (
									<div>
										<TextField
											sx={{
												'& .MuiInputLabel-root': {
													color: 'var(--Primary-Light-300, #A3AAE6)',
												},
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid var(--White-300, #E1E4EA) !important',
												},
											}}
											data-test="edit-contact-first-name"
											label={t('contacts.form.labels.firstName')}
											fullWidth
											margin="dense"
											{...register('firstName')}
											{...field}
										/>
										<p style={{ color: "red", fontSize: "18px" }}>{errors.firstName?.message}</p>
									</div>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<Controller
								control={control}
								name="lastName"
								render={({ field }) => (
									<div>

										<TextField
											label={t('contacts.form.labels.lastName')}
											data-test="edit-contact-last-name"
											fullWidth
											sx={{
												'& .MuiInputLabel-root': {
													color: 'var(--Primary-Light-300, #A3AAE6)',
												},
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid var(--White-300, #E1E4EA) !important',
												},
											}}
											margin="dense"
											{...register('lastName')}
											{...field}
										/>
										<p style={{ color: "red", fontSize: "12px" }}>{errors.lastName?.message}</p>
									</div>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<ControlledSelect
								sx={{
									'& .MuiOutlinedInput-notchedOutline': {
										border: '1px solid var(--White-300, #E1E4EA) !important',
									},
								}}
								inputLabelSx={{
									color: 'var(--Primary-Light-300, #A3AAE6) !important',
									'&.Mui-focused': { color: 'var(--Primary-Light-300, #A3AAE6) !important' },
								}}
								id="preferredLanguage"
								label="contacts.form.labels.preferredLanguage"
								data-test="edit-contact-preferred-language"
								control={control as unknown as Control<FieldValues>}
							>
								{supportedLanguages.map((language) => (
									<MenuItem
										data-test={'preferredLangOption-' + language}
										key={'preferredLangOption-' + language}
										value={language}
									>
										{t(`languages.${language}`)}
									</MenuItem>
								))}
							</ControlledSelect>
						</Grid>
						<Grid item xs={6}>
							<Controller
								control={control}
								name="company"
								render={({ field }) => (
									<div>

										<TextField
											sx={{
												'& .MuiInputLabel-root': {
													color: 'var(--Primary-Light-300, #A3AAE6)',
												},
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid var(--White-300, #E1E4EA) !important',
												},
											}}
											label={t('contacts.form.labels.company')}
											data-test="edit-contact-company"
											fullWidth
											margin="dense"
											{...register('company')}
											{...field}
										/>
										<p style={{ color: "red", fontSize: "12px" }}>{errors.company?.message}</p>
									</div>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<Controller
								control={control}
								name="email"
								render={({ field }) => (
									<div>

										<TextField
											sx={{
												'& .MuiInputLabel-root': {
													color: 'var(--Primary-Light-300, #A3AAE6)',
												},
												'& .MuiOutlinedInput-notchedOutline': {
													border: '1px solid var(--White-300, #E1E4EA) !important',
												},
											}}
											label={t('contacts.form.labels.email')}
											data-test="edit-contact-email"
											fullWidth
											margin="dense"
											{...register('email')}
											{...field}
										/>
										<p style={{ color: "red", fontSize: "12px" }}> {errors.email?.message}</p>
									</div>

								)}
							/>

						</Grid>
						<Grid item xs={6}>
							<ControlledSelect
								id="role"
								sx={{
									'& .MuiOutlinedInput-notchedOutline': {
										border: '1px solid var(--White-300, #E1E4EA) !important',
									},
								}}
								inputLabelSx={{
									color: 'var(--Primary-Light-300, #A3AAE6) !important',
									'&.Mui-focused': { color: 'var(--Primary-Light-300, #A3AAE6) !important' },
								}}
								label="contacts.form.labels.role"
								data-test="edit-contact-role"
								control={control as unknown as Control<FieldValues>}
							>
								{roles.map((role) => {
									const roleIcon = getRoleIcon(role.value);
									return (
										<MenuItem
											data-test={'roleOption-' + role.value}
											key={'roleOption-' + role.value}
											value={role.value}
										>
											{roleIcon && (
												<StyledRoleIcon
													path={roleIcon}
													size={'1em'}
													color={colors.common.black}
												/>
											)}
											{t(`roles.${role.value}`)}
										</MenuItem>
									);
								})}
							</ControlledSelect>
						</Grid>
						<Grid item xs={6} sx={{ margin: '8px 0px 4px' }}>
							<Controller
								control={control}
								name="phone"
								render={({ field }) => (
									<TextField
										label={t('contacts.form.labels.phone')}
										fullWidth
										sx={{
											'& .MuiOutlinedInput-notchedOutline': {
												border: '1px solid var(--White-300, #E1E4EA) !important',
											},
											'& .MuiInputLabel-root': {
												color: 'var(--Primary-Light-300, #A3AAE6)',
											},
											borderRadius: '4px',
											'& .MuiOutlinedInput-root': {
												borderRadius: '4px',
											},
										}}
										placeholder={'+43660...'}
										error={errors.phone ? true : false}
										helperText={errors.phone?.message && t(errors.phone?.message)}
										{...register('phone', {
											pattern: phoneRegex,
											disabled: true,
											validate: (value) => {
												// prevent deleting phone number if there has been one to avoid errors in conversations
												if (contact?.contactNumber && value?.length === 0) {
													return 'forms.errors.required';
												}
												return true;
											},
										})}
										{...field}
										value={formatPhoneNumberHide(field.value)}
									/>
								)}
							/>
							{/* <Controller
								control={control}
								name="isPhoneHide"
								defaultValue={false}
								render={({ field }) => (
									<FormControlLabel
										control={
											<Checkbox
												{...field}
												checked={field.value}
												color="primary"
											/>
										}
										label={t('contacts.form.labels.hideMobileNumber')}
									/>
								)}
							/> */}
						</Grid>
					</Grid>
				</DialogContent>

				<StyledDialogActions>
					<Grid>
						<ButtonV2 $variant="outlined2" disabled={updateContactLoading} type="reset" onClick={onCancel}>
							{t('actionLabels.cancel')}
						</ButtonV2>
						<ButtonV2 $variant="dark" startIcon={updateContactLoading && <CircularProgress size={20} />} data-test="confirm-edit-contact-button" variant="contained" type="submit">
							{t('actionLabels.save')}
						</ButtonV2>
					</Grid>
				</StyledDialogActions>
			</form>
		</>
	);
};
