import Icon from "@mdi/react";
import styled from "styled-components";

import { STYLE_3, SIZE_MD, STYLE_2, SIZE_XS } from "../../../styles";
import { Chip } from "@mui/material";

export const StyledContactName = styled.div`
  ${STYLE_3};
  ${SIZE_MD};
  white-space: nowrap;
  overflow: hidden;

  text-overflow: ellipsis;

  color: var(--White-Black, #000);

font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: -0.1px;
`;
export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledContactDetail = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  ${STYLE_2};
  ${SIZE_XS};
`;

export const StyledContactLabel = styled.span`
  ${STYLE_2};
  ${SIZE_XS};
  margin-top: 0.25rem;
  color: var(--White-600, #7c839e);

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.1px;
`;

export const StyledContactDetailIcon = styled(Icon)`
  margin-right: 6px;
  margin-bottom: -2px;
`;
export const StyledLinkedOrderChip = styled(Chip)(({ theme }) => ({
  borderRadius: "4px",
  border: `0px`,
  backgroundColor: "var(--White-300, #E1E4EA)",
  color: "var(--Primary-Darker, #030B5E)",
  "& .MuiChip-deleteIcon": {
    color: "#7C839E",
  },
}));

export const StyledP = styled.p`
color: var(--Primary-Light-300, #A3AAE6);

font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: -0.1px;`;

export const StyledText = styled.div`
  color: var(--White-600, #7C839E);

  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: -0.1px;
`;