import React, { useCallback } from "react";
import {
    StyledTextWrapper,
    StyledForm,
    StyledInput,
} from '../registrationform.styles';
import { Controller, UseFormReturn } from 'react-hook-form';

import { useTranslation } from "react-i18next";
import { DetailsHeader } from "../components/DetailsHeader";
import { TaskFormData } from "../../Types";
import { useMultiStep } from "../MultiStepProvider";

interface SecondStepProps {
    formMethods: UseFormReturn<TaskFormData>;
}

const inputFields: {
    name: keyof TaskFormData["step2"];
    errorMessage: string;
    pattern?: { value: RegExp; message: string };
    inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}[] = [
        { name: "tractorPlateNumber", errorMessage: "Tractor Plate Number is required" },
        { name: "trailerPlateNumber", errorMessage: "Trailer Plate Number is required" },
        {
            name: "driverName",
            errorMessage: "Driver Name is required",
            pattern: {
                value: /^[\p{L}\p{M}' -]+$/u,
                message: "Only letters, spaces, apostrophes, and hyphens are allowed",
            },
            inputProps: { maxLength: 50 },
        },
        {
            name: "phoneNumber",
            errorMessage: "Phone number is required",
            pattern: {
                value: /^[\d+\-() ]+$/,
                message: "Only numbers, '+', '(', ')', '-' and spaces are allowed",
            },
            inputProps: { maxLength: 15 },
        },
    ];

export const SecondStep: React.FC<SecondStepProps> = ({ formMethods }) => {
    const { t } = useTranslation();
    const { transportDetails } = useMultiStep();
    const {
        control,
        formState: { errors },
        reset,
    } = formMethods;

    const onFocusPhoneField = useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
            if (event.target.value === '') {
                reset((prevValues) => ({
                    ...prevValues,
                    step2: {
                        ...prevValues.step2,
                        phoneNumber: '+'
                    }
                }), { keepErrors: true, keepDirty: true });
            }
        },
        [reset]
    );

    return (
        <StyledTextWrapper style={{ marginBottom: '5.7rem' }}>
            <DetailsHeader note='registration.completeRegistration' transportDetails={transportDetails} />
            <StyledForm>
                {inputFields.map(({ name, errorMessage, pattern, inputProps }) => (
                    <Controller
                        key={name}
                        name={`step2.${name}`}
                        control={control}
                        rules={{ required: errorMessage, pattern }}
                        render={({ field }) => (
                            <StyledInput
                                {...field}
                                placeholder={t(`registration.${name}`)}
                                error={!!errors.step2?.[name]}
                                hasValue={!!field.value}
                                {...(inputProps && { inputProps })}
                                {...(name === "phoneNumber" && { onFocus: onFocusPhoneField })}
                                autoComplete="off"
                            />
                        )}
                    />
                ))}
            </StyledForm>
        </StyledTextWrapper>
    );
};