import { ListItem, ListItemButton } from '@mui/material';
import styled, { css } from 'styled-components';

import { colors } from '../../../styles/theme/colors';

type StyledListItemProps = {
	$active?: boolean;
};

export const StyledListItem = styled(ListItem)<StyledListItemProps>`
	margin: 0;
	padding: 0;

	background-color: rgb(255 255 255 / 0.5);
	border-bottom: 1px solid ${colors.grey[300]};

	${({ $active }) =>
		$active &&
		css`
			background-color: ${({ theme }) => theme.palette.primary.lighter};
		`}
`;

export const StyledListItemButton = styled(ListItemButton)<StyledListItemProps>`
	padding: 14.2px 10px 0px 10px;
	color: ${colors.grey[900]};
`;

export const StyledListItemV2 = styled(ListItem)<StyledListItemProps>`
	margin: 0;
	padding: 0;

	background-color: rgb(255 255 255 / 0.5);
`;

export const StyledListItemButtonV2 = styled(ListItemButton)<StyledListItemProps>`
	margin-bottom: 15px;
	color: ${colors.grey[900]};
	border-radius: 4px;
	${({ $active }) =>
		$active &&
		css`
			background-color: #F5F8FE;
		`}
`;

