import { css } from "styled-components";

export const typography = {
  fontFamily: ["Eudoxus Sans", "sans-serif"],
};

export const H1 = css`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: -0.4px;
`;

export const H2 = css`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.3px;
`;

export const H3 = css`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.2px;
`;

export const H4 = css`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.1px;
`;

export const T1 = css`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.4px;
`;

export const T2 = css`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
`;

export const T3 = css`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
`;

export const T4 = css`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.1px;
`;

export const T1_medium = css`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.4px;
`;

export const T2_medium = css`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;
`;

export const T3_medium = css`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
`;

export const T4_medium = css`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.1px;
`;

export const T5 = css`
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.1px;
`;

export const medium_T1 = css`
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.4px;
`;
