import type { FC } from 'react';

export const SvgUK: FC = () => (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2301_3125)">
            <path d="M0.595001 0.657227H16.405V12.3429H0.595001V0.657227Z" fill="#012169" />
            <path d="M2.44774 0.657227L8.4753 5.06369L14.4782 0.657227H16.405V2.16662L10.4763 6.5244L16.405 10.8578V12.3429H14.4288L8.5 7.9851L2.59595 12.3429H0.595001V10.8822L6.49905 6.54874L0.595001 2.21531V0.657227H2.44774Z" fill="white" />
            <path d="M11.0691 7.4982L16.405 11.3691V12.3429L9.71045 7.4982H11.0691ZM6.52375 7.9851L6.67197 8.83718L1.92897 12.3429H0.595001L6.52375 7.9851ZM16.405 0.657227V0.730262L10.2539 5.30714L10.3033 4.23596L15.1698 0.657227H16.405ZM0.595001 0.657227L6.49905 4.94197H5.01686L0.595001 1.67972V0.657227Z" fill="#C8102E" />
            <path d="M6.54845 0.657227V12.3429H10.501V0.657227H6.54845ZM0.595001 4.55244V8.44766H16.405V4.55244H0.595001Z" fill="white" />
            <path d="M0.595001 5.35583V7.69296H16.405V5.35583H0.595001ZM7.33895 0.657227V12.3429H9.71045V0.657227H7.33895Z" fill="#C8102E" />
        </g>
        <defs>
            <clipPath id="clip0_2301_3125">
                <rect x="0.595001" y="0.657227" width="15.81" height="11.6857" rx="1.37478" fill="white" />
            </clipPath>
        </defs>
    </svg>

);
