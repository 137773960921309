import React, { useEffect } from "react";
import { Container, StyledContainer, StyledTypoV1, StyledTypoV2 } from "./form.styles";
import { DetailsHeader } from "../components/DetailsHeader";
import { PdfUrlViewer } from "@heylog-app/frontend-lib/app";
import { useTranslation } from "react-i18next";
import { StyledPdfContainer } from "../registrationform.styles";
import { useMultiStep } from "../MultiStepProvider";
import { Controller } from "react-hook-form";

export const SafetyInstructions: React.FC = () => {
    const { t } = useTranslation();
    const { formMethods: { control, setValue }, transportDetails } = useMultiStep();

    return (
        <Container>
            <DetailsHeader note="registration.readSafetyInstructions" transportDetails={transportDetails} />
            <StyledContainer >
                <StyledTypoV1>SCB YARD</StyledTypoV1>
                <StyledTypoV2>
                    {t(`registration.safetyInstructions`)}
                </StyledTypoV2>
                <StyledTypoV1>
                    {t(`registration.lastUpdated`)} am 5.12.2022
                </StyledTypoV1>
            </StyledContainer>
            <StyledPdfContainer>
                <Controller
                    name="step4.hasScrolled"
                    control={control}
                    render={({ field }) => (
                        <PdfUrlViewer setValue={setValue} transportDetails={transportDetails}
                        />
                    )}
                />
            </StyledPdfContainer>
        </Container>
    );
};
