import React, { useState, useCallback } from "react";
import { Document, pdfjs } from "react-pdf";
import { PdfViewer } from "./PdfViewer";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { useParams } from "react-router-dom";
import { useApiClientContext } from "../../hooks";
import useSWR from "swr";
import type { FileDownloadResInterface } from "@heylog-app/shared/types";
import { getFetcher } from "../../util";
import { CircularProgress } from "@mui/material";
import type { UseFormSetValue } from "react-hook-form";

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
 
const useSafetyById = (language: string) => {
  const { apiClient } = useApiClientContext();
  const { workspaceId } = useParams();
  const { data, error, isValidating, isLoading, mutate } =
    useSWR<FileDownloadResInterface>(
      `/workspaces/${workspaceId}/single-safety-instruction${language ? `?language=${language}` : ""}`,
      getFetcher(apiClient),
      {
        revalidateOnFocus: false, // Disable revalidation on focus
      }
    );
 
  const refetchSafetyDocument = useCallback(() => mutate(), [mutate]);
  return {
    dataCheck: data,
    isLoading,
    refetchSafetyDocument,
    isError: error,
    isValidating,
  };
};

interface ChildProps {
  setValue: UseFormSetValue<any>;
  transportDetails: any;
}

export const PdfUrlViewer: React.FC<ChildProps> = ({ transportDetails, setValue }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const { dataCheck } = useSafetyById(transportDetails?.language)
  const onDocumentLoadSuccess = useCallback((pdf: PDFDocumentProxy) => {
    console.log("pdf", pdf)
    setNumPages(pdf.numPages);
  }, []);
 
  return (
    dataCheck?.safetyInstructionUrl ? (
      <Document
        file={dataCheck?.safetyInstructionUrl}
        onLoadSuccess={onDocumentLoadSuccess}>
        <PdfViewer itemCount={numPages} setValue={setValue} />
      </Document>
    ) : (
      <div style={{ border: '1px solid #D9D9D9', height: '18rem', width: '100%', borderRadius: '10px', alignContent: 'center' }}>
        <CircularProgress />
      </div>
    ))
};
