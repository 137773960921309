import React from "react";
import {
    StyledDiv1,
    StyledDetails,
    StyledTextWrapperPage2,
    StyledDiv4,
} from '../registrationform.styles';
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";

export const RegisterHeader = ({ transportDetails }: any) => {
    const { t } = useTranslation();

    const date = new Date(transportDetails?.timeSlot * 1000);
    const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month (0-based index, pad with zero)
    const day = date.getDate().toString().padStart(2, "0"); // Get day, pad with zero
    const hour = date.getHours().toString().padStart(2, "0"); // Get hour, pad with zero
    const minute = date.getMinutes().toString().padStart(2, "0"); // Get minutes, pad with zero

    return (<StyledTextWrapperPage2>
        <StyledDiv1>{t('registration.thankyou')}</StyledDiv1>
        <StyledDiv4>
            {t(`registration.stayOnScreen`)}
        </StyledDiv4>
        <StyledDetails>
            <Typography className="header">
                {t("registration.yourDetails")}
            </Typography>

            <Stack sx={{ margin: "0.7rem 0rem" }}>
                <div className="detail-row">
                    <Typography component="span">{t("registration.transportNumber")}</Typography>
                    <Typography component="p">: &nbsp;{transportDetails?.transportNumber || transportDetails?.ref_number}</Typography>
                </div>

                <div className="detail-row">
                    <Typography component="span">{t("registration.timeSlot")}</Typography>
                    <Typography component="p">&nbsp;{`${year}-${month}-${day} ${hour}:${minute}`}</Typography>
                </div>
            </Stack>
        </StyledDetails>
    </StyledTextWrapperPage2>);
};
