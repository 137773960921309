import { HeylogPluginEnum } from '@heylog-app/shared/types';

import { useWorkspaceById } from './use-workspace.hook';

export const usePlugins = (workspaceId: string) => {
	const { workspace } = useWorkspaceById(workspaceId);

	const TimeMattersOSUPlugin = workspace?.pluginsEnabled.some(
		(plugin) => plugin.plugin === HeylogPluginEnum.TimeMattersOSUPlugin,
	);
	const SCBPlugin = workspace?.pluginsEnabled.some(
		(plugin) => plugin.plugin === HeylogPluginEnum.SCBPlugin,
	);
	const ProofOfDeliveryPlugin = workspace?.pluginsEnabled.some(
		(plugin) => plugin.plugin === HeylogPluginEnum.ProofOfDeliveryPlugin,
	);
	const OSUReminderPlugin = workspace?.pluginsEnabled.some(
		(plugin) => plugin.plugin === HeylogPluginEnum.OSUReminderPlugin,
	);
	const OSUPlugin = workspace?.pluginsEnabled.some(
		(plugin) => plugin.plugin === HeylogPluginEnum.OSUPlugin,
	);
	const FleetCheckPlugin = workspace?.pluginsEnabled.some(
		(plugin) => plugin.plugin === HeylogPluginEnum.FleetCheckPlugin,
	);
	const FleetCheckReportPlugin = workspace?.pluginsEnabled.some(
		(plugin) => plugin.plugin === HeylogPluginEnum.FleetCheckReportPlugin,
	);
	const FrontendV2Plugin = workspace?.pluginsEnabled.some(
		(plugin) => plugin.plugin === HeylogPluginEnum.FrontendV2Plugin,
	);
	const DemoPlugin = workspace?.pluginsEnabled.some(
		(plugin) => plugin.plugin === HeylogPluginEnum.DemoPlugin,
	);

	return {
		DemoPlugin,
		FleetCheckPlugin,
		FleetCheckReportPlugin,
		FrontendV2Plugin,
		OSUPlugin,
		OSUReminderPlugin,
		ProofOfDeliveryPlugin,
		TimeMattersOSUPlugin,
		SCBPlugin,
	};
};
