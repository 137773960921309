import React, { Dispatch, SetStateAction } from 'react';
import { Box, Grid, InputAdornment, Stack } from '@mui/material';
import { ButtonsGridContainer, StyledAvatar, StyledChooseOrder, StyledDecoration, StyledDetailsTitle, StyledDialog, StyledDiv, StyledMessageAuthorText, StyledMessageGroup } from './conversations.styles';
import { useTranslation } from 'react-i18next';
import { ButtonV2, getNameInitials, MessageBubble, MessageInputV2, ROUTES, useUser } from '@heylog-app/frontend-lib/app';
import { staticMockedMessage } from 'libs/frontend-lib/app/src/components/forms/add-contact-form/static-mocked-message';
import { AddContacts } from './add-contacts';
import { generatePath, Navigate, useNavigate, useParams } from 'react-router-dom';

interface Contact {
    id: string;
    name: string;
    email: string;
    avatarUrl?: string;
}
interface NewConversationFormProps {
    setSelectedOrderId: (id: string | null) => void;
    message: string;
    setMessage: Dispatch<SetStateAction<string>>;
    selectedContacts: Contact[];
    setSelectedContacts: (contacts: Contact[]) => void;
    onSubmit?: any;
}

export const NewConversationForm: React.FC<NewConversationFormProps> = ({
    setSelectedOrderId,
    message,
    setMessage,
    selectedContacts,
    setSelectedContacts,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { user } = useUser();
    const params = useParams();
    return (
        <Box sx={{ bgcolor: 'var(--White-200, #F5F8FE)', mt: 2 }}>
            <Box sx={{ mb: 2, borderRadius: 1, mt: 4.5 }}>
                <Grid container rowSpacing={2}>
                    <AddContacts selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts} />
                    <StyledDecoration />
                    <Grid item xs={12} sx={{ p: 2 }}>
                        <StyledChooseOrder
                            inputProps={{
                                variant: 'outlined',
                                hasLabel: false,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {t('email.form.linkToOrder')}:
                                    </InputAdornment>
                                ),
                            }}
                            width="100%"
                            handleAssign={({ orderId }) => setSelectedOrderId(orderId)}
                        />
                    </Grid>
                </Grid>
            </Box>

            <StyledDialog>
                <StyledDiv>{t('contacts.HeylogChatPreview')}</StyledDiv>
                <Grid container spacing={1} style={{ marginTop: '25px' }}>
                    <Grid item>
                        <StyledAvatar sx={{ width: '80', height: '80', fontSize: '1rem' }} src={user?.profilePicturePath || ""}>
                            {!user?.profilePicturePath && getNameInitials(user?.firstName, user?.lastName)}
                        </StyledAvatar>
                    </Grid>
                    <Grid item xs={10}>
                        <StyledMessageGroup>
                            <StyledMessageAuthorText>{t('contacts.Heylog')}</StyledMessageAuthorText>
                        </StyledMessageGroup>
                        <StyledMessageGroup>
                            <MessageBubble
                                bgCustomColor={true}
                                borderRadius='0px 16px 16px 16px'
                                message={{ ...staticMockedMessage, text: message }}
                            />
                        </StyledMessageGroup>
                    </Grid>
                </Grid>
            </StyledDialog>

            <MessageInputV2
                onSubmit={onSubmit}
                message={message}
                setMessage={setMessage}
                showAttachment={false}
                showTranslate={false}
            />
            <Stack flexDirection="row" justifyContent="end" marginTop={2}>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <ButtonV2 $variant="outlined2" onClick={() => { navigate(generatePath(ROUTES.MESSAGES.OVERVIEW, params)) }} >
                        {t('actionLabels.cancel')}
                    </ButtonV2>
                    <ButtonV2 $variant="dark" onClick={onSubmit} style={{
                        borderRadius: '4px',
                    }}>
                        {t('actionLabels.send')}
                    </ButtonV2>
                </div>
            </Stack>
        </Box>
    );
};
