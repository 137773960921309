import React from 'react';

const SafetyIcon = (props: any) => {
    return (
        <div>
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M17.5918 13.568C17.7279 13.949 17.6463 14.4116 17.4014 14.7109C17.2109 14.9286 15.415 16.8061 9.83673 16.8061C4.23129 16.8061 2.43537 14.9286 2.2449 14.7109C2 14.4116 1.91837 13.949 2.05442 13.568C2.21769 13.1599 2.59864 12.8878 3.03401 12.8878H2.84354V11.5816C2.84354 8.5068 4.85714 5.89456 7.65986 4.9966V4.83333C7.65986 4.23469 8.12245 3.7449 8.72109 3.7449H10.9252C11.5238 3.7449 12.0136 4.23469 12.0136 4.83333V4.9966C14.7891 5.89456 16.8027 8.5068 16.8027 11.5816V12.8878H16.6122C17.0476 12.8878 17.4286 13.1599 17.5918 13.568ZM4.14966 11.5816V12.8878H15.4966V11.5816C15.4966 9.2415 14.0272 7.2551 11.9864 6.38435V11.3639C11.9864 11.7449 11.7143 12.017 11.3333 12.017C10.9796 12.017 10.6803 11.7449 10.6803 11.3639V5.05102H8.93877V11.3639C8.93877 11.7449 8.66667 12.017 8.28571 12.017C7.93197 12.017 7.63265 11.7449 7.63265 11.3639V6.38435C5.61905 7.2551 4.14966 9.2415 4.14966 11.5816ZM9.83673 15.5C13.4014 15.5 15.1973 14.6837 15.9592 14.1939H3.68707C4.44898 14.6837 6.2449 15.5 9.83673 15.5Z" fill="currentColor" />
            </svg>

        </div>
    );
}

export default SafetyIcon;
