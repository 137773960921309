import { T3, T3_medium } from "@heylog-app/frontend-lib/app";
import styled from "styled-components";

export const Container = styled.div`
  max-height: 17.5rem;
  width: 20.5rem;
  margin-bottom: 1rem;
  justify-self: center;
  overflow-y: auto;
  padding: 10px;
  border-radius: 8px !important;
  justify-items: center;

  ::-webkit-scrollbar {
    width: 10px;
    background: white !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #122cc5;
    border: 0px !important;
    background-clip: padding-box;
  }
  ::-webkit-scrollbar-track {
    padding-block: 4px;
    background-color: white;
  }
`;

export const StopContainer = styled.div`
  margin-bottom: 1.25rem;
  width: 100%;
`;

export const MapContainer = styled.div`
  height: 13rem;
  width: 100%;
  margin-top: 5px;
  overflow: hidden;
`;

export const Title = styled.div`
  ${T3_medium};
  color: var(--Primary-Main, #122cc5);
`;

export const Stop = styled.span`
  ${T3};
  color: var(--Primary-Light-600, #4960ba);
`;
