import { mdiAccount, mdiAccountPlusOutline, mdiEmailOutline } from '@mdi/js';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Icon from '@mdi/react';

import {
	parseAxiosErrorMessage,
	FlashSnackbar,
	useSnackbar,
	useUser,
	useUsers,
	useUserInvitations,
	useWorkspace,
	useFormWrapper,
	useApiClientContext,
	getNameInitials,
	InviteUserDialog,
	useDialog,
	ConfirmArchiveDialog,
	archiveUserInvitationAPI,
	ButtonV2,
	useWorkspaceById,
	useEnvContext,
} from '@heylog-app/frontend-lib/app';

import {
	StyledHeaderTitle,
	StyledSettingsPageLayout,
	StyledSettingsContentWrapper,
	StyledSettingsHeader,
	StyledSettingsTitle,
	StyledMember,
	StyledMemberName,
	StyledMemberEmail,
	StyledEmailIcon,
	PendingUserInvitations,
	MembersStyledButton,
	StyledTyphographyHeader,
	StyledAvatar,
	StyledUserInfoBlock,
	StyledOverflowMenu,
	StyledFooterWrapper,
	StyledFooterContent,
	StyledFooterContent2,
	StyledSettingsTitle2,
	StyledBox,
	BoxLabel,
	BoxContent,
	StyledTextField,
} from './settings.styles';
import { StyledInactivePill } from './components/messenger-button/messenger-button.styles';
import { SettingsFooter } from './components';

import type {
	UserResInterface,
	WorkspaceInvitationUserRoleType,
} from '@heylog-app/shared/types';
import type { FC } from 'react';

type FormData = {
	name: string;
	showUserSignature: boolean;
};

type MembersSettingsPageProps = {
	role: WorkspaceInvitationUserRoleType;
};

export const MembersSettingsPage: FC<MembersSettingsPageProps> = ({ role }) => {
	const { t } = useTranslation();
	const { workspaceId } = useParams();
	const env = useEnvContext()
	const { user } = useUser();
	const { apiClient } = useApiClientContext();
	const { users } = useUsers(workspaceId ? workspaceId : '-1', user);
	const { workspace: dataSettings } = useWorkspaceById(workspaceId || '-1');
	const { userInvitations, fetchUserInvitations } = useUserInvitations(
		workspaceId ? workspaceId : '-1',
	);

	const [pendingInvitationsToDelete, setPendingInvitationsToDelete] = useState('');
	const pendingUserInvitations = userInvitations?.filter(
		(userInvitation) => userInvitation.status === 'PENDING',
	);
	const [workspaceUsers, setWorkspaceUsers] = useState(users);
	const [userToRemove, setUserToRemove] = useState<UserResInterface | undefined>(
		undefined,
	);
	const { workspace, updateWorkspace } = useWorkspace(
		workspaceId ? workspaceId : '-1',
		user,
	);
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const {
		showDialog: showInviteDialog,
		openDialog: openInviteDialog,
		closeDialog: closeInviteDialog,
	} = useDialog();

	const {
		showDialog: showConfirmArchiveDialog,
		openDialog: openConfirmArchiveDialog,
		closeDialog: closeConfirmArchiveDialog,
	} = useDialog();

	const {
		showDialog: showConfirmArchivePendingInvitationDialog,
		openDialog: openConfirmArchivePendingInvitationDialog,
		closeDialog: closeConfirmArchivePendingInvitationDialog,
	} = useDialog();

	const handleCloseConfirmArchiveDialog = () => {
		closeConfirmArchiveDialog();
		setUserToRemove(undefined);
	};

	// Deduplicate pending user invitations and remove the ones that are already workspace users
	let uniquePendingUserInvitations;
	if (pendingUserInvitations && users) {
		const acceptedUserEmailAddresses = users.map((user) => user.email);

		uniquePendingUserInvitations = pendingUserInvitations
			.filter(
				(user, index) =>
					pendingUserInvitations.findIndex(
						(invitation) => invitation.email === user.email,
					) === index,
			)
			.filter(
				(userInvitation) => !acceptedUserEmailAddresses.includes(userInvitation.email),
			)
			.filter((userInvitation) => userInvitation.role === role);
	}

	useEffect(() => {
		setWorkspaceUsers(users);
	}, [users]);

	const filteredUsers = workspaceUsers?.filter((user) => user.role === role);

	const { setValue, handleSubmit } = useFormWrapper<FormData>({
		defaultValues: {
			name: workspace?.name || '',
			showUserSignature: workspace?.showUserSignature || false,
		},
	});

	useEffect(() => {
		if (workspace) {
			setValue('showUserSignature', workspace.showUserSignature);
			setValue('name', workspace.name);
		}
	}, [workspaceId, setValue, workspace]);

	const onSubmit = async (data: FormData) => {
		try {
			await updateWorkspace(data);
			openSnackbar('success', t('settings.settingsSavedSuccessfully'));
		} catch (error) {
			openSnackbar(
				'error',
				parseAxiosErrorMessage(error, t('settings.form.errors.unknown')),
			);
		}
	};

	const onUserRemoveClick = (userId: number) => {
		openConfirmArchiveDialog();
		setUserToRemove(workspaceUsers?.find((workspaceUser) => userId === workspaceUser.id));
	};

	const handleUserRemove = async () => {
		if (!userToRemove) return;

		await apiClient
			.patch(`/workspaces/${workspaceId}/users/${userToRemove.id}/remove`)
			.then((res) => {
				setWorkspaceUsers(
					workspaceUsers?.filter((workspaceUser) => res.data.id !== workspaceUser.id),
				);
			})
			.catch((err) => {
				throw new Error(err);
			});

		closeConfirmArchiveDialog();
	};

	const handleInviteSuccess = () => {
		fetchUserInvitations();
	};

	const handleRemovePendingInviteClick = (email: string) => {
		setPendingInvitationsToDelete(email);
		openConfirmArchivePendingInvitationDialog();
	};

	const handleArchivePendingInvitations = async () => {
		if (!workspaceId || !pendingInvitationsToDelete) return;

		await archiveUserInvitationAPI(apiClient, {
			data: {
				email: pendingInvitationsToDelete,
			},
			workspaceId,
		})
			.then(() => {
				openSnackbar('success', t('settings.archiveInvitationDialog.success'));
				fetchUserInvitations();
				closeConfirmArchivePendingInvitationDialog();
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => setPendingInvitationsToDelete(''));
	};

	return (
		<>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

			<StyledSettingsPageLayout>
				<StyledTyphographyHeader>
					<StyledHeaderTitle>
						<StyledSettingsHeader paddingTop={role === 'CUSTOMER' ? '15px' : '0px'}>
							<StyledSettingsTitle>
								<Typography variant="h5">
									{t(`navigation.settingsMenu.${role}.title`)}
								</Typography>
							</StyledSettingsTitle>

							<ButtonV2
								data-test="invite-user-button"
								onClick={openInviteDialog}
								size="medium"
								$variant="light2"
								startIcon={<Icon path={mdiAccountPlusOutline} size={1} />}
							>
								{t(`navigation.settingsMenu.${role}.addMember`)}
							</ButtonV2>
						</StyledSettingsHeader>
					</StyledHeaderTitle>
				</StyledTyphographyHeader>

				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<StyledSettingsContentWrapper>
						{filteredUsers?.map((workspaceUser) => (
							<StyledMember key={workspaceUser.id}>
								<StyledAvatar src={workspaceUser.profilePicturePath} sx={{ width: 32, height: 32, fontSize: '0.9rem' }}>
									{workspaceUser.profilePicturePath && (getNameInitials(workspaceUser.firstName, workspaceUser.lastName) || (
										<Icon path={mdiAccount} size={'1rem'} />
									))}
								</StyledAvatar>
								<StyledUserInfoBlock>
									<StyledMemberName>
										{workspaceUser?.firstName} {workspaceUser?.lastName}{' '}
										{workspaceUser.id === user?.id ? t('settings.you') : null}
									</StyledMemberName>
									<StyledMemberEmail>
										<StyledEmailIcon path={mdiEmailOutline} size={'1em'} />
										{workspaceUser?.email}
									</StyledMemberEmail>
								</StyledUserInfoBlock>

								{workspaceUser.id !== user?.id && (
									<StyledOverflowMenu
										options={[{ label: t('actionLabels.remove'), action: 'delete' }]}
										handleDelete={onUserRemoveClick}
										userId={workspaceUser.id}
									/>
									// <StyledRemoveButton
									// 	variant="outlined"
									// 	onClick={() => onUserRemoveClick(workspaceUser.id)}
									// >
									// 	{t('actionLabels.remove')}
									// </StyledRemoveButton>
								)}
							</StyledMember>
						))}
						{uniquePendingUserInvitations?.map((userInvitation) => (
							<StyledMember key={userInvitation.id}>
								<StyledAvatar src="" sx={{ width: 32, height: 32, fontSize: '0.9rem' }}>
									{getNameInitials(userInvitation.email) || (
										<Icon path={mdiAccount} size={'1rem'} />
									)}
								</StyledAvatar>
								<StyledMemberName>{userInvitation?.email}</StyledMemberName>

								<PendingUserInvitations>
									<StyledInactivePill>{t('settings.pending')}</StyledInactivePill>

									<MembersStyledButton
										data-test="remove-pending-invitation-button"
										variant="outlined"
										onClick={() => handleRemovePendingInviteClick(userInvitation.email)}
									>
										{t('actionLabels.remove')}
									</MembersStyledButton>
								</PendingUserInvitations>
							</StyledMember>
						))}
					</StyledSettingsContentWrapper>
				</form>
				{role === "WORKSPACE_ADMIN" && <StyledFooterWrapper>
					<StyledFooterContent2>
						<StyledSettingsTitle2>
							Data Processing Agreement
						</StyledSettingsTitle2>
					</StyledFooterContent2>
					{/* <StyledTextField
						disabled
						label={"DPA"}
						value={"https://www.heylog.com/privacy-policy"}
					/> */}
					<StyledBox>
						<BoxLabel>DPA</BoxLabel>
						<BoxContent>
							<a href={dataSettings?.heylogPolicy ? dataSettings.filePath : `/workspaces/${workspaceId}/settings/dpa-public`} target="_blank" rel="noopener noreferrer">
								{dataSettings?.heylogPolicy ? dataSettings.filePath : `${env.APP_URL}workspaces/${workspaceId}/settings/dpa-public`}
							</a>
						</BoxContent>
					</StyledBox>
				</StyledFooterWrapper>}

				{role === 'CUSTOMER' && <SettingsFooter />}
			</StyledSettingsPageLayout>

			<ConfirmArchiveDialog
				isOpen={showConfirmArchiveDialog}
				onClose={handleCloseConfirmArchiveDialog}
				dialogTitle={t('settings.removeDialog.title')}
				dialogContent={t('settings.removeDialog.body', {
					userName: `${userToRemove?.firstName} ${userToRemove?.lastName}`,
					workspaceName: workspace?.name,
				})}
				onArchive={handleUserRemove}
			/>

			<ConfirmArchiveDialog
				dataTest="archive-pending-user-invitation-dialog"
				isOpen={showConfirmArchivePendingInvitationDialog}
				onClose={closeConfirmArchivePendingInvitationDialog}
				dialogTitle={t('settings.archiveInvitationDialog.title')}
				dialogContent={t('settings.archiveInvitationDialog.body')}
				onArchive={handleArchivePendingInvitations}
			/>

			<InviteUserDialog
				onClose={closeInviteDialog}
				isOpen={showInviteDialog}
				handleInviteSuccess={handleInviteSuccess}
				role={role}
			/>
		</>
	);
};
