import { PageFooterV2, PageHeader } from '../ui';
import { StyledPageContainer } from './register-page-layout.styles';

import type { FC, ReactNode } from 'react';

type LayoutProps = {
	isHeader?: boolean;
	children?: ReactNode;
};

export const RegisterPageLayout: FC<LayoutProps> = ({ children, isHeader = false }) => {
	return (
		<StyledPageContainer>
			<PageHeader isHeaderIcon={isHeader} />
			{children}
			<PageFooterV2 />
		</StyledPageContainer>
	);
};
