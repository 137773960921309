import { StyledContentContainer } from './sidebar-layout-content.styles';

import type { FC, ReactNode, RefObject } from 'react';

type SidebarLayoutContentProps = {
	variant?: 'primary' | 'secondary' | 'conversations' | 'newConversation';
	children: ReactNode;
	oarHeaderRef?: RefObject<HTMLDivElement>;
};

/** component for the main content of a page with sidebars */
export const SidebarLayoutContent: FC<SidebarLayoutContentProps> = ({
	variant = 'conversations',
	children,
	oarHeaderRef,
}) => {
	const oarHeaderHeight = oarHeaderRef?.current?.clientHeight || undefined;

	return (
		<StyledContentContainer
			$variant={variant}
			item
			xs={true}
			$oarHeaderHeight={oarHeaderHeight}
		>
			{children}
		</StyledContentContainer>
	);
};
