import { Tooltip } from '@mui/material';
import { mdiTranslate, mdiMessageText, mdiAccountOff, mdiAccount } from '@mdi/js';
import Icon from '@mdi/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { MessageTypeEnum } from '@heylog-app/shared/types';

import {
	StyledAvatar,
	StyledMessageAuthor,
	StyledMessageAuthorName,
	StyledMessageAuthorText,
	StyledMessageTime,
} from './message-author.styles';
import { getMessageTime } from '../../../util/message-time';
import { colors } from '../../../styles';
import { useUsers } from '../../../hooks';
import { getNameInitials } from '../../../util/get-name-initials';

import type { FC } from 'react';
import type { MessageResInterface } from '@heylog-app/shared/types';

type MessageAuthorProps = {
	message: MessageResInterface;
	createdAtDate: string;
};

const DEFAULT_USER_NAME = 'Heylog';
const DEFAULT_CONTACT_NAME = 'New contact';

export const MessageAuthor: FC<MessageAuthorProps> = ({ message, createdAtDate }) => {
	const { workspaceId } = useParams();
	const currentUserLanguage = i18next.language;
	const { user, contact, createdAt } = message;
	const { t } = useTranslation();

	const { users } = useUsers(workspaceId ? workspaceId : '');

	const author = message.direction === 'FROM_CONTACT' ? contact : user;

	const fromInactiveUser =
		user !== undefined &&
		users !== undefined &&
		author !== undefined &&
		!users?.some((user) => user.id === author?.id);

	const userName =
		user?.firstName || user?.lastName
			? `${user?.firstName} ${user?.lastName}`
			: DEFAULT_USER_NAME;

	const contactName =
		contact?.firstName || contact?.lastName
			? `${contact?.firstName} ${contact?.lastName}`
			: DEFAULT_CONTACT_NAME;

	const displayName = message.direction === 'FROM_CONTACT' ? contactName : userName;

	return (
		<StyledMessageAuthor>
			{fromInactiveUser && userName !== DEFAULT_USER_NAME ? (
				<Tooltip title={t('chats.message.inactiveUserTooltip')} arrow placement="top">
					<StyledAvatar sx={{ width: 24, height: 24 }} src={user?.profilePicturePath || ""}>
						{!user?.profilePicturePath && <Icon path={mdiAccountOff} size={'0.85em'} />}
					</StyledAvatar>
				</Tooltip>
			) : (
				<StyledAvatar sx={{ width: 24, height: 24, fontSize: '0.6em' }} src={author?.profilePicturePath || ""}>
					{!author?.profilePicturePath && (getNameInitials(author?.firstName, author?.lastName) || (
						<Icon path={mdiAccount} size={'1rem'} />
					))}
				</StyledAvatar>
			)}
			<StyledMessageAuthorText>
				<StyledMessageAuthorName fromInactiveUser={fromInactiveUser}>
					{displayName}
				</StyledMessageAuthorName>
				{createdAtDate && (
					<Tooltip title={createdAtDate} placement="top" arrow>
						<StyledMessageTime>
							{getMessageTime(createdAt, currentUserLanguage)}
						</StyledMessageTime>
					</Tooltip>
				)}
			</StyledMessageAuthorText>
			{message?.translatedText && (
				<Icon path={mdiTranslate} size={'0.85em'} color={colors.primary.dark} />
			)}
			{message?.messageType === MessageTypeEnum.WHATSAPP_TEMPLATE && (
				<Icon path={mdiMessageText} size={'0.85em'} color={colors.primary.dark} />
			)}
		</StyledMessageAuthor>
	);
};
