export const IconWhatsapp3 = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
        <path d="M6.77925 18.2304L7.09815 18.4193C8.43749 19.2141 9.97317 19.6346 11.5392 19.6353H11.5425C16.3519 19.6353 20.2659 15.7222 20.2679 10.9125C20.2687 8.58184 19.362 6.39012 17.7146 4.74144C16.9066 3.92836 15.9454 3.28362 14.8865 2.84454C13.8277 2.40546 12.6922 2.18076 11.5459 2.18346C6.73288 2.18346 2.81871 6.0962 2.817 10.9055C2.81463 12.5478 3.27709 14.1572 4.15093 15.5477L4.35853 15.8776L3.4769 19.0962L6.77925 18.2304ZM0.956299 21.5896L2.44575 16.1515C1.52717 14.56 1.0439 12.7546 1.04448 10.9048C1.0469 5.11826 5.75595 0.410645 11.5427 0.410645C14.3508 0.412071 16.9865 1.50487 18.9688 3.48856C20.951 5.47225 22.0417 8.109 22.0407 10.9133C22.0381 16.6994 17.3284 21.4079 11.5425 21.4079H11.538C9.78112 21.4072 8.05482 20.9664 6.52143 20.1303L0.956299 21.5896Z" fill="#B3B3B3" />
        <path d="M0.848877 21.4822L2.33832 16.0441C1.41816 14.4488 0.934831 12.6391 0.937054 10.7974C0.939479 5.01084 5.64852 0.303223 11.4352 0.303223C14.2433 0.304649 16.8791 1.39744 18.8614 3.38114C20.8436 5.36483 21.9343 8.00158 21.9333 10.8058C21.9307 16.592 17.221 21.3004 11.4351 21.3004H11.4305C9.6737 21.2997 7.9474 20.859 6.41401 20.0229L0.848877 21.4822Z" fill="white" />
        <path d="M11.4399 2.07571C6.62683 2.07571 2.71266 5.98845 2.71095 10.7978C2.70858 12.4401 3.17104 14.0494 4.04487 15.4399L4.25247 15.7699L3.37085 18.9885L6.67334 18.1226L6.99223 18.3115C8.33158 19.1064 9.86726 19.5267 11.4333 19.5276H11.4366C16.246 19.5276 20.1601 15.6144 20.162 10.8048C20.1656 9.65843 19.9418 8.52274 19.5035 7.46346C19.0653 6.40419 18.4212 5.44237 17.6087 4.63369C16.8007 3.82059 15.8394 3.17584 14.7805 2.73676C13.7216 2.29768 12.5862 2.07299 11.4399 2.07571Z" fill="url(#paint0_linear_4905_2755)" />
        <path fill-rule="evenodd" clipRule="evenodd" d="M8.81344 6.40949C8.61682 5.97274 8.40994 5.96389 8.22317 5.95633L7.72036 5.9502C7.54543 5.9502 7.26121 6.01583 7.02094 6.27836C6.78066 6.54089 6.10278 7.1754 6.10278 8.46595C6.10278 9.7565 7.04277 11.0035 7.17375 11.1787C7.30473 11.354 8.98836 14.0867 11.6546 15.1381C13.8703 16.0119 14.3212 15.8381 14.8023 15.7945C15.2835 15.7508 16.3543 15.16 16.5727 14.5474C16.7912 13.9349 16.7913 13.4101 16.7258 13.3004C16.6603 13.1907 16.4854 13.1255 16.2229 12.9942C15.9603 12.8629 14.6709 12.2284 14.4305 12.1408C14.1901 12.0532 14.0153 12.0097 13.8402 12.2722C13.6652 12.5348 13.1631 13.1253 13.01 13.3004C12.8569 13.4755 12.7041 13.4975 12.4415 13.3663C12.179 13.2352 11.3343 12.9581 10.3321 12.0645C9.55238 11.3692 9.02603 10.5106 8.87279 10.2482C8.71955 9.98579 8.85653 9.84368 8.98808 9.71298C9.10579 9.59541 9.25033 9.40664 9.38174 9.25355C9.51315 9.10045 9.55638 8.99101 9.6437 8.81623C9.73102 8.64145 9.6875 8.48792 9.62187 8.3568C9.55624 8.22567 9.04658 6.92827 8.81344 6.40949Z" fill="white" />
        <defs>
            <linearGradient id="paint0_linear_4905_2755" x1="11.2588" y1="3.12299" x2="11.3473" y2="18.0367" gradientUnits="userSpaceOnUse">
                <stop stopColor="#57D163" />
                <stop offset="1" stopColor="#23B33A" />
            </linearGradient>
        </defs>
    </svg>
);