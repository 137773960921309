import React, { useEffect, useState } from "react";
import { ContactGrid, CustomGridContainer, StyledAvatar, StyledExistingButton, StyledLi } from "./conversations.styles";
import { TextField, Typography, Autocomplete, Grid, InputAdornment, IconButton } from '@mui/material';
import { getNameInitials, useApiClientContext, useUser, useUsers, useUsersForChat } from "@heylog-app/frontend-lib/app";
import { mdiCloseCircle, mdiMagnify, mdiPlus } from "@mdi/js";
import { Icon } from '@mdi/react';
import { useParams } from "react-router-dom";
import { truncateText } from "@heylog-app/shared/util";
import { useTranslation } from "react-i18next";


interface Contact {
    id: number;
    name: string;
    email: string;
    value: string;
    avatarUrl?: string;
    firstName?: string;
    lastName?: string;
}

export const AddContacts = ({ selectedContacts, setSelectedContacts }: any) => {
    const { t } = useTranslation();
    const [isSearchMode, setIsSearchMode] = useState(false);
    const { workspaceId } = useParams();
    const { user } = useUser();
    const { apiClient } = useApiClientContext();
    const { users } = useUsersForChat(workspaceId ? workspaceId : '-1');
    const handleAddContact = (contact: Contact | null) => {
        if (contact && !selectedContacts?.find((c: Contact) => c.id === contact.id)) {
            setSelectedContacts((prev: Contact[]) => [...prev, contact]);
        }
        setIsSearchMode(false);
    };
    const handleRemoveContact = (id: number) => {
        setSelectedContacts((prev: Contact[]) => prev?.filter((contact) => contact.id !== id));
    };

    const [contactData, setContactData] = useState<any>([]);
    useEffect(() => {
        setContactData(users?.map((item: any) => {
            return {
                id: item.id,
                name: item.firstName + " " + item?.lastName,
                email: item.email,
                avatarUrl: item?.profilePicturePath,
            }
        }))
    }, [users])

    return (
        <>
            <Grid item xs={12} sx={{ p: 2, mt: 2 }}>
                <CustomGridContainer container item xs={12} columnSpacing={2} sx={{
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    position: 'relative',
                }}>
                    <Grid item><Typography variant="body1" color="textSecondary">{t('contacts.To')}:</Typography></Grid>
                    <Grid item sx={{ maxWidth: "fit-content" }}>
                        {isSearchMode ? (
                            <Autocomplete
                                ListboxProps={{
                                    style: {
                                        padding: '0px 0px',
                                    },
                                }}
                                onClose={() => { setIsSearchMode(false) }}
                                onBlur={() => setIsSearchMode(false)}
                                sx={{
                                    width: 300,
                                    transition: 'visibility 0.3s',
                                    border: '1px solid #122CC5',
                                    borderRadius: '4px',
                                }}
                                renderOption={(props, option: Contact) => (
                                    <StyledLi {...props} key={option.email}>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item>
                                                <StyledAvatar sx={{ width: '30px', height: '30px', fontSize: '1rem' }} src={option?.avatarUrl || ""}>
                                                    {option?.avatarUrl ? null : getNameInitials(option?.name)}
                                                </StyledAvatar>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body1">{truncateText(option.name, 30)}</Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {truncateText(option?.email, 28)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </StyledLi>
                                )}
                                getOptionLabel={(option: Contact) => option?.name}
                                onChange={(event, value: Contact | null) => handleAddContact(value)}
                                options={contactData || []}
                                renderInput={(params) => <TextField {...params} size="small" sx={{
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'transparent',
                                        '& fieldset': {
                                            borderColor: '#122cc5',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'var(--White-200, #F5F8FE)',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'var(--White-200, #F5F8FE)',
                                            boxShadow: 'none',
                                        },
                                    },
                                    '& input::placeholder': {
                                        color: '#122CC5',
                                    },
                                }}
                                    placeholder={t('contacts.SearchExistingContacts')}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon path={mdiMagnify} size={1} color={'#122CC5'} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />}
                            />
                        ) : (
                            <StyledExistingButton
                                sx={{
                                    transition: 'visibility 0.3s',
                                }}
                                onClick={() => setIsSearchMode(true)}
                                startIcon={<Icon path={mdiPlus} size={1} />}
                            >
                                {t('contacts.ExistingContact')}
                            </StyledExistingButton>
                        )}
                    </Grid>
                    <Grid item display="flex" sx={{ gap: 1, flexWrap: 'wrap' }}>
                        {selectedContacts && selectedContacts?.map((contact: Contact) => (
                            <Grid
                                key={contact.id}
                                container
                                alignItems="center"
                                spacing={2}
                                gap={4}
                                sx={{
                                    backgroundColor: 'var(--Primary-Light-150, #EBEDFF)',
                                    borderRadius: '5px',
                                    padding: '8px',
                                    width: 'fit-content',
                                    height: '57px',
                                    margin: '8px',
                                }}
                            >
                                <Grid display="flex" gap={1}>
                                    <StyledAvatar
                                        sx={{
                                            width: 40,
                                            height: 40,
                                            fontSize: '1rem',
                                        }}
                                        src={contact?.avatarUrl}
                                    >
                                        {contact?.avatarUrl ? null : getNameInitials(contact.name)}
                                    </StyledAvatar>
                                    <Grid>
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight="bold"
                                            sx={{ color: '#333' }}
                                        >
                                            {contact.name}
                                        </Typography>
                                        <Typography variant="body2" sx={{ color: '#666' }}>
                                            {contact.email}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid>
                                    <IconButton
                                        size="small"
                                        sx={{
                                            color: '#4c6ef5',
                                        }}
                                        onClick={() => handleRemoveContact(contact.id)}
                                    >
                                        <Icon path={mdiCloseCircle} size={1} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </CustomGridContainer >
            </Grid >
        </>
    );
};
