import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { FlashSnackbar, getNameInitials, useApiClientContext, useSnackbar, useUploadProfilePicture, useUser } from '@heylog-app/frontend-lib/app';
import Avatar_MALE from '../../../../../../../app/src/assets/avatar-male.png';

import PreviewPicture from '../PreviewPicture';
import Upload from '../Upload';

import '../profile.css';
import {
	StyledAvatar,
	StyledProfileInfo,
	StyledProfileWrapper,
	StyledTeamInfo,
} from './profile-info.styles';

import { useState, type FC } from 'react';
import { useParams } from 'react-router-dom';

type ProfileInfoProps = {
	workspaceName: string | undefined;
	height: number;
	width: number;
};

export const ProfileInfo: FC<ProfileInfoProps> = ({
	workspaceName = '',
	height,
	width,
}) => {
	const { apiClient } = useApiClientContext();
	const { t } = useTranslation();
	const { user, mutateUser } = useUser();
	const [uploadedImage, setuploadImg] = useState<string>();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const { workspaceId } = useParams();
	const openModal = () => {
		setModalIsOpen(true);
	};
	const [previewImage, setPreviewImage] = useState<string | null>(null);
	const [croppedImage, setCroppedImage] = useState<string | null>(null);

	const closeModal = () => {
		setModalIsOpen(false);
	};
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const handleFileSelect = async (event: any) => {
		if (event) {
			const formData = new FormData();
			formData.append('file', event);
			const cond = ['.jpeg', '.jpg', '.png'].some((ext) =>
				event?.name?.endsWith(ext),
			);
			if (cond) {

				const val = await apiClient.post(`/workspaces/${workspaceId}/users/${user?.id}/uploadProfilePicture`, formData);
				if (val.status === 201) {
					setModalIsOpen(false);
					setCroppedImage(null);
					setPreviewImage(null);
					openSnackbar(
						'success',
						"Profile picture updated successfully",
					);
					mutateUser();
				} else {
					openSnackbar(
						'error',
						"Failed to update profile picture",
					);
				}
			} else {
				openSnackbar(
					'error',
					t('settings.form.errors.unknown'),
				);
			}
		}
	};
	return (
		<>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

			<div
				style={{
					marginLeft: '20px',
					position: 'relative',
					width: '120px',
					height: '120px',
					borderRadius: '50%', // Adding curved borders
					overflow: 'hidden', // To ensure the image stays within the curved border
					boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)', // Adding a box shadow
				}}
				onClick={openModal}
			>
				<img
					className="user_pic"
					src={
						user?.profilePicturePath || Avatar_MALE
					}
					alt=""
					style={{
						height: '100%',
						width: '100%',
						objectFit: 'cover',
					}}
				/>
				<div className='upload-btn-container'>

					<button className="upload-btn">
						<Upload width={40} height={40} />
					</button>
				</div>
			</div>
			<PreviewPicture
				modalIsOpen={modalIsOpen}
				closeModal={closeModal}
				uploadedImage={uploadedImage}
				handleFileSelect={handleFileSelect}
				employee={user}
				showUpload={true}
				croppedImage={croppedImage}
				setCroppedImage={setCroppedImage}
				setPreviewImage={setPreviewImage}
				previewImage={previewImage}
			/>

			<StyledProfileWrapper>
				<StyledProfileInfo>{user?.firstName + ' ' + user?.lastName}</StyledProfileInfo>
				<StyledTeamInfo>
					<span style={{ color: 'gray' }}>{t('settings.labels.team')}</span>:{' '}
					{workspaceName}
				</StyledTeamInfo>
			</StyledProfileWrapper>

		</>
	);
};
