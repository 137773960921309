import { useCallback, useMemo, useState } from 'react';
import { mdiCheckAll, mdiInformationOutline } from '@mdi/js';
import { Dialog, DialogContent, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MessageDirectionEnum, MessageTypeEnum } from '@heylog-app/shared/types';
import { getFullDateTime } from '@heylog-app/shared/util';

import { getMessageIconData } from './get-message-icon-data';
import { MessageAuthor, MessageAuthorV2, MessageBubble, MessageBubbleV2, MessageGroup, MessageGroupV2, ModalClose } from '../ui';
import {
	StyledIcon,
	StyledIconButton,
	StyledIconWrapper,
	StyledTranslationButton,
} from './conversation.styles';
import { isBetweenDates } from '../../util/date-helpers';
import { sortByCreatedAtAsc } from '../../util';
import { useCurrentLanguage } from '../../hooks';

import type { FC } from 'react';
import type { MessageResInterface, Reaction } from '@heylog-app/shared/types';
import { colors } from '../../styles';

export const MessageV2: FC<{
	message: any;
}> = ({ message }) => {
	const { transportType } = message;
	const { path, color } = getMessageIconData(message);
	const [start, end] = [null, null];
	const [showInfoDialog, setShowInfoDialog] = useState(false);

	const { t } = useTranslation();
	const { currentLanguage } = useCurrentLanguage();
	const [showTranslated, setShowTranslated] = useState(
		message?.direction === MessageDirectionEnum.TO_CONTACT ? false : true,
	);

	const createdAtDate = getFullDateTime(message.createdAt, currentLanguage);


	const isMessageInactive = useMemo(() => {
		if (start) {
			return !isBetweenDates(message.createdAt, start, end || new Date());
		}
		return false;
	}, [start, end, message.createdAt]);


	const handleInfoDialogClose = () => {
		setShowInfoDialog(false);
	};

	return (
		<>
			<MessageGroupV2 message={message} inactive={isMessageInactive}>
				<MessageAuthorV2 message={message} createdAtDate={createdAtDate} />

				<MessageBubbleV2
                    borderRadius={message.direction === "FROM_CONTACT"
						? "0px 16px 16px 16px"
						: "16px 0px 16px 16px"}
					message={message}
					reaction={undefined}
					showTranslated={showTranslated}
				/>

				<StyledIconWrapper>
					<>

						<StyledIcon path={mdiCheckAll} size={'0.85em'} color={colors.green.main50} />
						{message.direction === 'TO_CONTACT' && (
							<StyledIconButton
								data-test="message-info-button"
								onClick={() => setShowInfoDialog(true)}
							>
								<StyledIcon path={mdiInformationOutline} size={'0.85em'} />
							</StyledIconButton>
						)}
					</>

				</StyledIconWrapper>
			</MessageGroupV2>

			<Dialog
				data-test="message-info-dialog"
				open={showInfoDialog}
				onClose={handleInfoDialogClose}
				key={message.id}
			>
				<ModalClose closeModalFn={handleInfoDialogClose} />
				<DialogContent
					key={`${message.id}-${message.createdAt}-${message.receivedAt}--${message.seenAt}`}
				>
					<p data-test="message-transport-type-label">
						{t('chats.message.messageType') + ' '}
						{t(`join.transportTypes.${message.type}`)}
					</p>
					{message.createdAt && (
						<p data-test="message-sent-time-label">
							{t('chats.message.messageSent')}
							{` ${createdAtDate}`}
						</p>
					)}
					{message.receivedAt && (
						<p data-test="message-received-time-label">
							{t('chats.message.messageReceived')}
							{` ${getFullDateTime(message.receivedAt, currentLanguage)}`}
						</p>
					)}
					{message.seenAt && (
						<p data-test="message-seen-time-label">
							{t('chats.message.messageSeen')}
							{` ${getFullDateTime(message.seenAt, currentLanguage)}`}
						</p>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
};
