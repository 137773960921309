import type { FC } from 'react';

export const SvgDE: FC = () => (
    <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2301_3132)">
            <path d="M0.595001 8.44775H16.405V12.343H0.595001V8.44775Z" fill="#FFCE00" />
            <path d="M0.595001 0.657227H16.405V4.55244H0.595001V0.657227Z" fill="black" />
            <path d="M0.595001 4.55225H16.405V8.44746H0.595001V4.55225Z" fill="#DD0000" />
        </g>
        <defs>
            <clipPath id="clip0_2301_3132">
                <rect x="0.595001" y="0.657227" width="15.81" height="11.6857" rx="1.37478" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
