import { useTranslation } from 'react-i18next';

import {
	StyledCopyright,
	StyledCopyrightV2,
	StyledFooterLinks,
	StyledFooterLinksV2,
	StyledPageFooter,
} from './page-footer.styles';
import i18next from "i18next"
import { useCallback, type FC } from 'react';
import { useApiClientContext } from '../../../hooks';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { FileDownloadResInterface } from '@heylog-app/shared/types';
import { getFetcher } from '../../../util';

export const PageFooter: FC = () => {
	const { t } = useTranslation();
	return (
		<StyledPageFooter>
			<StyledFooterLinks>
				<li>
					<a href="https://www.heylog.com/agb" target="_blank" rel="noreferrer">
						{t('join.termsOfService')}
					</a>
				</li>

				<li>
					<a href="mailto:support@heylog.com">{t('join.reportProblem')}</a>
				</li>

				<li>
					<a href="https://www.heylog.com/impressum" target="_blank" rel="noreferrer">
						{t('join.imprint')}
					</a>
				</li>
			</StyledFooterLinks>
			<StyledCopyright>&copy; Heylog GmbH {new Date().getFullYear()}</StyledCopyright>
		</StyledPageFooter>
	);
};
const useSafetyById = (language: string) => {
	const { apiClient } = useApiClientContext();
	const { workspaceId } = useParams();
	const { data, error, isValidating, isLoading, mutate } =
	  useSWR<FileDownloadResInterface>(
		`/workspaces/${workspaceId}/single-safety-instruction${language ? `?language=${language}` : ""}`,
		getFetcher(apiClient),
		{
		  revalidateOnFocus: false, // Disable revalidation on focus
		}
	  );
   
	const refetchSafetyDocument = useCallback(() => mutate(), [mutate]);
	return {
	  dataCheck: data,
	  isLoading,
	  refetchSafetyDocument,
	  isError: error,
	  isValidating,
	};
  };
export const PageFooterV2: FC = () => {
	const { dataCheck } = useSafetyById(i18next.language || "en")

	const { t } = useTranslation();
	return (
		<StyledPageFooter>
			<StyledFooterLinksV2>
				<li>
					<a href="https://www.heylog.com/agb" target="_blank" rel="noreferrer">
						{t('join.termsOfService')}
					</a>
				</li>

				<li>
					<a href="mailto:support@heylog.com">{t('join.reportProblem')}</a>
				</li>

				<li>
					<a href="https://www.heylog.com/impressum" target="_blank" rel="noreferrer">
						{t('join.imprint')}
					</a>
				</li>
				<li>
					<a href={dataCheck?.safetyInstructionUrl} target="_blank" rel="noreferrer">
						{t('join.downloadDpa')}
					</a>
				</li>
			</StyledFooterLinksV2>
			<StyledCopyrightV2>&copy; Heylog GmbH {new Date().getFullYear()}</StyledCopyrightV2>
		</StyledPageFooter>
	);
};